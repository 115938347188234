@import "variables";

.search-product-container {
    .bread-crumbs {
        .card {
            .selected-filters {
                overflow: auto;
                p {
                    margin-bottom: 0px;
                    color: $navyBlueColor;
                    font-weight: $fontWeight600;
                    white-space: nowrap;
                    font-size: $fontSize14px;
                }
                .btn {
                    background: transparent;
                    border: 1px solid $earlyCrocusColor;
                    border-radius: 20px;
                    padding-right: 5px;
                    background: $whiteSmokeDarkColor;
                    color: $pantoneColor;
                    white-space: nowrap;
                    font-size: $fontSize14px;
                    span {
                        background-color: $reseneNightOwlColor;
                        color: $whiteColor;
                        border-radius: 50%;
                        padding: 1px 3px;
                        margin-left: 6px;
                        font-weight: $fontWeight500;
                    }
                }
            }
            .filter-dropdown {
                .btn {
                    background: transparent;
                    color: black;
                    border: none;
                }
                .dropdown-menu {
                    padding: 0px;
                    width: 300px;
                    border-radius: 1px;
                    border: 1px solid rgba(200, 200, 200, 0.3411);
                    right: 0;
                    left: auto;
                }
                .dropdown-item {
                    padding: 0px;
                    &.active,
                    &:active,
                    &:hover {
                        background-color: $whiteColor;
                        color: black;
                        outline: none;
                        border: none;
                    }
                }
                .dropdown-item {
                    .accordion {
                        background: $whiteColor;
                        .cancel-btn {
                            font-size: $fontSize14px;
                            font-weight: $fontWeight400;
                            color: $whiteColor;
                            background-color: $pinkSwanColor;
                            width: 50%;
                            border-radius: 4px;
                            padding: 6px 12px;
                            margin: 10px;
                        }
                        .shop-btn {
                            background-color: $companyPrimaryColor;
                            color: $companyPrimaryButtonTextColor;
                            padding: 6px 12px;
                            white-space: nowrap;
                            margin: 10px;
                            border-radius: 4px;
                            width: 50%;
                        }
                        .accordion-item {
                            border: none;
                            border-radius: 0;
                            background-color: $whiteColor;
                            border-bottom: 1px solid rgba(200, 200, 200, 0.3411764706);
                            .accordion-header {
                                .clear-btn {
                                    color: $tomatoBabyColor;
                                    font-size: $fontSize12px;
                                    font-weight: $fontWeight500;
                                    text-decoration: none;
                                }
                            }
                            .accordion-body {
                                padding-top: 0px;
                                overflow: auto;
                                max-height: 312px;
                            }
                            .accordion-button {
                                border: 0;
                                border-radius: 0;
                                background-color: transparent;
                                box-shadow: none;
                                padding-left: 55px;
                                color: $rusticRedColor;
                                font-size: $fontSize15px;
                                font-weight: $fontWeight600;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding-top: 12px;
                                padding-bottom: 10px;

                                .clear-btn {
                                    color: $tomatoBabyColor;
                                    font-size: $fontSize12px;
                                    font-weight: $fontWeight500;
                                    text-decoration: none;
                                    display: none;
                                }

                                &[aria-expanded="true"] {
                                    .clear-btn {
                                        display: block;
                                    }
                                }

                                &::after {
                                    content: "\f068";
                                    font-family: FontAwesome;
                                    left: 0;
                                    position: absolute;
                                    background: $whisperColor;
                                    border-radius: 2px;
                                    font-size: $fontSize10px;
                                    font-weight: normal;
                                    color: $blackColor;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-left: 20px;
                                    padding: 2px 5px;
                                    transition: none;
                                }

                                &.collapsed {
                                    &::after {
                                        content: "\f067";
                                    }
                                }
                            }

                            .accordion-body {
                                h4 {
                                    color: $rusticRedColor;
                                    font-size: $fontSize15px;
                                    font-weight: $fontWeight600;
                                    text-align: left;
                                }

                                .input-group {
                                    flex-wrap: nowrap !important;

                                    input {
                                        background: $superSilverColor;
                                        border: none;
                                        border-radius: 0px 2px 2px 0px;
                                        font-size: $fontSize14px;
                                        font-weight: $fontWeight500;
                                        width: 100%;
                                        padding: 8px 10px;
                                        color: $reseneNightOwlColor;
                                        padding-left: 4px;

                                        &:focus {
                                            outline: none;
                                            box-shadow: none;
                                            border: none;
                                        }
                                    }

                                    .input-group-text {
                                        background: $superSilverColor;
                                        border: none;
                                        border-radius: 2px 0px 0px 2px;
                                        border-left: 0 !important;
                                        padding-right: 3px;

                                        i {
                                            color: $reseneNightOwlColor;
                                            font-size: $fontSize14px;
                                        }
                                    }
                                }

                                ul {
                                    padding-left: 15px;
                                    margin-top: 15px;

                                    li {
                                        display: flex;
                                        align-items: center;
                                        margin: 8px 0;

                                        input[type="checkbox"] {
                                            accent-color: $companyPrimaryColor;
                                            width: 30px;
                                            height: 16px;
                                        }

                                        label {
                                            color: $rusticRedColor;
                                            font-size: $fontSize14px;
                                            font-weight: $fontWeight500;
                                            text-align: left;

                                            i {
                                                &.fa-star {
                                                    color: $yellowColor;
                                                    font-size: $fontSize14px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.product-listing-col {
    flex: 0 0 20%;

    a {
        color: inherit;
        text-decoration: none;
        .card {
            background-color: $whiteColor;
            height: 330px;
            overflow: auto;
            border: unset;
            .card-body {
                .image-box {
                    align-items: center;
                    background: $perfectWhiteColor;
                    padding: 15px;
                    img {
                        width: 100%;
                        height: 135px;
                        object-fit: contain;
                    }
                }

                .product-details {
                    h2 {
                        text-align: left;
                        font-size: $productTitleSize14px;
                        font-weight: $productTitleWeight400;
                        color: $rusticLightRedColor;
                        margin-bottom: 0;
                        word-break: break-word;
                        height: 2.5rem;
                        span {
                            text-align: left;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                    p {
                        text-align: left;
                        font-size: $productSubTitleSize16px;
                        font-weight: $productSubTitleWeight400;
                        color: $rusticLightRedColor;
                        margin-bottom: 5px;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        word-break: break-word;
                        span {
                            text-align: left;
                        }
                    }
                    h3 {
                        color: $purplishBrownColor;
                        font-size: $fontSize14px;
                        font-weight: $fontWeight500;
                        margin-bottom: 5px;
                        i {
                            color: $zambiaColor;
                            font-size: $fontSize14px;
                            margin-right: 5px;
                        }
                    }
                    h4 {
                        letter-spacing: 0px;
                        color: $rusticLightRedColor;
                        font-size: $productPriceSize18px;
                        font-weight: $productPriceWeight600;
                        margin-bottom: 5px;
                    }
                }
            }
            &::-webkit-scrollbar {
                display: none;
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
        }
    }
}
@media (min-width: 767.98px) and (max-width: 991.98px) {
    .product-sublist .product-listing-col {
        flex: 0 0 33.33%;
    }
}

@media (max-width: 767.98px) {
    .product-sublist .product-listing-col {
        flex: 0 0 50%;
    }
}

@media (max-width: 575.98px) {
    .product-sublist .product-listing-col {
        flex: 0 0 100%;
    }
}
