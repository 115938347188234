@import "variables";

#order-confirm {
    .card {
        border: 0;
        border-radius: 0;
        .card-body {
            .order-placed {
                text-align: center;
                i {
                    color: $energyGreenColor;
                    font-size: $fontSize20px;
                }
                h4 {
                    font-size: $fontSize18px;
                }
                h2 {
                    letter-spacing: 0px;
                    color: $energyGreenColor;
                    opacity: 1;
                    font-size: $fontSize17px;
                    font-weight: $fontWeight600;
                    text-align: left;
                }
                p {
                    letter-spacing: 0px;
                    color: $rusticRedColor;
                    opacity: 1;
                    margin-bottom: 4px;
                    font-size: $fontSize18px;
                }
            }
            table {
                thead {
                    th {
                        letter-spacing: 0px;
                        color: $rusticRedColor;
                        font-size: $fontSize16px;
                        opacity: 1;
                        font-weight: $fontWeight500;
                        padding: 0.5rem 0.5rem;
                    }
                }
                tbody {
                    tr {
                        td {
                            border-top: 1px solid $coquetteColor;
                            border-bottom: 1px solid $coquetteColor;
                            img {
                                background-color: $whiteColor;
                                border: 1px solid $whisperColor;
                                width: 250px;
                                height: 150px;
                                object-fit: contain;
                            }
                            h6 {
                                font-size: $fontSize16px;
                                font-weight: $fontWeight600;
                                color: $rusticRedColor;
                                display: -webkit-box;
                                overflow: hidden;
                            }
                            .btn {
                                font-size: $fontSize12px;
                                font-weight: $fontWeight500;
                                color: $navyBlueColor;
                                background-color: $aliceBlueColor;
                                &:focus {
                                    border: unset;
                                }
                                &:active {
                                    border: unset;
                                }
                            }
                            .pdt-detail {
                                width: 70%;
                                p {
                                    font-size: $fontSize14px;
                                    color: $energyGreenColor;
                                    margin-bottom: 0;
                                    font-weight: $fontWeight500;
                                    white-space: nowrap;
                                }
                            }
                            .pdt-model {
                                span {
                                    font-weight: $fontWeight600;
                                    color: grey;
                                }
                            }
                            .product-list {
                                display: flex;
                                align-items: flex-start;
                                .pdt-img {
                                    width: 30%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .product-table {
        overflow: auto;
    }
    .order-summary-card {
        border: unset;
        padding: 10px;
        border: 1px solid $whiteColor;
        min-height: 100%;
        max-height: 100%;
        .card-header {
            background-color: $whiteColor;
            border-bottom: unset;
            h2 {
                font-size: $fontSize18px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
        }
        .card-body {
            padding-top: 0;
            table {
                tbody {
                    tr {
                        th,
                        td {
                            font-size: $fontSize14px;
                            vertical-align: middle;
                        }
                        th {
                            font-weight: $fontWeight400;
                            color: $rusticRedColor;
                        }
                        td {
                            color: $rusticRedColor;
                            font-weight: $fontWeight600;
                        }
                    }
                    tr:nth-of-type(2) {
                        td {
                            font-weight: $fontWeight600;
                        }
                    }
                    tr:nth-of-type(4) {
                        td {
                            color: $rusticRedColor;
                            font-weight: $fontWeight600;
                        }
                    }
                }
                tfoot {
                    border-top: 1px solid $superSilverColor;
                    tr {
                        th:nth-of-type(2) {
                            font-size: $fontSize18px;
                            text-align: end;
                        }
                    }
                }
            }
        }
        &:hover {
            border: 1px solid $coquetteColor;
        }
    }
    .order-shipment {
        p {
            letter-spacing: 0px;
            color: $rusticRedColor;
            opacity: 1;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
            margin-bottom: 2px;
            span {
                font-weight: $fontWeight600;
            }
        }
        img {
            height: 22px;
            margin-right: 10px;
        }
    }
    a {
        letter-spacing: 0px;
        color: $resonantBlueColor;
        opacity: 1;
        text-decoration: underline;
        font-size: $fontSize14px;
        font-weight: $fontWeight400;
    }
    .shop-btn {
        &.btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            color: $companyPrimaryButtonTextColor;
            background-color: $companyPrimaryColor;
            width: 160px;
            border-radius: 4px;
        }
    }
}
#payment-method-error {
    .payment-method-row {
        .payment-error-page {
            p {
                color: $reseneNightOwlColor;
                font-size: $fontSize14px;
                margin-bottom: 3px;
                span {
                    color: $companyPrimaryColor;
                    font-weight: $fontWeight600;
                    font-size: $fontSize20px;
                }
            }
            button {
                background: transparent;
                border-radius: 4px;
                opacity: 1;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                margin: 7px 0;
                padding: 10px;
                border-color: $chestyBondColor;
                color: $chestyBondColor;
                border: 1px solid;
                width: 200px;
            }
            .payment-btn {
                color: $whiteColor;
                background-color: $chestyBondColor;
            }
            img {
                width: 246px;
            }
            .card {
                border: 0;
                border-radius: 0;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
}
.redirecting-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
