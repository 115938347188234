@import "variables";
#mapViewSection {
    i {
        background: $whisperColor;
        border-radius: 2px;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .card {
        border: 0;
        border-radius: 0;
    }
    .map-container {
        position: relative !important;
        width: 100% !important;
        height: 500px !important;
    }
    .btn {
        font-size: $fontSize14px;
        font-weight: $fontWeight600;
        color: $companyPrimaryButtonTextColor;
        background-color: $companyPrimaryColor;
        width: 100%;
        border-radius: 4px;
    }
    p {
        font-weight: $fontWeight400;
        color: $rusticRedColor;
        font-size: $fontSize14px;
        margin-bottom: 0px;
    }
}
