@import "variables";

@media (min-width: 0px) and (max-width: 575.98px) {
    .login-page .card .login-sec .login-form label:empty {
        display: none;
    }

    .home-wrapper .bacground-sec {
        height: 165px !important;
    }

    .home-wrapper .product-list .product-view .carousel__item .image-box,
    .products-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .image-box,
    .home-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .image-box,
    .product-list-filter
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .image-box,
    .home-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .image-box {
        padding: 10px;
    }

    .product-list .product-view .carousel__item .image-box img,
    .products-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__item
        img,
    .home-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__item
        img {
        height: 115px !important;
    }

    .home-wrapper .product-list .products-sec {
        margin: 15px 0;
    }

    .home-wrapper .product-list {
        margin-top: -30px;
    }

    .home-wrapper .product-list .products-sec.my-0 {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .products-wrapper .bacground-sec .overlap-title {
        height: 84%;
    }

    .products-wrapper .bacground-sec .overlap-title h2,
    .home-wrapper .product-list .products-sec .overlap-title h2 {
        font-size: $fontSize16px;
        line-height: 22px;
    }

    .home-wrapper .viewall {
        font-size: $fontSize12px;
    }

    .products-wrapper .product-list {
        padding: 5px 0;
    }

    .products-wrapper .product-list .product-thumb .overlap-title h2,
    .products-wrapper .product-list .products-sec .overlap-title h2,
    .product-list-filter .product-sublist .products-sec .overlap-title h2 {
        font-size: $fontSize16px;
    }

    .products-wrapper .product-list .product-thumb .thumb-list .product-container .product-box,
    .products-wrapper .product-list .products-sec .thumb-list .product-container .product-box {
        width: 120px;
        height: 95px;
        padding: 20px 8px;
        margin: 10px 4px;
    }

    .products-wrapper .product-list .product-thumb .thumb-list .product-container .product-box img,
    .products-wrapper .product-list .products-sec .thumb-list .product-container .product-box img {
        height: 88px;
        width: 100px;
        object-fit: contain;
    }

    .products-wrapper .product-list .product-thumb .thumb-list .product-container p,
    .products-wrapper .product-list .products-sec .thumb-list .product-container p {
        font-size: $fontSize12px;
    }

    .products-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__item {
        margin: 0 8px;
    }

    .products-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .image-box {
        padding: 10px;
    }

    .products-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__item
        img,
    .product-list-filter .product-sublist .carousel__item img {
        height: 128px !important;
    }

    .products-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__caption
        .title,
    .home-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__caption
        .title,
    .home-wrapper .product-list .product-view .carousel__item .carousel__caption .title,
    .product-list-filter .product-sublist .carousel__caption .title {
        font-size: $fontSize14px !important;
        margin-bottom: 0.5rem !important;
    }

    .products-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__caption
        p.model,
    .products-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__caption
        p.rating,
    .home-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__caption
        p.model,
    .home-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__caption
        p.rating,
    .product-list-filter .product-sublist .carousel__caption p.model,
    .product-list-filter .product-sublist .carousel__caption p.rating {
        font-size: $fontSize12px !important;
    }

    .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__caption
        p.rating
        i,
    .home-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__caption
        p.rating
        i,
    .product-list-filter .product-sublist .carousel__caption p.rating i {
        font-size: $fontSize12px !important;
        margin-right: 2px;
    }

    .products-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__caption
        .price,
    .home-wrapper
        .product-list
        .products-sec
        .product-carouselItem
        .react-multi-carousel-list
        .react-multi-carousel-item
        .carousel__caption
        .price,
    .product-list-filter .product-sublist .carousel__caption .price {
        font-size: $fontSize16px !important;
    }

    .home-wrapper .product-list .pdt-banner-left .bacground-sec {
        height: 145px !important;
        margin-bottom: 15px;
    }

    .home-wrapper .product-list .pdt-banner-right .bacground-sec {
        height: 145px !important;
    }

    .products-wrapper .product-list .products-sec .overlap-title .next-button,
    .product-list-filter .product-list .products-sec .overlap-title .next-button {
        width: 20px;
        height: 20px;
    }

    .products-wrapper .product-list .products-sec .overlap-title .next-button i,
    .product-list-filter .product-list .products-sec .overlap-title .next-button i {
        font-size: $fontSize12px;
    }

    .products-wrapper .product-list .products-sec {
        margin: 15px 0;
    }

    .products-wrapper .product-list .bacground-sec .overlap-title p {
        font-size: $fontSize12px !important;
    }

    .products-wrapper .product-list .bacground-sec .overlap-title .text-title {
        font-size: $fontSize22px !important;
    }

    .products-wrapper .product-list .bacground-sec .overlap-title .text-title span {
        font-size: $fontSize24px !important;
    }

    .products-wrapper .product-list .bacground-sec {
        height: 128px !important;
    }

    .list-items .pe-2 {
        padding-right: 0 !important;
    }

    #order-confirm .order-summary-card,
    #order-confirm .order-summary-card .card-body {
        padding: 0 !important;
    }

    #order-confirm .order-summary-card {
        border: 0 !important;
    }

    #order-confirm .order-summary-card .card-header {
        padding-left: 0 !important;
    }

    #order-confirm .continue-shop {
        justify-content: center !important;
    }

    .update-modal .modal-dialog {
        min-width: auto !important;
        width: auto !important;
    }

    .update-modal .modal-content .modal-body .bottom-buttons .btn {
        width: 100% !important;
    }

    .react-tel-input {
        margin-left: 0% !important;
    }

    .react-tel-input .flag-dropdown {
        left: 0 !important;
    }

    .react-tel-input .form-control {
        padding-left: 48px !important;
    }

    .login-page .card .login-sec .login-form .form-group.small .form-control {
        width: 32px;
        height: 32px;
    }
    #payment-method-error .payment-method-row .payment-error-page img {
        width: 167px;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .home-wrapper .product-list {
        margin-top: -20px;
    }

    .home-wrapper .bacground-sec {
        height: 380px !important;
    }

    .home-wrapper .product-list .products-sec.my-0 {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .home-wrapper .product-list .products-sec {
        margin: 15px 0;
    }

    .home-wrapper .product-list .pdt-banner-left .bacground-sec {
        height: 245px !important;
        margin-bottom: 15px;
    }

    .home-wrapper .product-list .pdt-banner-right .bacground-sec {
        height: 245px !important;
    }

    .products-wrapper .bacground-sec .overlap-title {
        height: 100%;
    }

    .products-wrapper .bacground-sec {
        height: 275px !important;
    }

    .products-wrapper .product-list {
        padding: 15px 0;
    }

    .products-wrapper .product-list .product-thumb .thumb-list .product-container .product-box,
    .products-wrapper .product-list .products-sec .thumb-list .product-container .product-box {
        width: 156px;
        height: 125px;
        margin: 10px 8px;
    }

    .products-wrapper .product-list .product-thumb .thumb-list .product-container .product-box img,
    .products-wrapper .product-list .products-sec .thumb-list .product-container .product-box img {
        height: 140px;
        width: 137px;
    }

    .products-wrapper .product-list .bacground-sec {
        height: 188px !important;
    }

    .products-wrapper .product-list .products-sec {
        margin: 15px 0;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .home-wrapper .product-list .product-tile {
        height: 778px !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .home-wrapper .product-list .product-tile {
        height: 760px !important;
    }
}

@media (max-width: 991.98px) {
    .header-outer-wrapper .dropbtn a {
        width: auto;
    }

    .header-top-wrapper,
    .header-bottom-wrapper {
        display: none;
    }

    .header-inner-wrapper .logo-wrapper {
        position: absolute;
        left: 42px;
    }

    .header-inner-wrapper {
        padding: 15px;
    }

    .header-inner-wrapper .navbar-wrapper {
        height: auto !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar {
        align-items: flex-start !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar .parent {
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar .parent li {
        margin: 0 5px !important;
        height: 47px;
        justify-content: end;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar .parent li a,
    .header-inner-wrapper .navbar-wrapper .nav-bar .parent li.location a {
        font-size: $fontSize12px !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar .parent li img {
        height: 15px !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar.search {
        margin: 10px 0 !important;
        display: block !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar.search li {
        margin-left: 0 !important;
        width: 100% !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar.search li .input-group {
        flex-wrap: nowrap !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar.search li.location {
        flex-direction: row !important;
        width: 100%;
        border-top: 1px solid $earlyCrocusColor;
        padding-top: 10px !important;
        margin-top: 10px !important;
        height: auto !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar.search li.location a {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar:not(.search) .parent li.location {
        flex-direction: column !important;
        align-items: center !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar.search li .input-group input {
        width: 100% !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar .parent li.location img {
        margin-right: 0 !important;
    }

    .header-inner-wrapper .navbar-wrapper .nav-bar.search li.location img {
        margin-right: 4px !important;
    }

    #menuToggle {
        display: block;
        z-index: 1;
        padding-top: 28px;
        -webkit-user-select: none;
        user-select: none;
        padding: 0px;
    }

    #menuToggle a {
        text-decoration: none;
        color: white !important;
        transition: color 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #menuToggle a img {
        height: 14px;
    }

    #menuToggle a:hover {
        color: $yellowColor;
    }

    #menuToggle input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        -webkit-touch-callout: none;
    }

    #menuToggle span {
        display: block;
        width: 25px;
        height: 3px;
        margin-bottom: 5px;
        position: relative;
        background-color: $blackColor;
        border-radius: 3px;
        z-index: 0;
        transform-origin: 4px 0px;
        transition:
            cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            opacity 0.55s ease;
    }

    #menuToggle span:first-child {
        transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }

    #menuToggle input:checked {
        left: 250px;
        top: 12px;
    }

    #menu {
        position: absolute;
        left: 0;
        width: 300px;
        min-height: 100%;
        height: 4000px;
        margin: -101px 0 0 0px;
        padding: 35px;
        padding-top: 65px;
        background: $floridaWatersColor;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(-100%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    .transform-none {
        transform: translate(-100%, 0);
    }

    #menu li {
        padding: 10px 0;
        font-size: $fontSize20px;
        color: $whiteColor;
        width: 250px;
    }

    #menuToggle input:checked ~ ul {
        transform: none;
    }

    #menu .close-icon {
        position: absolute;
        right: 0;
        top: 68px;
    }

    #menu .close-icon span {
        transform: rotate(45deg) translate(-15px, 7px);
        background: white;
        display: block;
        width: 25px;
        height: 3px;
        margin-bottom: 5px;
        position: relative;
        z-index: 1;
        transform-origin: 4px 0px;
        opacity: 1;
    }

    #menu .close-icon span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, 9px);
    }

    .login-page {
        margin: 15px;
    }

    .login-page .card {
        padding: 15px 15px;
    }

    .login-page .back-btn {
        left: 15px;
    }

    .footer-sec .footer-wrapper .bottom-footer {
        padding: 10px 0;
    }

    .footer-sec .footer-wrapper .bottom-footer .footernav.download {
        height: auto;
    }

    .products-wrapper .bacground-sec {
        background-image: url(../images/products/product-mobile.png) !important;
        background-position: top;
        background-size: 100%;
        height: 175px;
    }

    .products-wrapper .product-list .products-sec .thumb-list {
        justify-content: flex-start;
    }

    .products-wrapper .product-list .bacground-sec {
        background-image: url(../images/products/p-bg.png) !important;
        height: 188px;
    }

    #policy-sec .pe-1 {
        padding-right: 1.5rem;
    }

    #policy-sec .pe-1 {
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    }

    #policy-sec .ps-0 {
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }

    #account .card .card-header {
        width: 100%;
    }

    #account .card .card-body .account-right .profile {
        flex-direction: column;
        align-items: center;
        border: 0;
    }

    #account .card .card-body .edit-profile {
        position: relative !important;
        margin-top: 10px;
        white-space: nowrap;
    }

    #account .card .card-body .account-right .profile .user-detail p,
    #account .card .card-body .account-right .profile .user-detail h2,
    #account .card .card-body .account-right .profile img {
        margin-bottom: 0.6rem;
        text-align: center;
    }

    #account .card .card-body .tab-content {
        height: auto;
    }

    #account .order-2.order-md-1.col-md-2,
    #account .order-1.order-md-2.col-md-10,
    #account .card .card-body .tab-pane {
        padding: 0;
    }

    #account .card .card-body .account-left .nav-item,
    #account .card .card-body .account-right {
        border-bottom: 1px solid $whiteSmokeColor;
    }

    #account .card .card-body .account-left .nav-item:last-child {
        border-bottom: 0;
    }

    #account .card .card-body .tab-pane .input-group input,
    #account .card .card-body .tab-pane .top-title {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    #account .card .card-body .tab-pane .input-group {
        justify-content: flex-start;
        flex-wrap: nowrap;
        width: 100%;
    }

    .card .card-body .product-table {
        display: block;
        overflow: auto;
    }

    #account .card .card-body {
        width: 100%;
    }

    #account .card .card-body table thead th h2 {
        white-space: nowrap;
    }

    .bread-crumbs nav {
        display: flex;
        flex-wrap: wrap;
    }

    #order-history .product-table table tbody tr td img {
        max-width: 160px;
    }

    .bottom-buttons {
        margin: 0.5rem 0;
        float: none !important;
    }

    #account .card .card-body .tab-pane form {
        width: 100% !important;
    }
}

@media (min-width: 991.98px) {
    .header-inner-wrapper {
        display: none;
    }
}

.header-inner-wrapper {
    display: none;
}

@media (min-width: 769px) and (max-width: 992px) {
    .login-page {
        margin: 15px 100px;
    }

    .login-page .card {
        padding: 15px 15px;
    }

    .login-page .back-btn {
        left: 15px;
    }

    .home-wrapper .bacground-sec {
        height: 380px !important;
    }

    .home-wrapper .product-list .pdt-banner-left .bacground-sec {
        height: 210px !important;
        margin-bottom: 15px;
    }

    .home-wrapper .product-list .pdt-banner-right .bacground-sec {
        height: 210px !important;
    }

    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li {
        margin: 0 5px !important;
        height: 47px;
        justify-content: end;
    }

    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li a,
    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li a span {
        font-size: $fontSize12px !important;
    }

    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li.location img {
        margin-right: 4px !important;
    }

    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li .input-group input {
        width: 271px !important;
    }

    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li.location.account {
        align-items: center !important;
    }

    .header-bar .header-top-wrapper .top-bar p {
        min-width: 58%;
        max-width: 58%;
    }

    .header-bar .header-top-wrapper .top-bar p,
    .header-bar .header-top-wrapper .top-bar select {
        font-size: $fontSize12px !important;
    }

    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar {
        justify-content: normal !important;
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li {
        margin: 0 10px !important;
        height: 47px;
        justify-content: end;
    }

    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li a,
    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li a span {
        font-size: $fontSize14px !important;
    }

    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li.location.account {
        align-items: center !important;
    }

    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li.location.account span {
        display: none;
    }

    .header-bar .header-top-wrapper .top-bar p {
        max-width: 64%;
        min-width: 64%;
    }
}

@media (min-width: 993px) {
    .header-bar .logo-wrapper img {
        height: 70px;
        max-height: 70px;
    }
}

@media (min-width: 1400px) {
    .home-wrapper .bacground-sec {
        height: 78vh;
    }

    .header-bar .header-outer-wrapper .navbar-wrapper .nav-bar .parent li .input-group input {
        width: 420px;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .name {
        flex-direction: column;
    }

    #account .card .card-body .account-right .profile-section .profile-btns {
        width: 100%;
    }

    #policy-sec .card .card-header .input-group {
        justify-content: center;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 575px) {
    .img-upload .modal-dialog {
        width: 95%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .share-wishlit .share-text {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .navbar-modal .modal-dialog {
        width: 260px;
    }

    .social-media .modal-dialog {
        width: 260px;
    }

    .filter-form .modal-dialog {
        width: 260px;
    }
}

/* header */
@media (max-width: 992px) {
    .header-outer-wrapper .search-box {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .login-form .social-signin .social-signin-col {
        flex-direction: column;
    }

    .login-form .social-signin .social-signin-col .social-google,
    .login-form .social-signin .social-signin-col .social-facebook {
        width: 100%;
        justify-content: center;
        margin-right: 0px !important;
    }
}

@media (min-width: 768px) and (max-width: 991.8px) {
    .header-bar .header-outer-wrapper .dropdown.location-icon .dropdown-content {
        min-width: 285px !important;
        width: 269px !important;
        padding: 0 !important;
        top: 42px !important;
        right: -105px !important;
    }

    .header-bar .header-outer-wrapper .dropdown.location-icon .dropdown-content::before {
        right: 148px !important;
    }
}

@media (min-width: 320px) and (max-width: 767.8px) {
    .header-bar .header-outer-wrapper .dropdown.location-icon .dropdown-content {
        min-width: 260px !important;
        width: auto !important;
        padding: 0 !important;
        top: 42px !important;
        right: -7px !important;
    }

    .header-bar .header-outer-wrapper .dropdown.location-icon .dropdown-content::before {
        right: 108px !important;
    }
}

@media (min-width: 576px) and (max-width: 991.8px) {
    .header-outer-wrapper {
        .dropdown {
            .dropdown-content {
                min-width: 260px !important;
                right: -15px !important;
            }
            .dropdown-content:before {
                right: 63px;
            }
        }
    }
}

@media (min-width: 992px) {
    .header-outer-wrapper {
        .dropdown {
            .dropdown-content {
                min-width: 260px !important;
                right: 15px !important;
            }
            .dropdown-content:before {
                right: 60px;
            }
        }
    }
}

@media (max-width: 575.8px) {
    .header-outer-wrapper .search-box .input-group .search-dropdown {
        position: absolute;
        background: $whiteColor 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px rgba(87, 31, 37, 0.2235294118);
        opacity: 1;
        min-width: 411px;
        min-width: 269px;
        min-height: 200px;
        overflow: auto;
        z-index: 9;
        top: 40px;
        width: auto;
    }

    .header-outer-wrapper .dropdown .dropdown-content {
        right: -23px !important;
    }

    .header-outer-wrapper .dropdown .dropdown-content:before {
        right: 67px;
    }

    .header-bar .header-outer-wrapper .dropdown.notification .dropdown-content {
        min-width: auto !important;
        width: auto !important;
    }
}

@media (min-width: 991.98px) {
    #menuToggle {
        display: none;
    }
}

@media (max-width: 490px) {
    .orders .input-group {
        width: 100%;
        align-items: center;
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .orders .order-history {
        display: unset !important;
    }

    .orders .filter-dropdown button {
        background: $whiteColor;
        border: 1px solid $rockCrystalColor;
        border-radius: 4px;
        color: $reseneNightOwlColor;
        font-size: $fontSize14px;
        font-weight: $fontWeight400;
        width: 100%;
        text-align: center;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-left: 5px;
        gap: 4px;
    }
}

@media (max-width: 575.8px) {
    #checkoutSec .delivery-details-card .card-body .shop-btn {
        position: unset;
    }

    #checkoutSec .delivery-details-card .card-body .nav .nav-item a {
        text-decoration: none;
    }
}

@media (max-width: 1200px) {
    #checkoutSec .delivery-details-card .card-body .tab-content .tab-pane .address-list {
        padding: 0px;
    }

    #checkoutSec .delivery-details-card .card-body .tab-content .tab-pane .address-list .address-tile {
        width: auto;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .product-sublist .filter-sec .title {
        flex-direction: column;
    }

    .product-sublist .filter-sec .title h4 {
        margin-bottom: 4px;
    }
}

@media (max-width: 767.8px) {
    .customer-review .ratings {
        margin-bottom: 4px;
    }
}

@media only screen and (max-width: 576px) {
    .product-list-filter .bread-crumbs .card p {
        text-align: center;
        margin-bottom: 5px;
    }

    .product-list-filter .bread-crumbs .card .selected-filters {
        margin-bottom: 5px;
    }

    .bread-crumbs .card .sort-dropdown {
        text-align: start;
    }

    .filter-dropdown {
        text-align: right;
    }
}

@media (max-width: 991.98px) {
    #order-confirm .card .card-body table tbody tr td .pdt-detail p {
        white-space: unset;
    }
}

@media (max-width: 991.98px) {
    .bread-crumbs .card .search-box .input-group {
        width: 100%;
        margin-top: 4px;
    }
}

@media (min-width: 320px) and (max-width: 575.98px) {
    .location-a {
        width: auto !important;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .location-a {
        width: 125px !important;
    }
}

@media (min-width: 576px) and (max-width: 991.98px) {
    .w-sm-auto {
        width: auto !important;
    }
}

@media (max-width: 991.98px) {
    #contact-us {
        margin: 1rem 0 !important;
    }
}
@media (max-width: 575.98px) {
    .header-outer-wrapper {
        .notification {
            .notification {
                .badge {
                    position: absolute;
                    right: -7px;
                }
            }
        }
        .cart-section {
            .cart {
                .badge {
                    position: absolute;
                    right: -9px;
                }
            }
        }
    }
    .Coupon-Offers {
        .modal-dialog {
            width: 315px !important;
        }
    }
}
@media (max-width: 767.98px) {
    .pagination-list-card {
        .card-body {
            h2 {
                width: 100%;
                text-align: center;
            }
            .pagination {
                width: 100%;
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 1200px) {
    .product-detail .product-detail-right .pdt-price {
        flex-direction: column;
        align-items: start;
    }
}
