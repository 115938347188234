@import "variables";

.home-wrapper {
    .product-list-row {
        margin-top: -100px;
        .new-arrivals-column,
        .latest-products-column,
        .top-deals-column,
        .best-sellers-column {
            .card {
                border: unset;
                .card-header {
                    background-color: $whiteColor;
                    border-bottom: unset;
                    h2 {
                        font-size: $fontSize22px;
                        font-weight: $fontWeight500;
                        color: $rusticRedColor;
                        text-transform: capitalize;
                        margin-bottom: 0;
                    }
                    a {
                        color: $navyBlueColor;
                        cursor: pointer;
                        font-size: $fontSize15px;
                        font-weight: $fontWeight500;
                        text-decoration: none;
                        z-index: 999;
                    }
                }
                .card-body {
                    height: 600px;
                    overflow: auto;
                    &::-webkit-scrollbar {
                        display: none;
                        -ms-overflow-style: none;
                        scrollbar-width: none;
                    }
                }
            }
        }
        .latest-products-column,
        .best-sellers-column {
            .card-body {
                height: 645px !important;
                overflow: auto;
                &::-webkit-scrollbar {
                    display: none;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }
            }
        }
        // carousel-row start
        .carousel-row {
            .card {
                border: unset;
                .card-header {
                    background-color: $whiteColor;
                    border-bottom: unset;
                    h2 {
                        font-size: $fontSize22px;
                        font-weight: $fontWeight500;
                        color: $rusticRedColor;
                        text-transform: capitalize;
                        margin-bottom: 0;
                    }
                    a {
                        color: $navyBlueColor;
                        cursor: pointer;
                        font-size: $fontSize15px;
                        font-weight: $fontWeight500;
                        text-decoration: none;
                        z-index: 999;
                    }
                }
            }
        }
        // carousel-row end
        .react-multiple-carousel__arrow--left {
            top: 85px;
            left: 0px;
        }
        .react-multiple-carousel__arrow--right {
            top: 85px;
            right: 0px;
        }
        h3 {
            color: $purplishBrownColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
            margin-bottom: 5px;
            i {
                color: $zambiaColor;
                font-size: $fontSize14px;
                margin-right: 5px;
            }
        }
    }
}
