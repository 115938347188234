// @import url(variables.scss);
@import "variables";
input[type="radio"] {
    &:checked {
        background-color: $companyPrimaryColor;
        border-color: $companyPrimaryColor;
    }
    &:focus {
        outline: none;
        box-shadow: none;
    }
}
input[type="checkbox"] {
    accent-color: $companyPrimaryColor;
    appearance: auto;
}
