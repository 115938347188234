:root {
    --headerBackgroundColor: #fffefe; // header bg
    --headerTextColor: #040404; // header text
    --headerIconColor: #311a1c; // header icon
    --navigationMenuColor: #371d21; // Navigation menu
    --navigationSubMenuColor: #311a1d; // Navigation sub menu
    --footerBackgroundColor: #05387d; // footer bg
    --footerHeadingTextColor: #ffffff; // footer heading text
    --footerTextColor: #dae6fa; // footer text
    --primaryButtonBackgroundColor: #ca0015; // Primary Button bg color
    --primaryButtonTextColor: #fffefd; // Primary Button text color
    --secondaryButtonBackgroundColor: #083879; // Secondary Button bg color
    --secondaryButtonTextColor: #fffeff; // Secondary Button text color

    --navigationMenuFontSize14px: 14px; // Navigation menu size
    --navigationMenuFontWeight600: 600; // Navigation menu weight
    --navigationSubMenuFontSize16px: 16px; // Navigation sub-menu size
    --navigationSubMenuFontWeight600: 600; // Navigation sub-menu weight

    --productTitleFontSize14px: 14px; // Product Title size
    --productTitleFontWeight400: 400; // Product Title weight
    --productSubTitleFontSize16px: 16px; // Product sub-Title size
    --productSubTitleFontWeight400: 400; // Product sub-Title weight
    --productPriceFontSize18px: 18px; // Product Price size
    --productPriceFontWeight600: 600; // Product Price weight

    --productDetailsTitleFontSize20px: 20px; // Product Details Title size
    --productDetailsTitleFontWeight600: 600; // Product Details Title weight
    --productDetailsSubTitleFontSize16px: 16px; // Product Details sub-Title size
    --productDetailsSubTitleFontWeight400: 400; // Product Details sub-Title weight
    --productDetailsPriceFontSize22px: 22px; // Product Details Price size
    --productDetailsPriceFontWeight600: 600; // Product Details Price weight
    --productDetailsDescriptionHeadingFontSize18px: 18px; // Product Details Description Heading Size
    --productDetailsDescriptionHeadingFontWeight600: 600; // Product Details Description Heading weight
    --productDetailsDescriptionFontSize16px: 16px; // Product Details Description Size
    --productDetailsDescriptionFontWeight400: 400; // Product Details Description weight

    --customerReviewsTitleFontSize15px: 15px; // Customer Reviews Title size
    --customerReviewsTitleFontWeight500: 500; // Customer Reviews Title weight
    --customerReviewsDetailsFontSize14px: 14px; // Customer Reviews details size
    --customerReviewsDetailsFontWeight400: 400; // Customer Reviews details weight

    --notificationsTitleFontSize15px: 15px; // Notifications Title size
    --notificationsTitleFontWeight500: 500; // Notifications Title weight
    --notificationsSubTitleFontSize13px: 13px; // Notifications sub-Title size
    --notificationsSubTitleFontWeight400: 400; // Notifications sub-Title weight

    --profileHeadingFontSize16px: 16px; // Profile Heading size
    --profileHeadingFontWeight600: 600; // Profile Heading weight
    --profileContentFontSize14px: 14px; // Profile Content size
    --profileContentFontWeight500: 500; // Profile Content weight

    --othersContentTitleFontSize24px: 24px; // Others Content Title size
    --othersContentTitleFontWeight600: 600; // Others Content Title weight
    --othersContentDescriptionFontSize16px: 16px; // Others Content Description size
    --othersContentDescriptionFontWeight400: 400; // Others Content Description weight

    --faqTitleHeaderFontSize15px: 15px; // Faq Title Header size
    --faqTitleHeaderFontWeight600: 600; // Faq Title Header weight
    --faqTitleDetailsFontSize14px: 14px; // Faq Title Details size
    --faqTitleDetailsFontWeight400: 400; // Faq Title Details weight

    --primaryButtonFontSize14px: 14px; // Primary Button size
    --primaryButtonFontWeight500: 500; // Primary Button weight
    --secondaryButtonFontSize14px: 14px; // Secondary Button size
    --secondaryButtonFontWeight500: 500; // Secondary Button weight

    --fontFamilyRoboto: "Roboto", sans-serif;
    --fontFamilyPoppins: "Poppins", sans-serif;
    --fontFamilyUbuntu: "Ubuntu", sans-serif;
    --fontFamilyOpenSans: "Open Sans", sans-serif;
}
// font-family start
$fontFamilyRoboto: var(--fontFamilyRoboto);
$fontFamilyPoppins: var(--fontFamilyPoppins);
$fontFamilyUbuntu: var(--fontFamilyUbuntu);
$fontFamilyOpenSans: var(--fontFamilyOpenSans);
// font-family end
// font size start
$fontSize10px: 10px;
$fontSize11px: 11px;
$fontSize12px: 12px;
$fontSize13px: 13px;
$fontSize14px: 14px;
$fontSize15px: 15px;
$fontSize16px: 16px;
$fontSize17px: 17px;
$fontSize18px: 18px;
$fontSize20px: 20px;
$fontSize21px: 21px;
$fontSize22px: 22px;
$fontSize24px: 24px;
$fontSize25px: 25px;
$fontSize26px: 26px;
$fontSize28px: 28px;
$fontSize32px: 32px;
$fontSize34px: 34px;
$fontSize40px: 40px;
$fontSize60px: 60px;
// font size end
// font-weight start
$fontWeight400: 400;
$fontWeight500: 500;
$fontWeight600: 600;
$fontWeight700: 700;
// font-weight end
// color start
$reseneNightOwlColor: #483335;
$whiteSmokeColor: #f4f4f4;
$lightBlackColor: #282c34;
$skyBlueColor: #61dafb;
$lightGrayishCyan: #dff2f7;
$darkGrayColor: #445568;
$whiteSmokeDarkColor: #f9f9f9;
$ballerinaSilkColor: #f2e2e4;
$pinkSwanColor: #bab3b3;
$longLakeColor: #66737d;

$purplishBrownColor: #5f4d4f;
$navyBlueColor: #045ec6;
$whisperColor: #e8e6e6;
$grayColor: #808080;
$yellowColor: #d99b0e;
$energyGreenColor: #1baa57;
$reddishBlack: #413334;
$fireAxeRedColor: #ca1815;
$doveGrey: #6c6c6c;
$lynxWhiteColor: #f7f7f7;

$buttonBlueColor: #2498f1;
$sugarTreeColor: #a3999a;
$paradiseGrapeColor: #766668;
$mintCreamColor: #f0fff7;
$tulipColor: #ff8592;
$spanishGrayColor: #939393;
$rocketMetallicColor: #878080;
$tealishColor: #25b99a;
$coyColor: #fff5f5;
$resonantBlueColor: #04a0c6;

$lineDriedSheetsColor: #f5eded;
$homeopathicLimeColor: #e5f5df;
$superSilverColor: #f1f1f1;
$crystallizeColor: #ede1e1;
$pantoneColor: #746466;
$tomatoBabyColor: #e30c22;
$zambiaColor: #fc9b10;
$waitingColor: #9d9d9d;
$lovelyEuphoricDelightColor: #fffbff;
$hyacinthTintColor: #b8c2d3;

$aliceBlueColor: #edf6ff;
$watermillWoodColor: #d1cccd;
$snowColor: #fff7f7;
$glimpseOfPinkColor: #fff2f3;
$breathOfFireColor: #ea0018;
$chestyBondColor: #5273a1;
$liquoriceColor: #120303;
$singingBluesColor: #2b4b65;
$mithrilColor: #898686;
$iceBlueGreyColor: #747c8a;

$carouselPinkColor: #f8dcde;
$warpDriveColor: #f5f9f9;
$perfectWhiteColor: #f7f5f5;
$pinkLavenderColor: #d8abc2;
$carbonColor: #333333;
$blueStreakColor: #1c60be;
$retroBlueColor: #2f7bf5;
$brightCamouflageColor: #1baa71;
$whippedCoconutCreamColor: #edece7;
$citrusSplashColor: #ffc502;

$nightshadeVioletColor: #a282aa;
$schiavaBlueColor: #1a2269;
$voidColor: #050722;
$doctorColor: #f8f8f8;
$mintBonbonGreenColor: #7eb6aa;
$ghostwaverColor: #e0dada;
$earlyCrocusColor: #eae8e8;
$lightPinkPolarColor: #d9c9cc;
$fancyPinkColor: #fcf7f7;
$rockCrystalColor: #d0cccc;

$dullColor: #707070;
$mantellaFrogColor: #ffbd08;
$sefidWhiteColor: #fff0f0;
$insigniaWhiteColor: #f3f7fb;
$silverBirdColor: #fcf8f4;
$heavySugarColor: #f4f8f5;
$royalNavyBlueColor: #015ec6;
$angelFeatherColor: #fdfcfc;
$perfumeHazeColor: #f2e5f5;
$cottagecoreSunsetColor: #ffdbdb;

$coquetteColor: #e5dbdb;
$floridaWatersColor: #26477f;
$silverbackColor: #cbcbcb;
$brillianceColor: #fcfcfc;
$rusticLightRedColor: #31191c;
$whiteColor: #fff;
$rusticRedColor: #1a0003;
$blackColor: #000000;
$blueWhaleColor: #1a2d3c;

$faceBookColor: #316ff6;
$instagramColor: #e1306c;
$linkedinColor: #0077b5;
$youtubeColor: #ff0000;

$ivoryColor: var(--headerBackgroundColor); // header bg
$armorWashColor: var(--headerTextColor); // header text
$temptressColor: var(--headerIconColor); // header icon
$temptreColor: var(--navigationMenuColor); // Navigation menu
$temptColor: var(--navigationSubMenuColor); // Navigation sub menu
$darkCeruleanColor: var(--footerBackgroundColor); // footer bg
$footerHeadingTextWhiteColor: var(--footerHeadingTextColor); // footer heading text
$huskyColor: var(--footerTextColor); // footer text
$companyPrimaryColor: var(--primaryButtonBackgroundColor); // Primary Button bg color
$companyPrimaryButtonTextColor: var(--primaryButtonTextColor); // Primary Button text color
$companySecondaryColor: var(--secondaryButtonBackgroundColor); // Secondary Button bg color
$companySecondaryButtonTextColor: var(--secondaryButtonTextColor); // Secondary Button text color
// color end

$navigationMenuSize14px: var(--navigationMenuFontSize14px); // Navigation menu size
$navigationMenuWeight600: var(--navigationMenuFontWeight600); // Navigation menu weight
$navigationSubMenuSize16px: var(--navigationSubMenuFontSize16px); // Navigation sub-menu size
$navigationSubMenuWeight600: var(--navigationSubMenuFontWeight600); // Navigation sub-menu weight

$productTitleSize14px: var(--productTitleFontSize14px); // Product Title size
$productTitleWeight400: var(--productTitleFontWeight400); // Product Title weight
$productSubTitleSize16px: var(--productSubTitleFontSize16px); // Product sub-Title size
$productSubTitleWeight400: var(--productSubTitleFontWeight400); // Product sub-Title weight
$productPriceSize18px: var(--productPriceFontSize18px); // Product Price size
$productPriceWeight600: var(--productPriceFontWeight600); // Product Price weight

$productDetailsTitleSize20px: var(--productDetailsTitleFontSize20px); // Product Details Title size
$productDetailsTitleWeight600: var(--productDetailsTitleFontWeight600); // Product Details Title weight
$productDetailsSubTitleSize16px: var(--productDetailsSubTitleFontSize16px); // Product Details sub-Title size
$productDetailsSubTitleWeight400: var(--productDetailsSubTitleFontWeight400); // Product Details sub-Title weight
$productDetailsPriceSize22px: var(--productDetailsPriceFontSize22px); // Product Details Price size
$productDetailsPriceWeight600: var(--productDetailsPriceFontWeight600); // Product Details Price weight
$productDetailsDescriptionHeadingSize18px: var(
    --productDetailsDescriptionHeadingFontSize18px
); // Product Details Description Heading size
$productDetailsDescriptionHeadingWeight600: var(
    --productDetailsDescriptionHeadingFontWeight600
); // Product Details Description Heading weight
$productDetailsDescriptionSize16px: var(--productDetailsDescriptionFontSize16px); // Product Details Description size
$productDetailsDescriptionWeight400: var(
    --productDetailsDescriptionFontWeight400
); // Product Details Description weight

$customerReviewsTitleSize15px: var(--customerReviewsTitleFontSize15px); // Customer Reviews Title size
$customerReviewsTitleWeight500: var(--customerReviewsTitleFontWeight500); // Customer Reviews Title weight
$customerReviewsDetailsSize14px: var(--customerReviewsDetailsFontSize14px); // Customer Reviews details size
$customerReviewsDetailsWeight400: var(--customerReviewsDetailsFontWeight400); // Customer Reviews details weight

$notificationsTitleSize15px: var(--notificationsTitleFontSize15px); // Notifications Title size
$notificationsTitleWeight500: var(--notificationsTitleFontWeight500); // Notifications Title weight
$notificationsSubTitleSize13px: var(--notificationsSubTitleFontSize13px); // Notifications sub-Title size
$notificationsSubTitleWeight400: var(--notificationsSubTitleFontWeight400); // Notifications sub-Title weight

$profileHeadingSize16px: var(--profileHeadingFontSize16px); // Profile Heading size
$profileHeadingWeight600: var(--profileHeadingFontWeight600); // Profile Heading weight
$profileContentSize14px: var(--profileContentFontSize14px); // Profile Content size
$profileContentWeight500: var(--profileContentFontWeight500); // Profile Content weight

$othersContentTitleSize24px: var(--othersContentTitleFontSize24px); // Others Content Title size
$othersContentTitleWeight600: var(--othersContentTitleFontWeight600); // Others Content Title weight
$othersContentDescriptionSize16px: var(--othersContentDescriptionFontSize16px); // Others Content Description size
$othersContentDescriptionWeight400: var(--othersContentDescriptionFontWeight400); // Others Content Description weight

$faqTitleHeaderSize15px: var(--faqTitleHeaderFontSize15px); // Faq Title Header size
$faqTitleHeaderWeight600: var(--faqTitleHeaderFontWeight600); // Faq Title Header weight
$faqTitleDetailsSize14px: var(--faqTitleDetailsFontSize14px); // Faq Title Details size
$faqTitleDetailsWeight400: var(--faqTitleDetailsFontWeight400); // Faq Title Details weight

$primaryButtonSize14px: var(--primaryButtonFontSize14px); // Primary Button size
$primaryButtonWeight500: var(--primaryButtonFontWeight500); // Primary Button weight
$secondaryButtonSize14px: var(--secondaryButtonFontSize14px); // Secondary Button size
$secondaryButtonWeight500: var(--secondaryButtonFontWeight500); // Secondary Button weight
