@import "variables";

#checkoutSec {
    // delivery-details-card start
    .delivery-details-card {
        .card {
            border: 0;
            border-radius: 0;
        }

        .card-header {
            display: flex;
            justify-content: space-between;
            background-color: $whiteColor;
            align-items: center;
            border-bottom: unset;

            h1 {
                font-size: $fontSize18px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
        }
        .card-body {
            .d-flex {
                h2 {
                    font-size: $fontSize18px;
                    font-weight: $fontWeight500;
                    color: $rusticRedColor;
                    margin-bottom: 0;
                    .fa-home {
                        color: $companyPrimaryColor;
                        font-size: $fontSize22px;
                    }
                }
                .btn {
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;
                    color: $reseneNightOwlColor;
                    background-color: $perfectWhiteColor;
                }
            }
            .nav-pills {
                .nav-item {
                    .nav-link {
                        font-size: $fontSize14px;
                        color: $rusticRedColor;
                        background-color: $whisperColor;
                        border-radius: 4px;
                        &.active {
                            color: $companySecondaryButtonTextColor;
                            background-color: $companySecondaryColor;
                        }
                        i.tra {
                            transform: scaleX(-1);
                        }
                        i {
                            margin-right: 6px;
                        }
                    }
                }
            }
            h3 {
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                color: $rusticRedColor;
                margin-bottom: 0px;
            }
            p {
                font-size: $fontSize14px;
                margin-bottom: 4px;
            }
            .fa-map-marker {
                font-size: $fontSize22px;
                color: $companyPrimaryColor;
            }
            .shop-btn {
                position: absolute;
                right: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $fontSize14px !important;
                font-weight: $fontWeight500;
                color: $companyPrimaryButtonTextColor !important;
                background-color: $companyPrimaryColor !important;
                i {
                    margin-right: 6px;
                }
            }

            .tab-content {
                .tab-pane {
                    h3 {
                        font-size: $fontSize16px;
                        font-weight: $fontWeight600;
                        color: $rusticRedColor;
                    }
                    h4 {
                        font-size: $fontSize14px;
                        font-weight: $fontWeight600;
                        color: $rusticRedColor;
                        margin-bottom: 0;
                    }
                    .btn {
                        font-size: $fontSize14px;
                        font-weight: $fontWeight500;
                        color: $companyPrimaryButtonTextColor;
                        background-color: $companyPrimaryColor;
                        width: 100%;
                    }
                    .shop-btn {
                        width: 127px;
                        white-space: nowrap;
                    }
                    .form-check {
                        .form-check-label {
                            font-size: $fontSize14px;
                            font-weight: $fontWeight500;
                            color: $reseneNightOwlColor;
                        }
                    }
                    input {
                        &[type="text"],
                        &[type="email"] {
                            background: $whiteSmokeDarkColor;
                            border: 1px solid $earlyCrocusColor;
                            border-radius: 4px;
                        }
                        &:focus {
                            outline: none;
                            box-shadow: none;
                            border-color: $companyPrimaryColor;
                        }
                    }
                    .address-list {
                        display: flex;
                        flex-direction: row;
                        .address-tile {
                            display: flex;
                            align-items: baseline;
                            flex-direction: column;
                            width: 350px;
                            height: 245px;
                            margin-right: 1rem;
                            &:last-child {
                                margin-right: 0;
                            }
                            .user-detail {
                                background: $brillianceColor;
                                border: 1px solid $fancyPinkColor;
                                border-radius: 4px 4px 0px 0px;
                                opacity: 1;
                                padding: 1.5rem;
                                width: 100%;
                                height: 100%;
                                position: relative;
                                h2,
                                p {
                                    font-size: $fontSize16px;
                                    color: $reseneNightOwlColor;
                                    margin-bottom: 0.5rem;
                                    font-weight: $fontWeight600;
                                }
                                h2 {
                                    text-transform: capitalize;
                                }
                                p {
                                    font-weight: $fontWeight500;
                                    font-size: $fontSize14px;
                                    margin-top: -4px;
                                }
                                input[type="checkbox"] {
                                    position: absolute;
                                    right: 10px;
                                    top: 10px;
                                    height: 16px;
                                    width: 16px;
                                }
                                &:hover {
                                    background: $glimpseOfPinkColor;
                                }
                                &:has(input[type="checkbox"]:checked) {
                                    background: $glimpseOfPinkColor;
                                }
                            }
                        }
                        .react-multiple-carousel__arrow {
                            background: $brillianceColor 0% 0% no-repeat padding-box;
                            border: 1px solid $fancyPinkColor;
                            border-radius: 4px 4px;
                            height: 60%;
                            box-shadow:
                                0 2px 8px rgba(31, 30, 36, 0.16),
                                0 0 2px rgba(31, 30, 36, 0.16);
                            &::before {
                                color: $rusticLightRedColor;
                                font-weight: $fontWeight600;
                                font-size: $fontSize24px;
                            }
                            &.react-multiple-carousel__arrow--right {
                                right: calc(0% + 1px);
                            }
                            &.react-multiple-carousel__arrow--left {
                                left: calc(0% + 1px);
                            }
                        }
                    }
                }
            }
        }
    }
    .save-address {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60vh;
        h2,
        p {
            color: $rusticLightRedColor;
            font-size: $fontSize16px;
            font-weight: $fontWeight500;
            text-decoration: none;
            text-align: center;
            margin-bottom: 2px;
        }
        p {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
        }
        .address-btn {
            color: $companyPrimaryButtonTextColor !important;
            background-color: $companyPrimaryColor !important;
            border-color: $companyPrimaryColor !important;
            width: 140px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $fontSize14px !important;
            font-weight: $fontWeight400;
            border-radius: 4px;
            &:hover {
                color: $companyPrimaryColor !important;
                background-color: $companyPrimaryButtonTextColor !important;
                border: 1px solid $companyPrimaryColor;
                i {
                    color: $whiteColor !important;
                }
            }
            i {
                font-size: $fontSize12px;
                color: $companyPrimaryColor;
                margin-right: 4px;
            }
        }
        img {
            height: 16%;
        }
        a {
            text-decoration: none;
            color: inherit;
        }
    }
    // delivery-details-card end
    // order-summary-details-card start
    .order-summary-details-card {
        border: unset;
        .card-header {
            display: flex;
            justify-content: space-between;
            background-color: $whiteColor;
            align-items: center;

            h1 {
                font-size: $fontSize18px;
                font-weight: $fontWeight500;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
            .btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                color: $reseneNightOwlColor;
                background-color: $perfectWhiteColor;
            }
        }
        .card-body {
            table {
                tbody {
                    tr {
                        td:nth-of-type(1) {
                            img {
                                background-color: $whiteColor;
                                border: 1px solid $whisperColor;
                                width: 138px;
                                height: 157px;
                                object-fit: contain;
                            }
                        }
                        td:nth-of-type(2) {
                            font-size: $fontSize14px;
                            font-weight: $fontWeight600;
                            color: $rusticRedColor;
                            margin-bottom: 0;
                            width: 100%;
                            h6 {
                                font-size: $fontSize18px;
                                font-weight: $fontWeight600;
                                color: $rusticRedColor;
                            }
                            .btn {
                                font-size: $fontSize12px;
                                font-weight: $fontWeight500;
                                color: $navyBlueColor;
                                background-color: $aliceBlueColor;
                                width: 120px;
                                &:focus {
                                    border: unset;
                                }
                                &:active {
                                    border: unset;
                                }
                            }
                            .price-details {
                                white-space: nowrap;
                                h2 {
                                    font-size: $fontSize20px;
                                    font-weight: $fontWeight600;
                                    color: $companyPrimaryColor;
                                }
                                h3 {
                                    font-size: $fontSize18px;
                                    font-weight: $fontWeight600;
                                    color: $rusticRedColor;
                                    text-decoration: line-through;
                                }
                                h4 {
                                    font-size: $fontSize16px;
                                    font-weight: $fontWeight600;
                                    color: $energyGreenColor;
                                }
                            }
                            p {
                                font-size: $fontSize14px;
                                color: $energyGreenColor;
                                margin-bottom: 0;
                            }
                            .pdt-model {
                                span {
                                    font-weight: $fontWeight600;
                                    color: grey;
                                }
                            }
                            .qty-price {
                                color: black;
                                font-size: $fontSize16px;
                                font-weight: $fontWeight400;
                                margin-bottom: 2px;
                                span {
                                    font-weight: $fontWeight600;
                                    color: grey;
                                }
                            }
                        }
                        td:nth-of-type(3) {
                            vertical-align: middle;
                            h5 {
                                font-size: $fontSize14px;
                                font-weight: $fontWeight600;
                                color: $rusticRedColor;
                            }
                            .btn {
                                font-size: $fontSize16px;
                                color: $rusticRedColor;
                                background-color: $superSilverColor;
                                &:focus {
                                    border: unset;
                                }
                                &:active {
                                    border: unset;
                                }
                            }
                            .count-num {
                                background-color: $glimpseOfPinkColor !important;
                                border: 0px;
                                height: 37px;
                                margin-top: 10px;
                                padding: 6px 12px;
                                width: 75px;
                            }
                        }
                    }
                }
            }
            .btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                color: $companyPrimaryButtonTextColor;
                background-color: $companyPrimaryColor;
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    // order-summary-details-card end
    // select-payment-method-card start
    .select-payment-method-card {
        border: unset;
        .card-header {
            display: flex;
            justify-content: space-between;
            background-color: $whiteColor;
            align-items: center;

            h1 {
                font-size: $fontSize18px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
            .btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                color: $reseneNightOwlColor;
                background-color: $perfectWhiteColor;
            }
        }
        .card-body {
            .form-check {
                .form-check-label {
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;
                    color: $reseneNightOwlColor;
                }
            }
            p {
                font-size: $fontSize12px;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
            img {
                background-color: $whiteColor;
                border: 1px solid $ghostwaverColor;
                border-radius: 2px;
                width: 75px;
                padding: 2px;
                height: 45px;
                object-fit: contain;
            }
            .btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                color: $companyPrimaryButtonTextColor;
                background-color: $companyPrimaryColor;
                width: 100%;
                a {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
    }
    // select-payment-method-card end

    // order-summary-card start
    .order-summary-card {
        border: unset;
        .card-header {
            background-color: $whiteColor;
            border-bottom: unset;
            h2 {
                font-size: $fontSize16px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
        }
        .card-body {
            padding-top: 0;
            table {
                tbody {
                    tr {
                        th,
                        td {
                            font-size: $fontSize14px;
                            vertical-align: middle;
                        }
                        th {
                            font-weight: $fontWeight400;
                            color: $rusticRedColor;
                            white-space: nowrap;
                        }
                        td {
                            color: $rusticRedColor;
                            font-weight: $fontWeight600;
                            white-space: nowrap;
                        }
                    }
                    tr:nth-of-type(2) {
                        td {
                            font-weight: $fontWeight600;
                        }
                    }
                    tr:nth-of-type(3) {
                        td {
                            color: $rusticRedColor;
                        }
                    }
                    tr:nth-of-type(4) {
                        td {
                            color: $rusticRedColor;
                            font-weight: $fontWeight600;
                        }
                    }
                }
                tfoot {
                    border-top: 1px solid $superSilverColor;
                    tr {
                        th:nth-of-type(2) {
                            font-size: $fontSize18px;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
    // order-summary-card end
}
.tab-content {
    .react-multi-carousel-list {
        .react-multiple-carousel__arrow--right,
        .react-multiple-carousel__arrow--left {
            height: 10px !important;
        }
    }
}

.coupon-card {
    border: 0;
    border-radius: 0;
    .card-header {
        background-color: $whiteColor;
        border: 0;
        padding-top: 1rem;
        h2 {
            font-size: $fontSize16px;
            font-weight: $fontWeight600;
            color: $rusticRedColor;
            margin-bottom: 0;
        }
    }
    .accordion {
        .accordion-item {
            border: none;
        }
        .accordion-header {
            button {
                background-color: $lightGrayishCyan;
                font-size: $fontSize12px;
                font-weight: $fontWeight500;
                color: $resonantBlueColor;
                padding: 0;
                border: unset;
                outline: none;
                box-shadow: none;
                &::after {
                    background-size: 15px !important;
                    margin-top: 3px;
                }
            }
        }
        .accordion-collapse {
            background-color: $snowColor;
            .accordion-body {
                padding: 0px;
                font-size: $fontSize13px;
                color: $rusticRedColor;
                overflow-y: scroll;
                height: 57px;
                background-color: $lightGrayishCyan;
                ul {
                    padding: 0px;
                    margin: 0px;
                }
            }
        }
    }
    .coupons {
        border: 2px dashed $resonantBlueColor;
        background-color: $lightGrayishCyan;
        padding: 10px;
        position: relative;
        display: inline-block;
        width: 100%;
        .close-button {
            position: absolute;
            top: -12px;
            right: -12px;
            background: rgba(0, 0, 0, 0.5);
            color: $whiteColor;
            border: none;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: $fontSize12px;
            background-color: black;
        }
    }
    .card-body {
        .input-group {
            input {
                font-size: $fontSize14px;
                border: 1px solid $ghostwaverColor;
                border-radius: 2px;
                border-right: 0px !important;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .input-group-text {
                background-color: $whiteColor;
                .fa-search {
                    margin-left: 5px;
                    color: $sugarTreeColor;
                }
            }
            .apply-btn {
                font-size: $fontSize14px;
                background-color: $watermillWoodColor;
                color: $rusticLightRedColor;
                border-radius: 2px;
                line-height: 24px;
                font-weight: $fontWeight600;
                &:focus {
                    border: unset;
                }
                &:active {
                    border: unset;
                }
            }
        }
        img {
            width: 85px;
            margin-top: 15px;
            margin-bottom: 10px;
        }
        h3 {
            font-size: $fontSize14px;
            color: $rusticRedColor;
        }
        .form-check {
            label {
                font-size: $fontSize14px;
                font-weight: $fontWeight600;
            }
        }
        p {
            font-size: $fontSize12px;
            font-weight: $fontWeight500;
            color: $rusticRedColor;
            margin-bottom: 0;
            span {
                font-weight: $fontWeight600;
            }
        }
        i {
            font-size: $fontSize12px;
            cursor: pointer;
        }
        .view-btn {
            font-size: $fontSize12px;
            font-weight: $fontWeight500;
            color: $resonantBlueColor;
            background-color: transparent;
            padding: 0;
            border: unset;
            &:focus {
                border: unset;
            }
            &:active {
                border: unset;
            }
            a {
                text-decoration: none;
                color: inherit;
            }
        }
    }
    .card-footer {
        text-align: center;
        background-color: $whiteColor;
        .btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
            color: $rusticRedColor;
            background-color: transparent;
            padding: 0;
            &:focus {
                border: unset;
            }
            &:active {
                border: unset;
            }
        }
    }
}

.Coupon-Offers {
    .modal-dialog {
        position: fixed;
        margin: -1px;
        height: 100%;
        right: 0;
        top: 0;
        bottom: 0;
        width: 400px;
        .modal-content {
            height: 100vh;
            border-radius: 0;
            overflow-x: hidden;
            overflow-y: scroll;
            .modal-header {
                .modal-title {
                    font-size: $fontSize18px !important;
                    font-weight: $fontWeight600 !important;
                }
                .btn-close {
                    font-size: $fontSize10px;
                    color: $paradiseGrapeColor !important;
                    border: none;
                    background-color: $whiteColor;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
            .modal-body {
                .input-group {
                    input {
                        border: 1px solid $rockCrystalColor;
                        border-right: 0px !important;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
                .form-check {
                    input {
                        border-color: $carbonColor;
                        border: 1px solid;
                    }
                    label {
                        width: 100%;
                    }
                }
                .input-group-text {
                    background-color: $whiteColor;
                    border: 1px solid $rockCrystalColor;
                    .fa-search {
                        margin-left: 5px;
                        color: $sugarTreeColor;
                    }
                }
                .accordion {
                    .accordion-item {
                        border: none;
                    }
                    .accordion-header {
                        button {
                            background-color: $whiteColor;
                            font-size: $fontSize12px;
                            font-weight: $fontWeight500;
                            color: $resonantBlueColor;
                            padding: 0;
                            border: unset;
                            outline: none;
                            box-shadow: none;
                            &::after {
                                background-size: 15px !important;
                            }
                        }
                    }
                    .accordion-collapse {
                        background-color: $whiteColor;
                        .accordion-body {
                            padding: 0px;
                            font-size: $fontSize13px;
                            color: $rusticRedColor;
                            overflow-y: auto;
                            overflow-x: hidden;
                            max-height: 400px;
                            min-height: 50px;
                            ul {
                                padding: 0px;
                                margin: 0px;
                            }
                            p {
                                font-size: $fontSize12px;
                            }
                        }
                    }
                }
                h4 {
                    font-size: $fontSize12px;
                    color: $sugarTreeColor;
                }
                p {
                    margin-bottom: 0px;
                    font-weight: $fontWeight500;
                    font-size: $fontSize13px;
                    span {
                        font-weight: $fontWeight600;
                        font-size: $fontSize14px;
                        text-transform: lowercase;
                    }
                }
                hr {
                    color: $pinkLavenderColor !important;
                }
                .copy-url {
                    width: 248px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .view-btn {
                    font-size: $fontSize12px;
                    font-weight: $fontWeight500;
                    color: $resonantBlueColor;
                    background-color: transparent;
                    padding: 0;
                    border: unset;
                    a {
                        text-decoration: none;
                        color: inherit;
                    }
                }
            }
            .modal-footer {
                border-top: none;
                .btn {
                    font-size: $fontSize14px;
                    font-weight: $fontWeight600;
                    color: $whiteColor;
                    background-color: $watermillWoodColor;
                    width: 100%;
                    border-radius: 4px;
                    border-color: $watermillWoodColor;
                }
            }
        }
    }
}
