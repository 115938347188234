.overlap-title {
    .text-title {
        font-size: $fontSize21px;
    }
}
.all-product-list-container {
    .bread-crumbs {
        .card {
            border: unset;
            .card-body {
                padding: 5px 10px;
            }
            p {
                margin-bottom: 0px;
            }
            .selected-filters {
                overflow: auto;
                p {
                    margin-bottom: 0px;
                    color: $navyBlueColor;
                    font-weight: $fontWeight600;
                    white-space: nowrap;
                    font-size: $fontSize14px;
                }
                .btn {
                    background: transparent;
                    border: 1px solid $earlyCrocusColor;
                    border-radius: 20px;
                    padding-right: 5px;
                    background: $whiteSmokeDarkColor;
                    color: $pantoneColor;
                    white-space: nowrap;
                    font-size: $fontSize14px;
                    span {
                        background-color: $reseneNightOwlColor;
                        color: $whiteColor;
                        border-radius: 50%;
                        padding: 1px 3px;
                        margin-left: 6px;
                        font-weight: $fontWeight500;
                    }
                }
            }
            .filter-dropdown {
                .btn {
                    background: transparent;
                    border: none;
                    letter-spacing: 0px;
                    color: $paradiseGrapeColor;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;
                }
                .dropdown-menu {
                    padding: 0px;
                    width: 300px;
                    border-radius: 1px;
                    border: 1px solid rgba(200, 200, 200, 0.3411);
                    right: 0;
                    left: auto;
                }
                .dropdown-item {
                    padding: 0px;
                    &.active,
                    &:active,
                    &:hover {
                        background-color: $whiteColor;
                        color: black;
                        outline: none;
                        border: none;
                    }
                }
                .dropdown-item {
                    .accordion {
                        background: $whiteColor;
                        .cancel-btn {
                            font-size: $fontSize14px;
                            font-weight: $fontWeight400;
                            color: $whiteColor;
                            background-color: $pinkSwanColor;
                            width: 50%;
                            border-radius: 4px;
                            padding: 6px 12px;
                            margin: 10px;
                        }
                        .shop-btn {
                            background-color: $companyPrimaryColor;
                            color: $companyPrimaryButtonTextColor;
                            padding: 6px 12px;
                            white-space: nowrap;
                            margin: 10px;
                            border-radius: 4px;
                            width: 50%;
                        }
                        .accordion-item {
                            border: none;
                            border-radius: 0;
                            background-color: $whiteColor;
                            border-bottom: 1px solid rgba(200, 200, 200, 0.3411764706);
                            .accordion-header {
                                .clear-btn {
                                    color: $tomatoBabyColor;
                                    font-size: $fontSize12px;
                                    font-weight: $fontWeight500;
                                    text-decoration: none;
                                }
                            }
                            .accordion-body {
                                padding-top: 0px;
                                overflow: auto;
                                max-height: 312px;
                            }
                            .accordion-button {
                                border: 0;
                                border-radius: 0;
                                background-color: transparent;
                                box-shadow: none;
                                padding-left: 55px;
                                color: $rusticRedColor;
                                font-size: $fontSize15px !important;
                                font-weight: $fontWeight600;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding-top: 12px;
                                padding-bottom: 10px;

                                .clear-btn {
                                    color: $tomatoBabyColor;
                                    font-size: $fontSize12px;
                                    font-weight: $fontWeight500;
                                    text-decoration: none;
                                    display: none;
                                }

                                &[aria-expanded="true"] {
                                    .clear-btn {
                                        display: block;
                                    }
                                }

                                &::after {
                                    content: "\f068";
                                    font-family: FontAwesome;
                                    left: 0;
                                    position: absolute;
                                    background: $whisperColor;
                                    border-radius: 2px;
                                    font-size: $fontSize10px;
                                    font-weight: normal;
                                    color: $blackColor;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-left: 20px;
                                    padding: 2px 5px;
                                    transition: none;
                                }

                                &.collapsed {
                                    &::after {
                                        content: "\f067";
                                    }
                                }
                            }

                            .accordion-body {
                                h4 {
                                    color: $rusticRedColor;
                                    font-size: $fontSize15px;
                                    font-weight: $fontWeight600;
                                    text-align: left;
                                }

                                .input-group {
                                    flex-wrap: nowrap !important;

                                    input {
                                        background: $superSilverColor;
                                        border: none;
                                        border-radius: 0px 2px 2px 0px;
                                        font-size: $fontSize14px;
                                        font-weight: $fontWeight500;
                                        width: 100%;
                                        padding: 8px 10px;
                                        color: $reseneNightOwlColor;
                                        padding-left: 4px;

                                        &:focus {
                                            outline: none;
                                            box-shadow: none;
                                            border: none;
                                        }
                                    }

                                    .input-group-text {
                                        background: $superSilverColor;
                                        border: none;
                                        border-radius: 2px 0px 0px 2px;
                                        border-left: 0 !important;
                                        padding-right: 3px;

                                        i {
                                            color: $reseneNightOwlColor;
                                            font-size: $fontSize14px;
                                        }
                                    }
                                }

                                ul {
                                    padding-left: 15px;
                                    margin-top: 15px;

                                    li {
                                        display: flex;
                                        align-items: center;
                                        margin: 8px 0;

                                        input[type="checkbox"] {
                                            accent-color: $companyPrimaryColor;
                                            width: 30px;
                                            height: 16px;
                                        }

                                        label {
                                            color: $rusticRedColor;
                                            font-size: $fontSize14px;
                                            font-weight: $fontWeight500;
                                            text-align: left;

                                            i {
                                                &.fa-star {
                                                    color: $zambiaColor;
                                                    font-size: $fontSize14px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .bacground-sec {
        align-items: end;
        background-image: url(../images/products/product-banner.png);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        height: 275px;
        width: 100%;
        position: relative;

        .overlap-title {
            position: absolute;
            left: 23.8%;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h2 {
                letter-spacing: 0px;
                color: $whiteSmokeColor;
                opacity: 1;
                font-size: $fontSize28px;
                font-weight: $fontWeight500;
                line-height: 42px;
            }
        }
    }

    .product-list {
        .subproduct-carousel {
            justify-content: safe center;
        }
        .product-thumb,
        .products-sec {
            background-color: $whiteColor;
            .overlap-title {
                h2 {
                    text-align: center;
                    text-transform: capitalize;
                    color: $rusticRedColor;
                    font-size: $fontSize22px;
                    font-weight: $fontWeight500;
                    p {
                        margin-bottom: 0px;
                        font-size: $fontSize20px;
                    }

                    &.pg {
                        line-height: 32px;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 0px;
                    }
                }
            }

            .thumb-list {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 30px 0 15px;

                .product-container {
                    position: relative;
                    display: inline-block;
                    .product-box {
                        position: relative;
                        overflow: hidden;
                        align-items: center;
                        border-radius: 6px;
                        display: flex;
                        flex-direction: column;
                        width: 135px;
                        height: 135px;
                        justify-content: center;
                        padding: 10px 10px;
                        margin: 10px 10px;
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: 2px solid $tomatoBabyColor;
                            border-radius: 4px;
                            background: $coyColor;
                            opacity: 0;
                            transition: opacity 0.3s ease;
                            z-index: 0;
                        }
                        &:hover::before,
                        &:active::before {
                            opacity: 1;
                        }

                        img {
                            height: 145px;
                            width: 100px;
                            object-fit: contain;
                            display: block;
                            transition: opacity 0.3s ease;
                            z-index: 1;
                            &:hover img,
                            &:active img {
                                opacity: 1;
                            }
                        }
                    }

                    a {
                        color: inherit;
                        text-decoration: none;
                    }

                    p {
                        letter-spacing: 0px;
                        color: $rusticRedColor;
                        opacity: 1;
                        font-size: $fontSize18px;
                        font-weight: $fontWeight500;
                        text-align: center;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .products-sec {
            padding: 15px 20px;
            margin: 4px 0;

            .overlap-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0px;

                h2 {
                    text-align: left !important;
                    margin-bottom: 0;
                }

                .next-button {
                    background: $navyBlueColor;
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;

                    i {
                        color: $whiteColor;
                        font-size: $fontSize12px;
                    }
                }
            }
        }

        .bacground-sec {
            background-image: url(../images/products/p-bg.png);
            height: 400px;

            .overlap-title {
                left: 5%;
                flex-direction: column;

                .text-title {
                    font-size: $fontSize32px !important;
                    color: $whiteSmokeColor;
                    font-weight: $fontWeight600;
                    margin-bottom: 0;

                    span {
                        color: $zambiaColor;
                        font-size: $fontSize34px !important;
                    }
                }

                p {
                    color: $whiteSmokeColor;
                    font-size: $fontSize18px;
                    font-weight: $fontWeight600;
                }
            }
        }
    }
}
.product-sublist-row {
    .product-listing-col {
        flex: 0 0 20%;
    }
    .filter-sec {
        .title {
            background-color: $whiteColor;
            padding: 5px 15px;

            h4 {
                font-size: $fontSize16px;
                font-weight: $fontWeight500;
                text-align: left;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
            button {
                background-color: $companyPrimaryColor;
                color: $companyPrimaryButtonTextColor;
                padding: 4px 12px;
                white-space: nowrap;
            }
        }

        .accordion {
            margin-top: 0.25rem;

            .accordion-item {
                border: none;
                border-radius: 0;
                background-color: $whiteColor;
                border-bottom: 1px solid rgba(200, 200, 200, 0.3411764706);
                .accordion-body {
                    padding-top: 0px;
                }
                .accordion-button {
                    border: 0;
                    border-radius: 0;
                    background-color: transparent;
                    box-shadow: none;
                    padding-left: 55px;
                    color: $rusticRedColor;
                    font-size: $fontSize15px;
                    font-weight: $fontWeight600;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 12px;
                    padding-bottom: 10px;

                    .clear-btn {
                        color: $tomatoBabyColor;
                        font-size: $fontSize12px;
                        font-weight: $fontWeight500;
                        text-decoration: none;
                        display: none;
                    }

                    &[aria-expanded="true"] {
                        .clear-btn {
                            display: block;
                        }
                    }

                    &::after {
                        content: "\f068";
                        font-family: FontAwesome;
                        left: 0;
                        position: absolute;
                        background: $whisperColor;
                        border-radius: 2px;
                        font-size: $fontSize10px;
                        font-weight: normal;
                        color: $blackColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 20px;
                        padding: 2px 5px;
                        transition: none;
                    }

                    &.collapsed {
                        &::after {
                            content: "\f067";
                        }
                    }
                }

                .accordion-body {
                    h4 {
                        color: $rusticRedColor;
                        font-size: $fontSize15px;
                        font-weight: $fontWeight600;
                        text-align: left;
                    }

                    .input-group {
                        flex-wrap: nowrap !important;

                        input {
                            background: $superSilverColor;
                            border: none;
                            border-radius: 0px 2px 2px 0px;
                            font-size: $fontSize14px;
                            font-weight: $fontWeight500;
                            width: 100%;
                            padding: 8px 10px;
                            color: $reseneNightOwlColor;
                            padding-left: 4px;

                            &:focus {
                                outline: none;
                                box-shadow: none;
                                border: none;
                            }
                        }

                        .input-group-text {
                            background: $superSilverColor;
                            border: none;
                            border-radius: 2px 0px 0px 2px;
                            border-left: 0 !important;
                            padding-right: 3px;

                            i {
                                color: $reseneNightOwlColor;
                                font-size: $fontSize14px;
                            }
                        }
                    }

                    ul {
                        padding-left: 15px;
                        margin-top: 15px;

                        li {
                            display: flex;
                            align-items: center;
                            margin: 8px 0;

                            input[type="checkbox"] {
                                accent-color: $companyPrimaryColor;
                                width: 30px;
                                height: 16px;
                            }

                            label {
                                color: $rusticRedColor;
                                font-size: $fontSize14px;
                                font-weight: $fontWeight500;
                                text-align: left;

                                i {
                                    &.fa-star {
                                        color: $yellowColor;
                                        font-size: $fontSize14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sublist-filter {
        margin-right: -10px;
    }
    .filter-top-bar {
        background-color: $whiteColor;
        border: 0;
        border-radius: 0;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.25rem;

        h2 {
            color: $rusticLightRedColor;
            margin-bottom: 0;
            text-align: left;
            font-size: $fontSize16px;
            font-weight: $fontWeight500;
            text-transform: math-auto;

            span {
                color: $rocketMetallicColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
                margin: 0 4px;
            }
            .clear-search {
                color: $navyBlueColor !important;
                font-size: $fontSize13px;
                font-weight: $fontWeight500;
                cursor: pointer;
            }
        }

        .sort-list {
            display: flex;
            align-items: center;
            white-space: nowrap;

            a {
                margin: 0 12px;
                color: $rusticLightRedColor;
                font-size: $fontSize15px;
                font-weight: $fontWeight500;
                text-decoration: none;
                cursor: pointer;

                &.selected {
                    color: $navyBlueColor;
                }
            }
        }
    }
}
// @media (min-width: 1200px) and (max-width: 1399.98px) {
//     .product-sublist-row .product-listing-col,
//     .products-sec .product-carouselItem .carousel__row {
//         flex: 1 0 20%;
//     }
// }
// @media (min-width: 992px) and (max-width: 1199.98px) {
//     .product-sublist-row .product-listing-col,
//     .products-sec .product-carouselItem .carousel__row {
//         flex: 1 0 20%;
//     }
// }
@media (min-width: 767.98px) and (max-width: 991.98px) {
    .product-sublist-row .product-listing-col,
    .products-sec .product-carouselItem .carousel__row {
        flex: 0 0 33.33%;
    }
}

@media (max-width: 767.98px) {
    .product-sublist-row .product-listing-col,
    .products-sec .product-carouselItem .carousel__row {
        flex: 0 0 50%;
    }
}

@media (max-width: 575.98px) {
    .product-sublist-row .product-listing-col,
    .products-sec .product-carouselItem .carousel__row {
        flex: 0 0 100%;
    }
}
.title {
    a {
        color: inherit;
        text-decoration: none;
    }
}
.product-listing-col {
    flex: 0 0 20%;

    a {
        text-decoration: none;
        color: inherit;
    }
    .card {
        background-color: $whiteColor;
        height: 325px;
        overflow: auto;
        border: unset;
        .card-body {
            .image-box {
                align-items: center;
                background: $perfectWhiteColor;
                padding: 15px;
                img {
                    width: 100%;
                    height: 135px;
                    object-fit: contain;
                }
            }

            .product-details {
                h2 {
                    text-align: left;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight400;
                    color: $rusticLightRedColor;
                    word-break: break-word;
                    margin-bottom: 0;
                    span {
                        text-align: left;
                    }
                }
                p {
                    text-align: left;
                    font-size: $fontSize16px;
                    font-weight: $fontWeight400;
                    color: $rusticLightRedColor;
                    margin-bottom: 5px;
                    span {
                        text-align: left;
                    }
                }
                h3 {
                    color: $purplishBrownColor;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;
                    margin-bottom: 5px;
                    i {
                        color: $yellowColor;
                        font-size: $fontSize14px;
                        margin-right: 5px;
                    }
                }
                h4 {
                    letter-spacing: 0px;
                    color: $rusticRedColor;
                    font-size: $fontSize18px;
                    font-weight: $fontWeight600;
                    margin-bottom: 5px;
                }
            }
        }
        &::-webkit-scrollbar {
            display: none;
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    }
}
