@import "variables";

body {
    font-family: $fontFamilyRoboto;
    background: $whiteSmokeColor;
}

li {
    list-style: none;
}

.products-wrapper,
.home-wrapper,
.product-list-filter {
    .bacground-sec {
        align-items: end;
        background-image: url(../images/products/product-banner.png);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        height: 275px;
        width: 100%;
        position: relative;

        .overlap-title {
            position: absolute;
            left: 23.8%;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h2 {
                letter-spacing: 0px;
                color: $whiteSmokeColor;
                opacity: 1;
                font-size: $fontSize28px;
                font-weight: $fontWeight500;
                line-height: 42px;
            }
        }
    }

    .product-list {
        .subproduct-carousel {
            justify-content: safe center;
        }
        .product-thumb,
        .products-sec {
            background-color: $whiteColor;
            .overlap-title {
                h2 {
                    text-align: center;
                    text-transform: capitalize;
                    color: $rusticRedColor;
                    font-size: $fontSize22px;
                    font-weight: $fontWeight500;
                    p {
                        margin-bottom: 0px;
                        font-size: $fontSize20px;
                    }

                    &.pg {
                        line-height: 32px;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 0px;
                    }
                }
            }

            .thumb-list {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 30px 0 15px;

                .product-container {
                    position: relative;
                    display: inline-block;
                    .product-box {
                        position: relative;
                        overflow: hidden;
                        align-items: center;
                        border-radius: 6px;
                        display: flex;
                        flex-direction: column;
                        width: 135px;
                        height: 135px;
                        justify-content: center;
                        padding: 10px 10px;
                        margin: 10px 10px;
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: 2px solid $tomatoBabyColor;
                            border-radius: 4px;
                            background: $coyColor;
                            opacity: 0;
                            transition: opacity 0.3s ease;
                            z-index: 0;
                        }
                        &:hover::before,
                        &:active::before {
                            opacity: 1;
                        }

                        img {
                            height: 145px;
                            width: 100px;
                            object-fit: contain;
                            display: block;
                            transition: opacity 0.3s ease;
                            z-index: 1;
                            &:hover img,
                            &:active img {
                                opacity: 1;
                            }
                        }
                    }

                    a {
                        color: inherit;
                        text-decoration: none;
                    }

                    p {
                        letter-spacing: 0px;
                        color: $rusticRedColor;
                        opacity: 1;
                        font-size: $fontSize18px;
                        font-weight: $fontWeight500;
                        text-align: center;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .products-sec {
            padding: 15px 20px;
            margin: 4px 0;

            .overlap-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0px;

                h2 {
                    text-align: left !important;
                    margin-bottom: 0;
                }

                .next-button {
                    background: $navyBlueColor;
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;

                    i {
                        color: $whiteColor;
                        font-size: $fontSize12px;
                    }
                }
            }
        }

        .bacground-sec {
            background-image: url(../images/products/p-bg.png);
            height: 400px;

            .overlap-title {
                left: 5%;
                flex-direction: column;

                .text-title {
                    font-size: $fontSize32px !important;
                    color: $whiteSmokeColor;
                    font-weight: $fontWeight600;
                    margin-bottom: 0;

                    span {
                        color: $zambiaColor;
                        font-size: $fontSize34px !important;
                    }
                }

                p {
                    color: $whiteSmokeColor;
                    font-size: $fontSize18px;
                    font-weight: $fontWeight600;
                }
            }
        }
    }
}

.home-wrapper {
    .carousel {
        a {
            text-decoration: none;
        }
        span {
            font-size: $fontSize25px;
        }
    }
    .viewall {
        color: $blueStreakColor;
        font-weight: $fontWeight500;
        font-size: $fontSize15px;
        text-decoration: none;
        z-index: 999;
        cursor: pointer;
    }

    .product-list {
        .product-view {
            list-style: none;
            padding: 0;
            margin: 0;
            position: relative;
            .product-col {
                height: 348px;
            }
            a {
                text-decoration: none;
            }
        }

        .products-sec {
            margin: 7px 0;
            background: $whiteColor;
            .product-carouselItem {
                background-color: none !important;
                &.insulation {
                    .react-multi-carousel-list {
                        .react-multi-carousel-item {
                            .image-box {
                                background: $insigniaWhiteColor !important;
                            }
                        }
                    }
                }

                &.hvac {
                    .react-multi-carousel-list {
                        .react-multi-carousel-item {
                            .image-box {
                                background: $silverBirdColor !important;
                            }
                        }
                    }
                }

                &.outlets {
                    .react-multi-carousel-list {
                        .react-multi-carousel-item {
                            .image-box {
                                background: $heavySugarColor !important;
                            }
                        }
                    }
                }
                .carousel__row {
                    flex: 0 0 20%;
                }
            }
        }
        .carousel-section {
            padding: 25px 20px 5px;
        }
        .products-card {
            height: 739px;
        }

        .pdt-banner-right {
            .bacground-sec {
                background-image: url("https://jerseymepa1d95feba3e148e4937bf474442d8a6c221411-dev.s3.me-south-1.amazonaws.com/public/pumpimage.png");
                background-position: top !important;
                height: 300px !important;
            }
        }

        .pdt-banner-left {
            .bacground-sec {
                background-image: url("https://jerseymepa1d95feba3e148e4937bf474442d8a6c221411-dev.s3.me-south-1.amazonaws.com/public/image+(6).png");
                background-position: top !important;
                height: 300px !important;
            }
        }
    }
}

.bread-crumbs {
    border: 0;
    border-radius: 0;
    nav {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .sort-dropdown {
        .btn {
            background: transparent;
            border: none;
            letter-spacing: 0px;
            color: $paradiseGrapeColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
        }
        .dropdown-menu {
            transform: translate3d(-61px, 38.4px, 0px) !important;
        }
        .dropdown-item {
            &.active,
            &:active {
                background-color: $aliceBlueColor;
                color: black;
            }
        }
    }
    .sort-list {
        display: flex;
        align-items: center;
        white-space: nowrap;

        a {
            margin: 0 12px;
            color: $rusticLightRedColor;
            font-size: $fontSize15px;
            font-weight: $fontWeight500;
            text-decoration: none;
            cursor: pointer;

            &.selected {
                color: $retroBlueColor;
            }
        }
    }

    .breadcrumb-not-active {
        letter-spacing: 0px;
        color: $sugarTreeColor;
        text-transform: capitalize;
        opacity: 0.8;
        font-size: $fontSize12px;
        font-weight: $fontWeight500;
        text-decoration: none;
    }

    .breadcrumb-active {
        letter-spacing: 0px;
        color: $paradiseGrapeColor;
        opacity: 1;
        font-size: $fontSize12px;
        font-weight: $fontWeight500;
        text-decoration: none;
        white-space: normal;
    }

    .breadcrumb-active:hover {
        text-decoration: none !important;
        cursor: inherit;
    }

    .breadcrumb-arrow {
        margin-left: 10px;
        margin-right: 10px;
        color: $sugarTreeColor;
    }
    .clear-search {
        color: $retroBlueColor !important;
        font-size: $fontSize13px;
        font-weight: $fontWeight500;
        cursor: pointer;
        white-space: nowrap;
    }
}

.product-sublist {
    .product-listing-col {
        flex: 0 0 20%;
    }
    .filter-sec {
        .title {
            background-color: $whiteColor;
            padding: 5px 15px;

            h4 {
                font-size: $fontSize16px;
                font-weight: $fontWeight500;
                text-align: left;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
            button {
                background-color: $companyPrimaryColor;
                color: $companyPrimaryButtonTextColor;
                padding: 4px 12px;
                white-space: nowrap;
            }
        }

        .accordion {
            margin-top: 0.25rem;

            .accordion-item {
                border: none;
                border-radius: 0;
                background-color: $whiteColor;
                border-bottom: 1px solid rgba(200, 200, 200, 0.3411764706);
                .accordion-body {
                    padding-top: 0px;
                }
                .accordion-button {
                    border: 0;
                    border-radius: 0;
                    background-color: transparent;
                    box-shadow: none;
                    padding-left: 55px;
                    color: $rusticRedColor;
                    font-size: $fontSize15px;
                    font-weight: $fontWeight600;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 12px;
                    padding-bottom: 10px;

                    .clear-btn {
                        color: $tomatoBabyColor;
                        font-size: $fontSize12px;
                        font-weight: $fontWeight500;
                        text-decoration: none;
                        display: none;
                    }

                    &[aria-expanded="true"] {
                        .clear-btn {
                            display: block;
                        }
                    }

                    &::after {
                        content: "\f068";
                        font-family: FontAwesome;
                        left: 0;
                        position: absolute;
                        background: $whisperColor;
                        border-radius: 2px;
                        font-size: $fontSize10px;
                        font-weight: normal;
                        color: $blackColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 20px;
                        padding: 2px 5px;
                        transition: none;
                    }

                    &.collapsed {
                        &::after {
                            content: "\f067";
                        }
                    }
                }

                .accordion-body {
                    h4 {
                        color: $rusticRedColor;
                        font-size: $fontSize15px;
                        font-weight: $fontWeight600;
                        text-align: left;
                    }

                    .input-group {
                        flex-wrap: nowrap !important;

                        input {
                            background: $superSilverColor;
                            border: none;
                            border-radius: 0px 2px 2px 0px;
                            font-size: $fontSize14px;
                            font-weight: $fontWeight500;
                            width: 100%;
                            padding: 8px 10px;
                            color: $reseneNightOwlColor;
                            padding-left: 4px;

                            &:focus {
                                outline: none;
                                box-shadow: none;
                                border: none;
                            }
                        }

                        .input-group-text {
                            background: $superSilverColor;
                            border: none;
                            border-radius: 2px 0px 0px 2px;
                            border-left: 0 !important;
                            padding-right: 3px;

                            i {
                                color: $reseneNightOwlColor;
                                font-size: $fontSize14px;
                            }
                        }
                    }

                    ul {
                        padding-left: 15px;
                        margin-top: 15px;

                        li {
                            display: flex;
                            align-items: center;
                            margin: 8px 0;

                            input[type="checkbox"] {
                                accent-color: $companyPrimaryColor;
                                width: 30px;
                                height: 16px;
                            }

                            label {
                                color: $rusticRedColor;
                                font-size: $fontSize14px;
                                font-weight: $fontWeight500;
                                text-align: left;

                                i {
                                    &.fa-star {
                                        color: $zambiaColor;
                                        font-size: $fontSize14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sublist-filter {
        margin-right: -10px;
    }
    .filter-top-bar {
        background-color: $whiteColor;
        border: 0;
        border-radius: 0;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.25rem;

        h2 {
            color: $rusticLightRedColor;
            margin-bottom: 0;
            text-align: left;
            font-size: $fontSize16px;
            font-weight: $fontWeight500;
            text-transform: math-auto;

            span {
                color: $rocketMetallicColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
                margin: 0 4px;
            }
            .clear-search {
                color: $retroBlueColor !important;
                font-size: $fontSize13px;
                font-weight: $fontWeight500;
                cursor: pointer;
            }
        }

        .sort-list {
            display: flex;
            align-items: center;
            white-space: nowrap;

            a {
                margin: 0 12px;
                color: $rusticLightRedColor;
                font-size: $fontSize15px;
                font-weight: $fontWeight500;
                text-decoration: none;
                cursor: pointer;

                &.selected {
                    color: $retroBlueColor;
                }
            }
        }
    }
}
.pagination-card {
    margin-right: 1px !important;
    margin-left: -6px !important;
}
.pagination-list-card {
    border: 0;
    border-radius: 0;
    .card-body {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        h2 {
            color: $rusticLightRedColor;
            font-size: $fontSize16px;
            font-weight: $fontWeight500;
            margin-bottom: 0;
            text-align: left;
        }

        .pagination {
            margin-bottom: 0;
            flex-wrap: wrap;
            align-items: center;

            .page-item {
                .page-link {
                    border: 0;
                    border-radius: 0;
                    color: $rusticLightRedColor;
                    font-size: $fontSize16px;
                    font-weight: $fontWeight500;
                    padding: 3px 10px;
                    margin: 0 2px;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                        border: none;
                    }
                }

                &.active {
                    .page-link {
                        background: $companyPrimaryColor;
                        border-radius: 2px;
                        color: $companyPrimaryButtonTextColor;
                    }
                }

                &.arrow {
                    .page-link {
                        border: 1px solid $watermillWoodColor;
                        border-radius: 2px;
                        background-color: $whiteColor;

                        span {
                            color: $rusticLightRedColor;
                            font-size: $fontSize14px;
                            font-weight: $fontWeight500;
                        }
                    }
                }

                &.next,
                &.prev {
                    .page-link {
                        span {
                            visibility: visible;
                            position: relative !important;
                            color: $companyPrimaryColor;
                            font-size: $fontSize14px;
                            font-weight: $fontWeight500;

                            &:first-child {
                                display: none;
                            }
                        }
                    }
                }

                &.disabled {
                    .page-link {
                        span {
                            color: $watermillWoodColor;
                        }
                    }
                }
            }
        }
    }
}

.list-items {
    .carousel__item {
        background-color: $whiteColor;
        padding: 10px 15px;
        height: 346px;
        overflow: auto;

        img {
            width: 100%;
            height: 135px;
            object-fit: contain;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .image-box {
        height: 140px;
        margin: 0;
        overflow: hidden;
    }

    .carousel__caption {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .title {
            letter-spacing: 0px;
            color: $rusticRedColor;
            text-transform: uppercase;
            font-size: $fontSize16px;
            font-weight: $fontWeight600;
            margin-bottom: 0;
            overflow: hidden;
            height: 1.8em;
            text-align: center;

            span {
                font-weight: $fontWeight400 !important;
            }
        }

        p {
            margin-bottom: 4px;

            &.model {
                letter-spacing: 0px;
                text-align: left;
                font-size: $fontSize16px;
                font-weight: $fontWeight400;
                color: $rusticLightRedColor;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-word;

                span {
                    text-align: left;
                }
            }

            &.rating {
                letter-spacing: 0px;
                color: $purplishBrownColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;

                i {
                    color: $zambiaColor;
                    font-size: $fontSize14px;
                    margin-right: 5px;
                }
            }
        }

        .price {
            letter-spacing: 0px;
            color: $rusticRedColor;
            font-size: $fontSize18px;
            font-weight: $fontWeight600;
        }
    }
}

.product-detail {
    margin-top: 0.5rem;
    background-color: $whiteColor;
    padding: 20px;
    position: relative;

    .product-detail-left {
        position: sticky;
        top: 0;
        .product-detail-carousel {
            .image-gallery-slide-wrapper {
                border: 1px solid $watermillWoodColor;
            }

            .image-gallery-thumbnail {
                border: 1px solid $watermillWoodColor;

                &.active {
                    border: 1px solid $companyPrimaryColor;
                }
            }

            .image-gallery-thumbnail {
                width: 125px !important;
                height: 80px;
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }

                .image-gallery-thumbnail-image {
                    height: 78px;
                    object-fit: contain;
                }
            }

            .image-gallery-slide-wrapper {
                .image-gallery-icon {
                    background: $whiteColor;
                    border: 0;
                    border-radius: 35px;
                    cursor: pointer;
                    padding: 0;
                    height: 43px;
                    min-height: 43px;
                    min-width: 43px;
                    opacity: 1;
                    outline: 0;
                    position: absolute;
                    transition: all 0.5s;
                    z-index: 1;
                    filter: drop-shadow(0 1px 3px $darkGrayColor);

                    svg {
                        height: 35px !important;
                        width: 35px !important;
                        stroke: $blackColor;
                    }

                    &.image-gallery-left-nav {
                        left: -20px;
                    }

                    &.image-gallery-right-nav {
                        right: -20px;
                    }
                }
            }
        }
    }

    .product-detail-right {
        .pdt-name {
            font-size: $productDetailsTitleSize20px;
            font-weight: $productDetailsTitleWeight600;
            text-align: left;
            color: $rusticRedColor;
            line-height: 32px;
        }

        .pdt-model {
            color: $blackColor;
            font-size: $productDetailsSubTitleSize16px;
            font-weight: $productDetailsSubTitleWeight400;
            margin-bottom: 6px;
            span {
                font-weight: $fontWeight600;
                color: grey;
            }

            span {
                margin: 0 4px;
            }
        }

        .rating {
            font-size: $fontSize16px;
            font-weight: $fontWeight500;
            color: $retroBlueColor;
            text-align: left;

            i {
                color: $zambiaColor;
                font-size: $fontSize16px;
                margin-right: 4px;
            }

            span {
                font-weight: $fontWeight600;
                margin: 0 5px;
                color: $retroBlueColor;
            }
        }

        hr {
            border-color: $watermillWoodColor;
            border-width: 2px;
            margin-bottom: 2px;
            margin-top: 0px;
        }

        .pdt-price {
            display: flex;
            align-items: center;

            .price {
                color: $companyPrimaryColor;
                font-size: $productDetailsPriceSize22px;
                font-weight: $productDetailsPriceWeight600;
                margin-bottom: 6px;
                text-transform: uppercase;
                text-align: left;
                margin-right: 8px;

                span {
                    font-size: $fontSize26px;
                }
            }

            .offer-price {
                margin-bottom: 6px;
                text-align: left;
                text-decoration: line-through;
                font-size: $fontSize16px;
                font-weight: $fontWeight600;
                margin: 0 8px;
                color: $rusticRedColor;
            }

            .price-off {
                margin-bottom: 6px;
                text-align: left;
                font-size: $fontSize16px;
                font-weight: $fontWeight600;
                margin: 0 8px;
                color: $energyGreenColor;
            }
        }

        .pdt-response {
            p {
                margin-bottom: 4px;
                text-align: left;
                font-size: $fontSize16px;
                font-weight: $fontWeight500;
                color: $rusticRedColor;
                text-transform: capitalize;
            }

            .resp-btn {
                border: 1px solid $watermillWoodColor;
                border-radius: 4px;
                background-color: $whiteColor;
                text-align: center;
                font-size: $fontSize15px;
                font-weight: $fontWeight500;
                color: $rusticRedColor;
                padding: 10px;
                margin-right: 4px;
                text-transform: capitalize;
                margin-bottom: 0.2rem;

                &.selected {
                    border: 1px dashed $royalNavyBlueColor;
                    background-color: $aliceBlueColor;
                }
            }
        }

        .offers {
            p {
                margin-bottom: 6px;
                text-align: left;
                font-size: $fontSize16px;
                font-weight: $fontWeight500;
                color: $rusticRedColor;
            }

            label {
                background: $glimpseOfPinkColor 0% 0% no-repeat padding-box;
                border-radius: 4px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                text-align: left;
                color: $breathOfFireColor;
                font-size: $fontSize16px;
                font-weight: $fontWeight500;
                width: 200px;
                height: 64px;
                margin-bottom: 4px;
                margin-right: 4px;

                span {
                    color: $rusticRedColor;
                    font-size: $fontSize14px;
                }
            }
        }

        .about-pdt {
            .about-title {
                text-align: left;
                font-size: $productDetailsDescriptionHeadingSize18px;
                font-weight: $productDetailsDescriptionHeadingWeight600;
                color: $rusticRedColor;
                margin-bottom: 6px;
            }

            .pdt-description {
                text-align: left;
                font-size: $productDetailsDescriptionSize16px;
                font-weight: $productDetailsDescriptionWeight400;
                color: $rusticRedColor;
                margin-bottom: 6px;
                p {
                    margin-bottom: 0;
                }
                ul {
                    margin-bottom: 0;
                    li {
                        margin-bottom: 0;
                        a {
                            text-decoration: none;
                        }
                    }
                }
            }

            ul {
                padding-left: 20px;

                li {
                    list-style: inherit;
                    text-align: left;
                    font-size: $fontSize16px;
                    font-weight: $fontWeight400;
                    color: $rusticRedColor;

                    &::marker {
                        font-size: $fontSize22px;
                        color: $blackColor;
                    }
                }
            }
        }

        .pdt-features {
            .accordion {
                .accordion-item {
                    border-left: 0;
                    border-right: 0;
                    border-color: $watermillWoodColor;
                    border-radius: 0;

                    .accordion-header {
                        button {
                            border: 0;
                            text-align: left;
                            font-size: $fontSize18px;
                            font-weight: $fontWeight600;
                            color: $rusticRedColor;
                            padding: 12px;
                            padding-left: 0;
                            border-radius: 0;
                            background-color: $whiteColor !important;
                            box-shadow: none;

                            &::after {
                                transition: none;
                            }

                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }

                            &[aria-expanded="true"] {
                                border-bottom: 1px dashed $companyPrimaryColor;
                            }
                        }
                    }

                    .accordion-body {
                        padding-left: 0;
                        padding-right: 0;

                        p {
                            text-align: left;
                            font-size: $fontSize15px;
                            font-weight: $fontWeight500;
                            color: $rusticRedColor;
                            margin-bottom: 6px;
                        }

                        ul {
                            padding-left: 20px;

                            li {
                                list-style: inherit;
                                text-align: left;
                                font-size: $fontSize15px;
                                font-weight: $fontWeight400;
                                color: $rusticRedColor;
                                margin-bottom: 4px;

                                &::marker {
                                    font-size: $fontSize22px;
                                    color: $blackColor;
                                }
                            }
                        }

                        .data-table {
                            overflow-x: auto;
                            max-width: 100%;
                            table {
                                vertical-align: middle;
                                width: 100%;
                                table-layout: fixed;
                                width: 100%;
                                tr {
                                    border-radius: 4px;

                                    td {
                                        padding: 10px 20px;
                                        font-size: $fontSize14px;
                                        font-weight: $fontWeight600;
                                        text-align: left;
                                        border-top: 1px solid $whiteColor;
                                        border-bottom: 1px solid $whiteColor;

                                        .download {
                                            color: $rusticRedColor;
                                            font-size: $fontSize14px;
                                            text-decoration: none;
                                            font-weight: $fontWeight500;
                                            display: block;
                                            width: 250px;
                                            text-overflow: ellipsis;
                                            overflow: hidden;

                                            &:hover {
                                                color: $navyBlueColor;
                                                text-decoration: underline;
                                            }
                                        }

                                        p,
                                        a {
                                            color: $retroBlueColor;
                                            font-size: $fontSize14px;
                                            font-weight: $fontWeight400;
                                            cursor: pointer;
                                            text-decoration: none;
                                        }
                                    }

                                    &:nth-child(even) {
                                        td {
                                            background: $angelFeatherColor;
                                        }
                                    }

                                    &:nth-child(odd) {
                                        td {
                                            background: $glimpseOfPinkColor;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ship-to-sec {
        background: $whiteColor;
        border: 1px solid $watermillWoodColor;
        border-radius: 4px;
        padding: 10px;

        hr {
            border-color: $watermillWoodColor;
        }

        .ship-to {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                margin-bottom: 0 !important;
            }
        }
        .package-type {
            font-weight: $fontWeight400;
            font-size: $fontSize15px;
            margin-bottom: 9px;
            span {
                font-weight: $fontWeight600;
                font-size: $fontSize15px;
            }
        }
        p {
            margin-bottom: 14px;
            text-align: left;
            font-size: $fontSize12px;
            font-weight: $fontWeight500;
            color: $paradiseGrapeColor;
            span {
                color: $rusticRedColor;
            }
        }

        .location {
            align-items: center;

            p {
                color: $resonantBlueColor;
                font-weight: $fontWeight500 !important;
                margin-bottom: 0 !important;
                font-size: $fontSize14px;
            }

            img {
                margin-right: 5px;
                height: 15px;
            }
        }

        .free-delivery {
            h2 {
                margin-bottom: 6px;
                text-align: left;
                font-size: $fontSize16px;
                font-weight: $fontWeight500;
                color: $rusticRedColor;
            }

            p {
                margin-bottom: 6px;
                text-align: left;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                color: $brightCamouflageColor;
            }

            i {
                font-size: $fontSize15px;
                color: $brightCamouflageColor;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
        }

        .delivery-by {
            p {
                color: $purplishBrownColor;
                margin-bottom: 6px;
                text-align: left;
                font-size: $fontSize14px;
                font-weight: $fontWeight400;

                span {
                    color: $rusticRedColor;
                    font-weight: $fontWeight600;
                }
            }
        }

        .delivery-detail {
            p {
                color: $purplishBrownColor;
                margin-bottom: 6px;
                text-align: left;
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
            }

            a {
                color: $resonantBlueColor;
                text-decoration: none;
                cursor: pointer;
            }
        }

        .instock {
            margin: 8px 0;

            h2 {
                color: $energyGreenColor;
                margin-bottom: 6px;
                text-align: left;
                font-size: $fontSize13px;
                font-weight: $fontWeight500;
                span {
                    color: $resonantBlueColor;
                    font-size: $fontSize16px;
                }
            }

            p {
                color: $purplishBrownColor;
                margin-bottom: 6px;
                text-align: left;
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
            }
        }
        .text-danger {
            h2 {
                margin-bottom: 6px;
                text-align: left;
                font-size: $fontSize13px;
                font-weight: $fontWeight500;
                span {
                    color: $resonantBlueColor;
                    font-size: $fontSize16px;
                }
            }
        }
        .quantity {
            h2 {
                color: $rusticRedColor;
                margin-bottom: 5px;
                text-align: left;
                font-size: $fontSize16px;
                font-weight: $fontWeight500;
            }
            h4 {
                color: $rusticRedColor;
                margin-bottom: 5px;
                text-align: left;
                font-size: $fontSize13px;
                font-weight: $fontWeight500;
                color: $resonantBlueColor;
            }
        }
        .quantity-action {
            margin: 8px 0 18px;
            display: flex;
            gap: 5px;
            .count-num {
                width: 75px;
                background-color: $glimpseOfPinkColor;
                border: 0px;
            }
            a {
                background: $superSilverColor;
                border-radius: 4px;
                padding: 5px 12px;
                color: $rusticRedColor;
                text-decoration: none;
                font-size: $fontSize15px;
                font-weight: $fontWeight600;

                &.selected {
                    background: $glimpseOfPinkColor;
                }

                &:not(.selected) {
                    cursor: pointer;
                }
                i {
                    font-size: $fontSize14px;
                }
            }
        }

        .btn {
            border-radius: 4px;
            padding: 10px;
            width: 100%;
            height: 40px;
            margin-bottom: 10px;
            line-height: 1px;

            &.add-to {
                font-size: $primaryButtonSize14px;
                font-weight: $primaryButtonWeight500;
                color: $companyPrimaryButtonTextColor;
                background: $companyPrimaryColor;
            }

            &.buy-now {
                font-size: $secondaryButtonSize14px;
                font-weight: $secondaryButtonWeight500;
                color: $companySecondaryButtonTextColor;
                background: $companySecondaryColor;
            }

            &.outline-btn {
                width: 50% !important;
                color: $reseneNightOwlColor;
                border: 1px solid $purplishBrownColor;
                border-radius: 4px;
                white-space: nowrap;

                i {
                    margin-right: 3px;
                }
            }
        }

        .share-wishlit {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        .quote {
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: $fontSize13px;
                color: $reseneNightOwlColor;
                margin-right: 5px;
            }

            p {
                color: $reseneNightOwlColor;
                margin-bottom: 0px;
                text-align: left;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
            }
        }
    }
}

.customer-review {
    margin-top: 0.5rem;

    .ratings {
        background-color: $whiteColor;
        padding: 25px 20px;
        height: 362px;

        p {
            margin-bottom: 6px;
            text-align: left;
            font-size: $customerReviewsTitleSize15px;
            font-weight: $customerReviewsTitleWeight500;
            color: $rusticRedColor;
        }
        .date {
            color: $sugarTreeColor;
            font-size: $fontSize13px;
        }

        .rate-count {
            margin-bottom: 6px;
            text-align: left;
            font-size: $fontSize24px;
            font-weight: $fontWeight600;
            color: $rusticRedColor;
        }

        .rate-star {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;
        }

        i {
            color: $zambiaColor;
            font-size: $fontSize14px;
            margin-right: 5px;
        }

        .rate-progress {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 15px;
            margin-top: 15px;

            p {
                margin-bottom: 0;
            }

            .rate-left {
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .progress {
                width: 50%;
                background-color: $whippedCoconutCreamColor;
                border-radius: 4px;

                .progress-bar {
                    background-color: $citrusSplashColor;
                }
            }
        }

        .comment {
            font-size: $customerReviewsDetailsSize14px;
            font-weight: $customerReviewsDetailsWeight400;
        }

        select {
            background: $superSilverColor;
            border-radius: 2px;
            opacity: 1;
            letter-spacing: 0px;
            color: $rusticRedColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
            border: none;
            width: auto;
            appearance: auto;
            padding: px;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .reviewer {
            display: flex;
            align-items: flex-start;
            margin: 1.5rem 0;

            .user {
                background: $perfumeHazeColor;
                border-radius: 2px;
                width: 3.5rem;
                height: 3.5rem;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.8rem;

                i {
                    color: $nightshadeVioletColor;
                    font-size: $fontSize22px;
                    margin-right: 0 !important;
                }
            }

            .user-detail {
                h2 {
                    margin-bottom: 1px;
                    text-align: left;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight600;
                    color: $rusticRedColor;
                }
            }
        }
        .user-report {
            p {
                color: $purplishBrownColor;
                font-size: $fontSize13px;
                cursor: pointer;
            }
            .error-msg {
                color: $companyPrimaryColor;
                font-weight: $fontWeight500;
            }
            i {
                color: $whiteColor;
                background-color: $brightCamouflageColor;
                padding: 3px;
                border-radius: 50%;
                font-size: $fontSize10px;
                display: flex;
                align-items: center;
                height: 17px;
            }
            .success-msg {
                color: $energyGreenColor;
            }
            .reporting-comments {
                .title {
                    border-bottom: 1px solid $watermillWoodColor;
                }
                h6 {
                    font-size: $fontSize13px;
                    color: $rusticRedColor;
                    font-weight: $fontWeight600;
                }
                .form-check-input {
                    &:focus {
                        border-color: none;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
            .user-message {
                h4 {
                    font-size: $fontSize12px;
                }
                .form-control {
                    background: $whiteSmokeDarkColor;
                    border: 1px solid $earlyCrocusColor;
                    border-radius: 4px;
                    opacity: 1;
                    color: $rusticLightRedColor;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;
                    padding: 8px;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }

                    &::placeholder {
                        color: $reseneNightOwlColor;
                        font-weight: $fontWeight400;
                        font-size: $fontSize12px !important;
                    }
                }
            }
            .form-check {
                p {
                    color: $rusticRedColor;
                    margin-bottom: 0px;
                    font-size: $fontSize11px;
                    span {
                        color: $sugarTreeColor;
                    }
                }
            }
            .buttons {
                .shop-btn {
                    font-size: $fontSize14px;
                    font-weight: $fontWeight400;
                    color: $companyPrimaryButtonTextColor;
                    background-color: $companyPrimaryColor;
                    width: 145px !important;
                    border-radius: 4px;
                    margin-right: 5px;
                    margin-top: 2px;
                }
                .cancel-btn {
                    font-size: $fontSize14px;
                    font-weight: $fontWeight400;
                    color: $whiteColor;
                    background-color: $pinkSwanColor;
                    width: 145px !important;
                    border-radius: 4px;
                }
            }
        }
    }
    .no-reviews {
        h2 {
            font-size: $fontSize24px;
            font-weight: $fontWeight600;
            color: $rusticRedColor;
        }
    }
}

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    button {
        border: 1px solid $companyPrimaryColor !important;
        font-size: $fontSize14px;
        font-weight: $fontWeight600;
        color: $companyPrimaryButtonTextColor;
        background-color: $companyPrimaryColor;
        border-radius: 4px;
        padding: 6px 36px;
    }
    img {
        width: 240px;
    }
    p {
        font-size: $fontSize16px;
        font-weight: $fontWeight500;
        text-align: center;
        color: $rocketMetallicColor;
        margin-bottom: 2px;
        margin-top: 12px;
    }
    .n-found {
        div[aria-label="animation"] {
            overflow: auto;
        }

        img {
            height: 50px;
            margin-right: 10px;
        }

        h2 {
            font-size: $fontSize60px;
            font-weight: $fontWeight600;
            color: $schiavaBlueColor;
            text-align: left;
        }

        h4 {
            font-size: $fontSize40px;
            font-weight: $fontWeight600;
            color: $voidColor;
            text-align: center;
        }

        p {
            font-size: $fontSize16px;
            font-weight: $fontWeight500;
            text-align: center;
            color: $rocketMetallicColor;
            margin-bottom: 2px;
        }

        a {
            background-color: $schiavaBlueColor;
            color: $whiteColor;
            text-transform: uppercase;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
            text-align: center;
            padding: 8px 40px;
            border-radius: 18px;
            text-decoration: none;
        }
    }
}

#viewall {
    .card {
        border: 0;
        border-radius: 0;

        .card-body {
            padding: 30px;
        }
    }

    .viewall-notification {
        h2 {
            font-size: $fontSize24px;
            font-weight: $fontWeight600;
            color: $rusticRedColor;
            margin-bottom: 0;
        }

        .clearall {
            color: $companyPrimaryColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            margin-bottom: 0;
            text-decoration: none;
        }
        .no-results {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80vh;
        }
        .tabs {
            .nav-item {
                width: 100px;

                a {
                    padding: 10px !important;
                }

                .nav-link {
                    color: $sugarTreeColor;
                    text-align: left;
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    position: relative;

                    &.active {
                        color: $companyPrimaryColor;
                        border-bottom: 2px solid $companyPrimaryColor;
                    }
                }

                .badge {
                    background: $sefidWhiteColor;
                    border-radius: 8px;
                    width: 28px;
                    height: 24px;
                    color: $singingBluesColor !important;
                    text-align: center !important;
                    position: relative !important;
                    right: -5px !important;
                    top: 0px !important;
                }
            }
        }

        .notify-block {
            margin: 1rem 0;
            height: 80vh;
            overflow: auto;
        }

        .note-item {
            margin: 0;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
            border-radius: 4px;
            padding: 10px 10px 10px;

            &.active {
                border-left: 4px solid $companyPrimaryColor;
                background: $snowColor;
            }

            .note-icon {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 15px;

                img {
                    height: 30px !important;
                    object-fit: contain;
                }
            }

            .description {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .title {
                    letter-spacing: 0px;
                    color: $blueWhaleColor;
                    text-transform: capitalize;
                    opacity: 1;
                    font-size: $notificationsTitleSize15px;
                    font-weight: $notificationsTitleWeight500;
                    text-align: left;
                    margin-bottom: 4px;
                    white-space: initial;
                }

                .designation {
                    letter-spacing: 0px;
                    color: $mithrilColor;
                    text-transform: capitalize;
                    opacity: 1;
                    font-size: $fontSize12px;
                    font-weight: $fontWeight400;
                    text-align: left;
                    margin-bottom: 8px;
                    white-space: initial;
                }
            }

            .message {
                letter-spacing: 0px;
                color: $longLakeColor;
                text-transform: math-auto;
                opacity: 1;
                font-size: $notificationsSubTitleSize13px;
                font-weight: $notificationsSubTitleWeight400;
                margin-bottom: 0px;
                white-space: initial;
            }

            .date {
                letter-spacing: 0px;
                color: $paradiseGrapeColor;
                float: right;
                text-transform: uppercase;
                opacity: 1;
                font-size: $fontSize12px;
                font-weight: $fontWeight500;
                margin-bottom: 4px;
                white-space: initial;
                text-align: right;
                width: 25%;
                min-width: 25%;
            }

            .delete-icon {
                float: right;
                cursor: pointer;
                background: $cottagecoreSunsetColor;
                border-radius: 4px;
                padding: 1px 8px;
                margin-bottom: 0;

                i {
                    color: $blackColor;
                    font-size: $fontSize12px;
                }

                &:hover {
                    svg {
                        color: $companyPrimaryColor;
                    }
                }
            }
        }

        hr {
            border-bottom: 1px solid $whiteSmokeDarkColor;
            margin: 0.5rem 0;
        }

        .day-date {
            letter-spacing: 0px;
            color: $reseneNightOwlColor;
            float: right;
            text-transform: uppercase;
            opacity: 1;
            font-size: $fontSize14px;
            font-weight: $fontWeight600;
            margin-bottom: 4px;
            white-space: initial;
            text-align: left;
            display: contents;
            text-transform: capitalize;
        }
    }
}

.product-images {
    .react-multi-carousel-item {
        width: 146px !important;
        margin-left: 10px;
    }
    h3 {
        font-size: $fontSize20px;
        color: $rusticRedColor;
    }

    img {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        height: 93px;
        object-fit: contain;
    }

    .card {
        border: 2px solid $whisperColor;
        border-radius: unset;
        display: flex;
        justify-content: center;
        padding: 6px;
        text-align: center;
        cursor: pointer;
        padding: 5px 10px;

        &.active {
            border: 2px solid $royalNavyBlueColor;
            border: 2px solid $royalNavyBlueColor;
            color: $navyBlueColor;
            background-color: $aliceBlueColor;

            .card-footer {
                color: $navyBlueColor;
                background-color: $aliceBlueColor;
            }
        }
    }

    .card-footer {
        background-color: $doctorColor;
        font-size: $fontSize16px;
        color: $rusticRedColor;
        text-align: center;
        width: 100%;
    }
}

.zoom-container {
    .zoom-image {
        img {
            min-height: 400px !important;
            object-fit: contain;
            height: 200px;
        }
        div div img {
            width: 1200px !important;
            height: 1000px !important;
        }
    }
}

// spinner loader start

#no-freeze-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.1s linear;
    opacity: 0;
    height: 100vh;
    transform: scale(10);
    z-index: -1;
    background: rgba(0, 0, 0, 0.8);
}

.no-freeze-spinner {
    #no-freeze-spinner {
        z-index: 9999;
        opacity: 0.5;
        transform: scale(2);

        div {
            div {
                animation-play-state: running;
            }
        }

        img {
            animation: loadicons 3s 2s infinite ease-in-out;
        }
    }
}

#no-freeze-spinner {
    & > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
        box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.8);
        width: 60px;
        height: 60px;
        padding: 5px;

        & > div {
            animation-play-state: paused;
            border: 5px solid $companyPrimaryColor;
            border-radius: 50%;
            animation: rotate 1s infinite linear;
            border-left-color: rgba(200, 200, 200, 0.4);
            width: 100%;
            height: 100%;
        }
    }
}

@keyframes loadicons {
    0% {
        transform: scale(0) translate3d(0, 0, 0);
    }
    11% {
        transform: scale(1.2) translate3d(0, 0, 0);
    }
    22% {
        transform: scale(1) translate3d(0, 0, 0);
    }
    33% {
        transform: scale(0) translate3d(0, 0, 0);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.no-freeze-spinner {
    #no-freeze-spinner {
        img {
            font-size: $fontSize24px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            color: $companyPrimaryColor;
            width: 25px;
            height: 25px;
            object-fit: contain;
            line-height: 1;
            transform: scale(0) translate3d(0, 0, 0);
        }
    }
}
// spinner loader ends here
.product-list-filter {
    .bread-crumbs {
        .card {
            p {
                margin-bottom: 0px;
            }
            .selected-filters {
                overflow: auto;
                p {
                    margin-bottom: 0px;
                    color: $navyBlueColor;
                    font-weight: $fontWeight600;
                    white-space: nowrap;
                    font-size: $fontSize14px;
                }
                .btn {
                    background: transparent;
                    border: 1px solid $earlyCrocusColor;
                    border-radius: 20px;
                    padding-right: 5px;
                    background: $whiteSmokeDarkColor;
                    color: $pantoneColor;
                    white-space: nowrap;
                    font-size: $fontSize12px;
                    span {
                        background-color: $reseneNightOwlColor;
                        color: $whiteColor;
                        border-radius: 50%;
                        padding: 1px 3px;
                        margin-left: 3px;
                        font-size: $fontSize10px;
                        font-weight: $fontWeight500;
                    }
                }
            }
            .filter-dropdown {
                .btn {
                    background: transparent;
                    color: black;
                    border: none;
                }
                .dropdown-menu {
                    padding: 0px;
                    width: 300px;
                    border-radius: 1px;
                    border: 1px solid rgba(200, 200, 200, 0.3411);
                    right: 0;
                    left: auto;
                }
                .dropdown-item {
                    padding: 0px;
                    &.active,
                    &:active,
                    &:hover {
                        background-color: $whiteColor;
                        color: black;
                        outline: none;
                        border: none;
                    }
                }
                .dropdown-item {
                    .accordion {
                        background: $whiteColor;
                        .cancel-btn {
                            font-size: $fontSize14px;
                            font-weight: $fontWeight400;
                            color: $whiteColor;
                            background-color: $pinkSwanColor;
                            width: 50%;
                            border-radius: 4px;
                            padding: 6px 12px;
                            margin: 10px;
                        }
                        .shop-btn {
                            background-color: $companyPrimaryColor;
                            color: $companyPrimaryButtonTextColor;
                            padding: 6px 12px;
                            white-space: nowrap;
                            margin: 10px;
                            border-radius: 4px;
                            width: 50%;
                        }
                        .accordion-item {
                            border: none;
                            border-radius: 0;
                            background-color: $whiteColor;
                            border-bottom: 1px solid rgba(200, 200, 200, 0.3411764706);
                            .accordion-header {
                                .clear-btn {
                                    color: $tomatoBabyColor;
                                    font-size: $fontSize12px;
                                    font-weight: $fontWeight500;
                                    text-decoration: none;
                                }
                            }
                            .accordion-body {
                                padding-top: 0px;
                                overflow: auto;
                                max-height: 312px;
                            }
                            .accordion-button {
                                border: 0;
                                border-radius: 0;
                                background-color: transparent;
                                box-shadow: none;
                                padding-left: 55px;
                                color: $rusticRedColor;
                                font-size: $fontSize15px;
                                font-weight: $fontWeight600;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding-top: 12px;
                                padding-bottom: 10px;

                                .clear-btn {
                                    color: $tomatoBabyColor;
                                    font-size: $fontSize12px;
                                    font-weight: $fontWeight500;
                                    text-decoration: none;
                                    display: none;
                                }

                                &[aria-expanded="true"] {
                                    .clear-btn {
                                        display: block;
                                    }
                                }

                                &::after {
                                    content: "\f068";
                                    font-family: FontAwesome;
                                    left: 0;
                                    position: absolute;
                                    background: $whisperColor;
                                    border-radius: 2px;
                                    font-size: $fontSize10px;
                                    font-weight: normal;
                                    color: $blackColor;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-left: 20px;
                                    padding: 2px 5px;
                                    transition: none;
                                }

                                &.collapsed {
                                    &::after {
                                        content: "\f067";
                                    }
                                }
                            }

                            .accordion-body {
                                h4 {
                                    color: $rusticRedColor;
                                    font-size: $fontSize15px;
                                    font-weight: $fontWeight600;
                                    text-align: left;
                                }

                                .input-group {
                                    flex-wrap: nowrap !important;

                                    input {
                                        background: $superSilverColor;
                                        border: none;
                                        border-radius: 0px 2px 2px 0px;
                                        font-size: $fontSize14px;
                                        font-weight: $fontWeight500;
                                        width: 100%;
                                        padding: 8px 10px;
                                        color: $reseneNightOwlColor;
                                        padding-left: 4px;

                                        &:focus {
                                            outline: none;
                                            box-shadow: none;
                                            border: none;
                                        }
                                    }

                                    .input-group-text {
                                        background: $superSilverColor;
                                        border: none;
                                        border-radius: 2px 0px 0px 2px;
                                        border-left: 0 !important;
                                        padding-right: 3px;

                                        i {
                                            color: $reseneNightOwlColor;
                                            font-size: $fontSize14px;
                                        }
                                    }
                                }

                                ul {
                                    padding-left: 15px;
                                    margin-top: 15px;

                                    li {
                                        display: flex;
                                        align-items: center;
                                        margin: 8px 0;

                                        input[type="checkbox"] {
                                            accent-color: $companyPrimaryColor;
                                            width: 30px;
                                            height: 16px;
                                        }

                                        label {
                                            color: $rusticRedColor;
                                            font-size: $fontSize14px;
                                            font-weight: $fontWeight500;
                                            text-align: left;

                                            i {
                                                &.fa-star {
                                                    color: $zambiaColor;
                                                    font-size: $fontSize14px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 767.98px) and (max-width: 991.98px) {
    .products-sec .product-carouselItem .carousel__row {
        flex: 0 0 33.33%;
    }
}

@media (max-width: 767.98px) {
    .products-sec .product-carouselItem .carousel__row {
        flex: 0 0 50%;
    }
}

@media (max-width: 575.98px) {
    .products-sec .product-carouselItem .carousel__row {
        flex: 0 0 100%;
    }
}

.products-sec {
    .carousel__row {
        flex: 0 0 20%;
    }
    .carousel__item {
        background-color: $whiteColor;
        padding: 10px 15px;
        height: 100%;
        overflow: auto;

        .image-box {
            background: $coyColor;
            padding: 30px;
            height: 180px;
            margin: 0;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                background: $coyColor;
                width: 100%;
                height: 135px;
                object-fit: contain;
            }
        }
        .carousel__caption {
            margin: 15px 0;

            .title {
                letter-spacing: 0px;
                color: $rusticRedColor;
                text-transform: uppercase;
                font-size: $fontSize16px;
                font-weight: $fontWeight600;
                margin-bottom: 4px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-word;
                span {
                    font-weight: $fontWeight400 !important;
                    text-transform: capitalize;
                }
            }

            p {
                margin-bottom: 4px;

                &.model {
                    letter-spacing: 0px;
                    text-align: left;
                    font-size: $fontSize15px;
                    font-weight: $fontWeight600;
                    color: $rusticLightRedColor;
                    overflow: hidden;
                    span {
                        text-align: left;
                    }
                }

                &.rating {
                    letter-spacing: 0px;
                    color: $purplishBrownColor;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;

                    i {
                        color: $zambiaColor;
                        font-size: $fontSize14px;
                        margin-right: 5px;
                    }
                }
            }

            .price {
                letter-spacing: 0px;
                color: $rusticRedColor;
                font-size: $fontSize18px;
                font-weight: $fontWeight600;
            }
        }
    }
}
.skeleton-column {
    .prodt-name {
        margin-bottom: 11px !important;
    }
}
.rating {
    letter-spacing: 0px;
    color: $purplishBrownColor;
    font-size: $fontSize14px;
    font-weight: $fontWeight500;

    i {
        color: $zambiaColor;
        font-size: $fontSize14px;
        margin-right: 5px;
    }
}
.summary-details-icon {
    color: $companyPrimaryColor;
}
