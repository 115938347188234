@import "./assets/scss/variables.scss";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* scroll bar start */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $superSilverColor;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* scroll bar end */
.form-control {
  font-size: $fontSize14px;
}
.form-error {
  color: red !important;
  font-size: $fontSize12px !important;
  margin-bottom: 0;
  font-weight: $fontWeight400 !important;
}
::placeholder {
  font-size: $fontSize14px;
  color: $darkGrayColor;
}
// PhoneInput start
.PhoneInputCountry {
  border: 1px solid $earlyCrocusColor;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
}
// PhoneInput end
