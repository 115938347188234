@import "variables";

.react-multi-carousel-list {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;

    .react-multi-carousel-item {
        &:first-child {
            .carousel__item {
                margin-left: 0 !important;
            }
        }

        &:last-child {
            .carousel__item {
                margin-right: 0 !important;
            }
        }

        .carousel__item {
            margin: 0px 4px;
            cursor: pointer;

            img {
                width: 100%;
                height: 210px;
                object-fit: contain;
            }
        }

        .image-box {
            background: $coyColor;
            padding: 30px;
            height: 100%;
            margin: 0;
            overflow: hidden;
        }

        .carousel__caption {
            margin: 15px 0;
            a {
                text-decoration: none;
            }
            .title {
                letter-spacing: 0px;
                color: $rusticRedColor;
                text-transform: uppercase;
                font-size: $fontSize16px;
                font-weight: $fontWeight600;
                margin-bottom: 10px;
                overflow: hidden;

                span {
                    font-weight: $fontWeight400 !important;
                    text-transform: capitalize;
                }
            }

            p {
                margin-bottom: 4px;

                &.model {
                    letter-spacing: 0px;
                    text-align: left;
                    font-size: $fontSize15px;
                    font-weight: $fontWeight600;
                    color: $rusticLightRedColor;
                    height: 1.8em;
                    margin-bottom: 0;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    word-break: break-word;

                    span {
                        text-align: left;
                    }
                }

                &.rating {
                    letter-spacing: 0px;
                    color: $purplishBrownColor;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;

                    i {
                        color: $yellowColor;
                        font-size: $fontSize14px;
                        margin-right: 5px;
                    }
                }
            }

            .price {
                letter-spacing: 0px;
                color: $rusticRedColor;
                font-size: $fontSize18px;
                font-weight: $fontWeight600;
            }
        }
    }

    .react-multi-carousel-dot-list {
        top: -16px !important;
        bottom: unset;

        li {
            width: 100%;
        }

        .custom-dot {
            background: $rockCrystalColor;
            width: 100%;
            height: 2px;
            border-width: 0;

            &.custom-dot--active {
                background: $companyPrimaryColor;
                width: 260px;
            }
        }
    }
}
.carousel__item {
    cursor: pointer;

    .image-box {
        background: $perfectWhiteColor;
        padding: 30px;
        height: 180px;
        margin: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 158px;
            object-fit: contain;
        }
    }

    .carousel__caption {
        margin: 15px 0;
        a {
            text-decoration: none;
        }
        .title {
            letter-spacing: 0px;
            color: $rusticRedColor;
            text-transform: uppercase;
            font-size: $fontSize16px;
            font-weight: $fontWeight600;
            margin-bottom: 10px;
            overflow: hidden;

            span {
                font-weight: $fontWeight400 !important;
                text-transform: capitalize;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                max-width: 100%;
                height: 2.8rem;
            }
        }

        p {
            margin-bottom: 4px;
            font-weight: $fontWeight600;
            color: $rusticLightRedColor;
            font-size: $fontSize15px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-word;

            span {
                font-weight: $fontWeight400;
            }

            &.model {
                letter-spacing: 0px;
                font-size: $fontSize15px;
                font-weight: $fontWeight500;
                color: $rusticLightRedColor;
                overflow: hidden;
                height: 1.8em;
                span {
                    text-align: left;
                }
            }

            &.rating {
                letter-spacing: 0px;
                color: $purplishBrownColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;

                i {
                    color: $yellowColor;
                    font-size: $fontSize14px;
                    margin-right: 5px;
                }
            }
        }

        .price {
            letter-spacing: 0px;
            color: $rusticRedColor;
            font-size: $fontSize18px;
            font-weight: $fontWeight600;
        }
    }
}

.react-multi-carousel-item {
    &:last-child {
        .carousel__item {
            margin-right: 0 !important;
        }
    }

    .carousel__item {
        .image-box {
            height: 210px !important;

            img {
                height: 200px !important;
            }
        }
    }
}
