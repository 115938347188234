@import "variables";

.header-bar {
  width: 100%;

  .header-top-wrapper {
    background-color: $whiteSmokeDarkColor;

    .top-bar {
      display: flex;
      align-items: center;
      padding: 5px 0;
      justify-content: end;
      gap: 15px;

      p {
        letter-spacing: 0px;
        color: $purplishBrownColor;
        font-weight: $fontWeight400;
        font-size: $fontSize14px;
        margin-bottom: 0;
        max-width: 84%;
        min-width: 84%;
      }
      hr {
        border-right: 1px solid $sugarTreeColor;
        opacity: 1 !important;
        height: 20px;
        margin: 0;
      }
      .track-drops,
      .language-drops,
      .location-drops {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          height: 20px;
        }

        .track-order,
        .language,
        .location {
          display: flex;
          align-items: center;

          select {
            color: $purplishBrownColor;
            font-size: $fontSize12px;
            font-weight: $fontWeight500;
            border: none;
            appearance: auto;
            padding: 0 6px;
            background-color: transparent;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }

      .language {
        img {
          height: $fontSize14px !important;
        }
      }
    }
  }

  .logo-wrapper {
    img {
      display: block;
      height: auto;
      max-height: 50px;
    }
  }

  .header-outer-wrapper,
  .header-inner-wrapper {
    background-color: $ivoryColor;
    border: 1px solid $earlyCrocusColor;
    #menuToggle {
      a {
        cursor: pointer;
        .fa-bars {
          font-size: $fontSize18px;
          color: black;
        }
      }
    }
    .navbar-wrapper {
      width: 100%;
      height: 70px;

      .nav-bar {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;

        .parent {
          display: flex;
          padding: 0;
          margin: 0;
          align-items: end;
          justify-content: center;
          flex-wrap: wrap;

          li {
            margin: 0 10px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            align-items: center;
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 4px;
              img {
                height: 29px !important;
                margin-right: 8px;
                margin-top: 0px;
              }
            }

            &.location {
              flex-direction: row;
              align-items: flex-start;

              a {
                font-size: $fontSize14px;
                line-height: 20px;
                margin-top: 0px;
                span {
                  font-size: $fontSize13px !important;
                }
              }
            }
            &.account {
              a {
                line-height: 16px !important;
              }
            }
            &.wishlist,
            &.notification {
              position: relative;
              a {
                display: flex;
                flex-direction: column;
                align-items: center;
                span:not(.badge) {
                  font-weight: $fontWeight600 !important;
                  font-size: $fontSize12px;
                }
              }

              img {
                height: 20px !important;
                width: 20px;
                margin-bottom: 0.2rem;
              }
              .badge {
                position: absolute;
                right: 22px;
                top: -12px;
                background: $mantellaFrogColor;
                color: $rusticRedColor;
                font-size: $fontSize12px;
                font-weight: $fontWeight500;
                padding: 3px 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
              }
            }

            &.cart {
              position: relative;
              a {
                display: flex;
                flex-direction: column;
                align-items: center;
                span:not(.badge) {
                  font-weight: $fontWeight600 !important;
                  font-size: $fontSize12px;
                  width: 64px;
                  text-align: center;
                }
              }
              img {
                width: 16px;
                height: 16px !important;
              }
              .badge {
                position: absolute;
                right: 13px;
                top: -12px;
                background: $mantellaFrogColor;
                color: $rusticRedColor;
                font-size: $fontSize12px;
                font-weight: $fontWeight500;
                padding: 3px 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
              }
            }

            a {
              font-weight: $fontWeight500;
              text-decoration: none;
              font-weight: $fontWeight600;
              color: $rusticLightRedColor;
              font-size: $fontSize14px;
              font-weight: $fontWeight600;

              span {
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
                text-transform: capitalize;
              }
            }

            img {
              height: 24px;
            }

            .input-group {
              input {
                background: $whiteSmokeDarkColor;
                border: 1px solid $mantellaFrogColor;
                border-radius: 4px 0 0 4px;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                color: $reseneNightOwlColor;
                padding: 8px 10px;
                width: 189px;

                &:focus {
                  outline: none;
                  box-shadow: none;
                }

                &::placeholder {
                  color: $reseneNightOwlColor;
                  font-size: $fontSize12px;
                  font-weight: $fontWeight400;
                }
              }

              .input-group-text {
                background: $whiteSmokeDarkColor;
                border: 1px solid $mantellaFrogColor;
                border-radius: 0px 4px 4px 0;
                border-left: 0 !important;
                cursor: pointer;

                i {
                  color: $companyPrimaryColor;
                  font-size: $fontSize20px;
                }
              }
            }
          }
        }

        .search-dropdown {
          position: absolute;
          background: $whiteColor 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px rgba(87, 31, 37, 0.2235294118);
          opacity: 1;
          min-width: 422px;
          min-width: 422px;
          min-height: 200px;
          overflow: auto;
          z-index: 9;
          top: 40px;
          &.h-fixed {
            height: 344px;
          }
          &.h-auto {
            height: auto !important;
            min-height: auto !important;
          }

          a {
            text-decoration: none;
            padding: 10px 15px;
            display: block;
          }

          .product-drop {
            cursor: pointer;
            border-bottom: 1px solid $superSilverColor;
            img {
              height: 50px;
              width: 50px;
            }

            h2 {
              letter-spacing: 0px;
              color: $liquoriceColor;
              opacity: 1;
              font-size: $fontSize14px;
              font-weight: $fontWeight500;
              text-align: left;
              margin-bottom: 2px;
              line-height: 20px;
              text-transform: capitalize;
              margin-bottom: 6px !important;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              width: 334px;
            }

            .product-sub {
              display: flex;
              justify-content: space-between;
              display: flex;

              p {
                font-size: $fontSize12px;
                color: $reseneNightOwlColor;
                width: 50%;
                text-transform: capitalize;

                span {
                  letter-spacing: 0px;
                  color: $rusticLightRedColor;
                  opacity: 1;
                  font-size: $fontSize13px;
                  font-weight: $fontWeight500;
                  margin-bottom: 0;
                  white-space: nowrap;
                  margin-right: 8px;
                  margin-left: 6px;
                }
              }
            }

            &:hover {
              background-color: $superSilverColor;
            }
          }
        }
      }
    }
  }
  .header-bottom-wrapper {
    background-color: $ivoryColor;
  }
  .header-bottom-wrapper,
  .header-outer-wrapper {
    .hamberger-icon {
      cursor: pointer;
      padding-left: 0 !important;
      .fa-bars {
        font-size: $fontSize18px;
      }
    }
    .dropbtn {
      color: $whiteColor;
      padding: 10px;
      font-size: $fontSize16px;
      border: none;
      position: relative;
      display: inline-block;
      text-decoration: none;
      a {
        color: $armorWashColor;
        font-size: $navigationMenuSize14px;
        font-weight: $navigationMenuWeight600;
        text-decoration: none;
        text-transform: capitalize;
        display: flex;
        white-space: nowrap;
        align-items: baseline;
        i {
          color: $temptressColor;
          font-size: $fontSize13px;
          margin: 0 5px;
        }
      }
      i {
        color: $temptressColor;
      }
      .fa-map-marker {
        font-size: $fontSize28px;
      }
      .location-a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
      }
      .account-a {
        color: $armorWashColor;
        font-size: $fontSize14px;
        font-weight: $fontWeight600;
        text-decoration: none;
        text-transform: capitalize;
        width: auto;

        i {
          color: $rusticLightRedColor;
          font-size: $fontSize13px;
          margin: 0 5px;
        }
      }

      &:hover {
        .dropdown-content {
          display: block;
        }
      }
    }

    .dropdown {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      padding: 0;

      .dropdown-content {
        display: none;
        position: absolute;
        background: $whiteColor 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $liquoriceColor;
        opacity: 1;
        min-width: 545px;
        z-index: 9999;
        padding: 25px;
        top: 34px;

        hr {
          margin: 0.5rem 0;
          border-color: $companyPrimaryColor;
          border-width: 1.4px;
          opacity: 1;
        }

        h2 {
          color: $temptreColor;
          font-size: $navigationSubMenuSize16px;
          font-weight: $navigationSubMenuWeight600;
          text-decoration: none;
          margin-bottom: 0px;
        }

        ul {
          padding: 0;

          li {
            padding: 6px 0px 6px;
            a {
              color: $temptColor;
              font-size: $navigationSubMenuSize16px;
              font-weight: $navigationSubMenuWeight600;
              text-decoration: none;
              display: inline-block;
              border-left: 3px solid transparent;
              &:hover {
                color: $companyPrimaryColor;
                box-shadow: #444 0 0 0px !important;
                border-left: 3px solid $companyPrimaryColor !important;
                padding-left: 5px;
              }
            }
          }
        }
      }
      .nav-dropdown {
        min-height: 300px;
        height: 300px;
        overflow-y: scroll;
      }
      &.account {
        .dropdown-content {
          padding: 26px !important;
          ul {
            li {
              padding: 0;
              a {
                padding: 10px 0;
                border-left: 0;
                font-size: $fontSize14px !important;
                &:hover {
                  padding-left: 0 !important;
                  border-left: 0 !important;
                  box-shadow: none !important;
                }
              }
            }
          }
        }
      }
      &.notification {
        .dropbtn {
          cursor: default;
        }
        .dropdown-content {
          min-width: 400px !important;
          width: 400px !important;
          padding: 0 !important;
          top: 42px !important;
          right: -85px !important;
          z-index: 1001;
          &::before {
            right: 130px !important;
          }
          h2 {
            margin-bottom: 0;
          }
          .clearall {
            color: $rocketMetallicColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            margin-bottom: 0;
          }
          .fa-trash {
            font-size: $fontSize15px;
            cursor: pointer;
            color: $rocketMetallicColor;
            &:hover {
              color: red;
            }
          }
          .tabs {
            .nav-item {
              width: 100px;
              a {
                padding: 10px !important;
              }
              .nav-link {
                color: $sugarTreeColor;
                text-align: left;
                display: flex;
                flex-direction: row;
                align-items: baseline;
                position: relative;
                &.active {
                  color: $companyPrimaryColor;
                  border-bottom: 2px solid $companyPrimaryColor;
                }
              }

              .badge {
                background: $sefidWhiteColor;
                border-radius: 8px;
                width: 28px;
                height: 24px;
                color: $singingBluesColor !important;
                text-align: center !important;
                position: relative !important;
                right: -5px !important;
                top: 0px !important;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .notify-block {
            margin: 1rem 0;
            height: 80vh;
            overflow: auto;
          }
          .notification-block {
            margin: 1rem 0;
            height: 300px;
            overflow: auto;
          }
          .no-results {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;
            p {
              text-align: center !important;
            }
          }
          .note-item {
            margin: 0;
            margin-bottom: 0.5rem;
            background: $brillianceColor;
            border-radius: 4px;
            padding: 10px 10px 10px;
            &.active {
              border-left: 4px solid $companyPrimaryColor;
              background: $snowColor;
            }
            .note-icon {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              margin-right: 15px;
              img {
                height: 37px !important;
                object-fit: contain;
                width: 36px;
              }
            }
            .description-box {
              width: 270px;
            }
            .description {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              .title {
                letter-spacing: 0px;
                color: $blueWhaleColor;
                text-transform: capitalize;
                opacity: 1;
                font-size: $fontSize15px;
                font-weight: $fontWeight500;
                text-align: left;
                margin-bottom: 4px;
                white-space: initial;
              }
              .designation {
                letter-spacing: 0px;
                color: $mithrilColor;
                text-transform: capitalize;
                opacity: 1;
                font-size: $fontSize12px;
                font-weight: $fontWeight400;
                text-align: left;
                margin-bottom: 8px;
                white-space: initial;
              }
            }
            .message {
              letter-spacing: 0px;
              color: $longLakeColor;
              text-transform: math-auto;
              opacity: 1;
              font-size: $fontSize13px;
              font-weight: $fontWeight400;
              margin-bottom: 8px;
              white-space: initial;
            }
            .date {
              letter-spacing: 0px;
              color: $paradiseGrapeColor;
              float: right;
              text-transform: uppercase;
              opacity: 1;
              font-size: $fontSize12px;
              font-weight: $fontWeight500;
              margin-bottom: 4px;
              white-space: initial;
              min-width: 25%;
              width: 25%;
            }
            .delete-icon {
              float: right;
              cursor: pointer;
              svg {
                color: $iceBlueGreyColor;
                width: 0.8rem;
                height: 0.8rem;
              }
              &:hover {
                svg {
                  color: $breathOfFireColor;
                }
              }
            }
          }
          .viewall {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $companyPrimaryColor;
            padding: 10px;
            color: $companyPrimaryButtonTextColor !important;
            position: absolute;
            bottom: 0;
            width: 100%;
            border-radius: 4px;
            a {
              color: $companyPrimaryButtonTextColor;
              font-size: $fontSize16px;
              font-weight: $fontWeight500;
              text-decoration: none;
            }
          }
        }
      }
      &.location-icon {
        .dropbtn {
          cursor: default;
        }
        .dropdown-content {
          min-width: 400px !important;
          width: 470px !important;
          padding: 0 !important;
          top: 42px !important;
          right: -213px !important;
          z-index: 1000;
          &::before {
            right: 279px !important;
          }
          .location-dropdown {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 500px;
            min-height: 100px;
          }
          .location-drpicon {
            img {
              max-width: 50%;
              max-height: 50%;
            }
          }
          .input-group {
            flex-wrap: nowrap;
            width: 360px;
          }
          .location-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $companyPrimaryColor;
            padding: 10px;
            color: $companyPrimaryButtonTextColor;
            width: 100%;
            border-radius: 4px;
            a {
              color: $companyPrimaryButtonTextColor;
            }
          }
          .current-location {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $navyBlueColor;
            padding: 10px;
            color: $whiteColor;
            width: 100%;
          }
          .no-results {
            max-height: 500px;
            min-height: 100px;
          }
        }

        .location-address {
          margin: 0;
          margin-bottom: 0.5rem;
          background: $brillianceColor;
          border-radius: 4px;
          padding: 10px 10px 10px;
          &.active {
            background: $snowColor !important;
          }
        }

        .description {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .title {
            letter-spacing: 0px;
            color: $blueWhaleColor;
            text-transform: capitalize;
            opacity: 1;
            font-size: $fontSize15px;
            font-weight: $fontWeight600;
            text-align: left;
            margin-bottom: 0px;
            white-space: initial;
          }
          .designation {
            letter-spacing: 0px;
            color: $mithrilColor;
            text-transform: capitalize;
            opacity: 1;
            font-size: $fontSize12px;
            font-weight: $fontWeight400;
            text-align: left;
            margin-bottom: 8px;
            white-space: initial;
          }
        }
      }
      .viewall {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $companyPrimaryColor;
        padding: 10px;
        color: $companyPrimaryButtonTextColor !important;
        position: absolute;
        bottom: 0;
        width: 100%;
        a {
          color: $companyPrimaryButtonTextColor;
          font-size: $fontSize16px;
          font-weight: $fontWeight500;
        }
      }
    }
    .menu-div {
      &:last-child,
      &:nth-last-child(2),
      &:nth-last-child(3) {
        .dropdown-content {
          right: 0;
        }
      }
    }
  }
}
.header-outer-wrapper {
  .dropbtn {
    padding: 0 !important;
  }
  .dropdown .dropdown-content {
    min-width: 260px !important;
    right: -104px !important;
    box-shadow: 0px 3px 6px $carbonColor;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      right: 160px;
      width: 15px;
      height: 15px;
      background-color: $whiteColor;
      transform: translateY(-49%) rotate(45deg);
      z-index: 0;
      box-shadow: -1px -1px 0px $silverbackColor;
    }
    .shop-btn {
      &.btn {
        font-size: $fontSize14px;
        font-weight: $fontWeight400;
        color: $companyPrimaryButtonTextColor;
        background-color: $companyPrimaryColor;
        width: 100%;
        border-radius: 4px;
        &:hover {
          color: $companyPrimaryColor;
          background-color: $companyPrimaryButtonTextColor;
          border: 1px solid $companyPrimaryColor;
        }
      }
      a {
        text-decoration: none;
        color: inherit !important;
        font-weight: $fontWeight400 !important;
      }
    }
    p {
      letter-spacing: 0px;
      color: $rusticLightRedColor;
      font-size: $fontSize14px;
      font-weight: $fontWeight400;
      margin-bottom: 0px;
      text-align: left;
    }
    ul {
      margin-top: 15px;
      li {
        align-items: flex-start !important;
        margin: 0 !important;
        border-bottom: 1px solid $watermillWoodColor;
        &:first-child {
          border-top: 1px solid $watermillWoodColor;
        }
        &:hover {
          border-bottom-color: $companyPrimaryColor;
        }
        a {
          width: 100%;
        }
      }
    }
  }
}

.navbar-modal {
  .modal-dialog {
    position: fixed;
    margin: -1px;
    width: 400px;
    height: 100%;
    top: 0;
    bottom: 0;

    .modal-content {
      height: 100vh;
      border-radius: 0;
      background-color: white !important;
      overflow-y: auto;

      .modal-header {
        border-bottom: none;
        border-bottom: none;
        background-color: $companyPrimaryColor;
        color: $companyPrimaryButtonTextColor;
        border-radius: 0px;
        height: 50px;

        h2 {
          font-size: $fontSize21px;
          padding-left: 25px;
          padding-top: 10px;
          font-weight: $fontWeight600;
        }
        i {
          font-size: $fontSize18px;
          font-weight: $fontWeight400;
        }

        .modal-title {
          font-size: $fontSize18px !important;
          font-weight: $fontWeight600 !important;
        }

        .btn-close {
          font-size: $fontSize10px;
          color: white !important;
          border: none;
        }
      }

      .modal-body {
        padding-top: 15px;

        .sub-container-content {
          h2 {
            color: $rusticLightRedColor;
            font-size: $fontSize16px;
            font-weight: $fontWeight600;
            text-decoration: none;
            margin-bottom: 0px;
          }

          hr {
            margin: 0.5rem 0;
            border-color: $companyPrimaryColor;
            border-width: 1.4px;
            opacity: 1;
          }

          ul {
            padding: 0;

            li {
              padding: 6px 0px 6px;

              a {
                color: $rusticLightRedColor;
                font-size: $fontSize16px;
                font-weight: $fontWeight600;
                text-decoration: none;
                display: inline-block;
                border-left: 3px solid transparent;

                &:hover {
                  color: $companyPrimaryColor;
                  box-shadow: #444 0 0 0px !important;
                  border-left: 3px solid $companyPrimaryColor !important;
                  padding-left: 5px;
                }
              }
            }
          }

          .sidenav-content-header {
            display: flex;
            align-items: center;

            h2 {
              color: $rusticLightRedColor;
              font-size: $fontSize16px;
              font-weight: $fontWeight600;
              text-decoration: none;
              margin-bottom: 0px;
            }

            i {
              margin-right: 18px;
              font-size: $fontSize13px;
            }
          }
        }
      }
    }
  }
}
/* NavBar */
#main-container {
  a {
    color: $rusticLightRedColor;
    font-size: $fontSize14px;
    font-weight: $fontWeight600;
    text-decoration: none;
  }

  .sidenavRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    p {
      margin-bottom: 0px;
    }

    &:hover {
      background-color: $warpDriveColor;

      i {
        color: $blackColor !important;
      }
    }
    .fa-chevron-right {
      cursor: pointer;
    }
  }
}
//header
.header-outer-wrapper {
  .delivery-location {
    img {
      height: 24px;
    }
    a {
      font-weight: $fontWeight500;
      text-decoration: none;
      font-weight: $fontWeight600;
      color: $rusticLightRedColor;
      font-size: $fontSize14px;
      font-weight: $fontWeight600;

      span {
        font-size: $fontSize14px;
        font-weight: $fontWeight400;
        text-transform: capitalize;
      }
      .btn-link {
        font-size: $fontSize14px;
        font-weight: $fontWeight400;
        text-transform: capitalize;
        padding: 0;
        color: $armorWashColor;
        text-decoration: none;
      }
    }
  }
  .search-box {
    .input-group {
      flex-wrap: unset;
      justify-content: center;
      input {
        background: $whiteSmokeDarkColor;
        border: 1px solid $earlyCrocusColor;
        border-radius: 4px 0 0 4px;
        font-size: $fontSize14px;
        font-weight: $fontWeight500;
        color: $reseneNightOwlColor;
        padding: 8px 10px;
        width: 100%;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &::placeholder {
          color: $reseneNightOwlColor;
          font-size: $fontSize12px;
          font-weight: $fontWeight400;
        }
      }

      .input-group-text {
        background: $companyPrimaryColor;
        border: 1px solid $earlyCrocusColor;
        border-radius: 0px 4px 4px 0;
        border-left: 0 !important;
        cursor: pointer;
        i {
          color: $companyPrimaryButtonTextColor;
          font-size: $fontSize20px;
        }
      }
      .search-dropdown {
        position: absolute;
        background: $whiteColor 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px rgba(87, 31, 37, 0.2235294118);
        opacity: 1;
        min-width: 422px;
        min-width: 422px;
        min-height: 200px;
        overflow: auto;
        z-index: 9;
        top: 40px;
        &.h-fixed {
          height: 344px;
        }
        &.h-auto {
          height: auto !important;
          min-height: auto !important;
        }

        a {
          text-decoration: none;
          padding: 10px 15px;
          display: block;
          font-weight: $fontWeight500;
          font-weight: $fontWeight600;
          color: $rusticLightRedColor;
          font-size: $fontSize14px;
          font-weight: $fontWeight600;
          span {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            text-transform: capitalize;
          }
        }

        .product-drop {
          cursor: pointer;
          border-bottom: 1px solid $superSilverColor;
          .w-10 {
            width: 25%;
          }
          img {
            height: 50px;
            width: 50px;
            object-fit: contain;
          }

          h2 {
            letter-spacing: 0px;
            color: $liquoriceColor;
            opacity: 1;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
            text-align: left;
            margin-bottom: 2px;
            line-height: 20px;
            text-transform: capitalize;
            margin-bottom: 6px !important;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 334px;
          }

          .product-sub {
            display: flex;
            justify-content: space-between;
            display: flex;

            p {
              font-size: $fontSize12px;
              color: $reseneNightOwlColor;
              width: 50%;
              text-transform: capitalize;

              span {
                letter-spacing: 0px;
                color: $rusticLightRedColor;
                opacity: 1;
                font-size: $fontSize13px;
                font-weight: $fontWeight500;
                margin-bottom: 0;
                white-space: nowrap;
                margin-right: 8px;
                margin-left: 6px;
              }
            }
          }

          &:hover {
            background-color: $superSilverColor;
          }
        }
      }
    }
  }
  .cart-section {
    .cart {
      position: relative;
      i {
        color: $temptressColor;
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        span:not(.badge) {
          font-weight: $fontWeight600 !important;
          font-size: $fontSize12px;
          width: 64px;
          text-align: center;
          color: $armorWashColor;
          white-space: nowrap;
        }
      }
      img {
        width: 16px;
        height: 16px !important;
      }
      .badge {
        position: absolute;
        right: 9px;
        top: -12px;
        background: $mantellaFrogColor;
        color: $rusticRedColor;
        font-size: $fontSize12px;
        font-weight: $fontWeight500;
        display: inline-block;
        padding: 0.25em 0.4em;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
      }
    }
  }
  &.cart {
    position: relative;
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      span:not(.badge) {
        font-weight: $fontWeight600 !important;
        font-size: $fontSize12px;
        width: 64px;
        text-align: center;
      }
    }
    img {
      width: 16px;
      height: 16px !important;
    }
    .badge {
      position: absolute;
      right: 13px;
      top: -12px;
      background: $mantellaFrogColor;
      color: $rusticRedColor;
      font-size: $fontSize12px;
      font-weight: $fontWeight500;
      padding: 3px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
  .notification {
    &.wishlist,
    &.notification {
      position: relative;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        span:not(.badge) {
          font-weight: $fontWeight600 !important;
          font-size: $fontSize12px;
          color: $armorWashColor;
        }
      }

      img {
        height: 16px !important;
        width: 16px;
      }
      .badge {
        position: absolute;
        right: 15px;
        top: -12px;
        background: $mantellaFrogColor;
        color: $rusticRedColor;
        font-size: $fontSize12px;
        font-weight: $fontWeight500;
        display: inline-block;
        padding: 0.25em 0.4em;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
      }
    }
  }
  .account-section {
    a {
      line-height: 16px !important;
      margin-top: 0px;
      text-decoration: none;
      span {
        font-size: $fontSize13px !important;
        color: $armorWashColor;
        font-weight: $fontWeight400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100px;
        display: block;
      }
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
      margin-top: 0px;
      border: 1px solid $companyPrimaryColor;
      padding: 2px;
    }
    .fa-user-circle {
      font-size: $fontSize28px;
      margin-right: 8px;
    }
    &.account {
      a {
        line-height: 16px !important;
      }
    }
  }
}
@media only screen and (max-width: 991.98px) {
  #menuLogoColumn {
    order: 1;
  }
  #deliveryLocationColumn {
    order: 4;
  }
  #searchColumn {
    order: 3;
  }
  #notifyAccountColumn {
    order: 2;
  }
}
