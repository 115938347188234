@import "variables";

#account {
    .card {
        border: 0;
        border-radius: 0;

        .card-header {
            background-color: $whiteColor;
            padding: 20px;
            border-color: $whiteSmokeColor;
            border-width: 1.5px;
            h2 {
                font-size: $fontSize22px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
            a {
                text-decoration: none;
                color: inherit;
                padding: 0;
            }
        }
        .card-body {
            padding: 20px;
            background-color: $whiteColor;
            .account-left {
                border-right: 2px solid $whiteSmokeColor;
                height: 100%;
                .nav-item {
                    a {
                        color: $rusticLightRedColor;
                        font-size: $fontSize16px;
                        font-weight: $fontWeight600;
                        text-decoration: none;
                        padding: 8px 0px;
                        display: block;
                        &.active {
                            color: $companyPrimaryColor;
                        }

                        &:hover {
                            color: $companyPrimaryColor;
                        }
                        a {
                            padding: 0;
                        }
                    }
                }
            }
            .account-right {
                .profile-section {
                    border: 1px solid $ghostwaverColor;
                    input {
                        background: $whiteSmokeDarkColor;
                        border: 1px solid $earlyCrocusColor;
                        border-radius: 4px;
                        font-size: $fontSize14px;
                        font-weight: $fontWeight400;
                        width: auto;
                        padding: 8px 10px;
                        color: $reseneNightOwlColor;
                        padding-left: 4px;
                        height: 34px;
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }

                    .profile-btns {
                        flex-direction: column;
                        padding: 15px;
                        button {
                            border: 1px solid $lightPinkPolarColor;
                            width: 100%;
                            margin: 2px;
                            &:hover {
                                color: $companyPrimaryButtonTextColor;
                                background: $companyPrimaryColor;
                            }
                        }
                    }
                }
                .profile {
                    border-radius: 4px;
                    padding: 15px;
                    display: flex;
                    align-items: flex-start;
                    position: relative;
                    .profile-img {
                        height: 121px;
                        margin-right: 20px;
                    }
                    .camera-icon {
                        background-color: $ballerinaSilkColor;
                        border-radius: 50%;
                        width: 29px;
                        height: 29px;
                        border: 1px solid $companyPrimaryColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        bottom: 8px;
                        right: 22px;
                        cursor: pointer;
                        img {
                            height: 12px;
                            margin-bottom: 0px;
                        }
                    }
                    .user-detail {
                        margin-top: 22px;
                        span.material-symbols-outlined {
                            color: $blackColor;
                            margin-left: 10px;
                            margin-top: -2px;
                            font-size: $fontSize18px;
                            cursor: pointer;
                            display: none;
                            &:hover {
                                color: red;
                            }
                        }
                        &:hover {
                            .edit-icon {
                                display: inline-block;
                            }
                        }
                        h2 {
                            font-size: $profileHeadingSize16px;
                            font-weight: $profileHeadingWeight600;
                            text-decoration: none;
                            color: $rusticRedColor;
                            span {
                                font-weight: $profileHeadingWeight600;
                                color: $iceBlueGreyColor;
                            }
                        }
                        p {
                            font-size: $profileContentSize14px;
                            font-weight: $profileContentWeight500;
                            margin-bottom: 0;
                            text-decoration: none;
                            color: $rusticRedColor;
                            span {
                                font-weight: $profileContentWeight500;
                                color: $iceBlueGreyColor;
                            }
                        }
                        h2 {
                            margin-top: 0px;
                        }
                        .tick-button {
                            background-color: $companyPrimaryColor;
                            color: $companyPrimaryButtonTextColor;
                            border: none !important;
                            border-radius: 4px;
                            margin-right: 4px;
                            font-size: $fontSize20px;
                            padding: 2px 10px;
                        }
                        .close-button {
                            background-color: $pinkSwanColor;
                            color: $whiteColor;
                            border: none !important;
                            border-radius: 4px;
                            margin-right: 4px;
                            font-size: $fontSize20px;
                            padding: 2px 8px;
                        }
                        .save-btns {
                            text-align: center;
                        }
                        .user-name {
                            position: relative;
                            min-height: 70px;
                            .form-error {
                                position: absolute;
                            }
                        }
                    }
                }
                h2 {
                    font-size: $fontSize22px;
                    font-weight: $fontWeight600;
                    color: $rusticRedColor;
                    margin-bottom: 0;
                    margin-top: 10px;
                }
                .manage-list {
                    display: flex;
                    align-items: center;
                    margin: 15px 0;
                    gap: 1rem;
                    flex-wrap: wrap;
                    a {
                        text-decoration: none;
                    }
                    .list-box {
                        border: 1px solid $ghostwaverColor;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        margin-right: 1rem;
                        width: 220px;
                        height: 200px;
                        &:last-child {
                            margin-right: 0;
                        }
                        img {
                            height: 95px;
                            margin-bottom: 1rem;
                        }
                        h4 {
                            font-size: $fontSize16px;
                            font-weight: $fontWeight600;
                            text-decoration: none;
                            color: $rusticRedColor;
                        }
                        p {
                            color: $reseneNightOwlColor;
                            font-size: $fontSize14px;
                            font-weight: $fontWeight400;
                        }
                    }
                }
            }
            .edit-profile {
                background: $whiteColor;
                border: 1px solid $lightPinkPolarColor;
                border-radius: 4px;
                position: absolute;
                right: 10px;
                color: $companyPrimaryColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                padding: 2px 8px;
                display: flex;
                align-items: center;
                i {
                    color: $companyPrimaryColor;
                    margin-right: 5px;
                }
                a {
                    text-decoration: none;
                    color: inherit;
                }
            }
            .product-table {
                border-radius: 4px;
            }
            table {
                width: 100%;
                thead {
                    th {
                        background: $lynxWhiteColor;
                        padding: 10px 20px;
                        &:nth-of-type(5) {
                            text-align: end;
                        }
                        p,
                        h2,
                        a {
                            letter-spacing: 0px;
                            color: $purplishBrownColor;
                            font-size: $fontSize14px;
                            opacity: 1;
                            font-weight: $fontWeight500;
                            margin-bottom: 2px;
                        }
                        h2 {
                            color: $rusticRedColor;
                        }
                        a {
                            color: $navyBlueColor !important;
                            white-space: nowrap;
                        }
                    }
                }
                tbody {
                    tr {
                        th {
                            padding: 4px;
                            white-space: nowrap;
                        }
                        td {
                            padding: 4px;

                            img {
                                background-color: $whiteColor;
                                border: 1px solid $whisperColor;
                                width: 160px;
                                height: 160px;
                                object-fit: contain;
                            }
                            h6 {
                                font-size: $fontSize16px;
                                font-weight: $fontWeight600;
                                color: $rusticRedColor;
                                overflow: hidden;
                            }
                            .track-btn,
                            .review-btn,
                            .buy-btn,
                            .return-btn {
                                &.btn {
                                    font-size: $fontSize14px;
                                    font-weight: $fontWeight400;
                                    color: $companyPrimaryButtonTextColor;
                                    background-color: $companyPrimaryColor;
                                    width: 130px;
                                    border-radius: 2px;
                                    &:hover {
                                        color: $companyPrimaryColor;
                                        background-color: $companyPrimaryButtonTextColor;
                                        border: 1px solid $companyPrimaryColor;
                                    }
                                }
                            }
                            .review-btn {
                                box-shadow: 0px 3px 6px $pinkLavenderColor;
                                border: 1px solid $whisperColor;
                                border-radius: 2px;
                                background-color: $whiteColor !important;
                                color: $reseneNightOwlColor !important;
                                font-weight: $fontWeight500 !important;
                                width: 180px !important;
                            }
                            .return-btn,
                            .buy-btn {
                                background-color: $whiteColor !important;
                                border: 1px solid $whisperColor !important;
                                border-radius: 2px;
                                color: $reseneNightOwlColor !important;
                                font-weight: $fontWeight500 !important;
                            }
                            .buy-btn {
                                color: $navyBlueColor !important;
                            }
                            .product-list {
                                display: flex;
                                align-items: flex-start;
                            }
                            .pdt-detail {
                                width: 70%;
                                p {
                                    font-size: $fontSize14px;
                                    color: $grayColor;
                                    margin-bottom: 4px;
                                    font-weight: $fontWeight600;
                                    span {
                                        &[color="yellow"] {
                                            color: $yellowColor;
                                        }
                                        &[color="green"] {
                                            color: $energyGreenColor;
                                        }
                                    }
                                    a {
                                        color: $navyBlueColor;
                                        text-decoration: none;
                                    }
                                }
                            }
                            .pdt-model {
                                span {
                                    font-weight: $fontWeight600;
                                    color: grey;
                                }
                            }
                            .qty-price {
                                span {
                                    font-weight: $fontWeight600;
                                    color: grey;
                                }
                            }
                            .delivery-type {
                                color: $blackColor !important;
                                font-weight: $fontWeight400 !important;
                            }
                            .next-collapse {
                                background: none;
                                border: 0;
                                i {
                                    color: $reddishBlack;
                                }
                                a {
                                    text-decoration: none;
                                    color: inherit;
                                }
                            }
                        }
                        &:last-child {
                            td {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
            .save-address {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 60vh;
                h2,
                p {
                    color: $rusticLightRedColor;
                    font-size: $fontSize16px;
                    font-weight: $fontWeight500;
                    text-decoration: none;
                    text-align: center;
                    margin-bottom: 2px;
                }
                p {
                    font-size: $fontSize14px;
                    font-weight: $fontWeight400;
                }
                .address-btn {
                    color: $companyPrimaryButtonTextColor;
                    background-color: $companyPrimaryColor;
                    width: 140px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: $fontSize14px !important;
                    font-weight: $fontWeight400;
                    border-radius: 4px;
                    &:hover {
                        color: $companyPrimaryColor !important;
                        background-color: $companyPrimaryButtonTextColor !important;
                        border: 1px solid $companyPrimaryColor;
                        i {
                            color: $companyPrimaryButtonTextColor !important;
                        }
                    }
                    i {
                        font-size: $fontSize12px;
                        color: $companyPrimaryColor;
                        margin-right: 4px;
                    }
                }
                img {
                    height: 16%;
                }
            }
            .address-list {
                display: flex;
                gap: 0.5rem;
                flex-wrap: wrap;
                .address-tile {
                    display: flex;
                    align-items: baseline;
                    flex-direction: column;
                    width: 380px;
                    margin-bottom: 0.25rem;
                    .user-detail {
                        background: $brillianceColor;
                        border: 1px solid $fancyPinkColor;
                        border-radius: 4px 4px 0px 0px;
                        opacity: 1;
                        padding: 1.5rem;
                        width: 100%;
                        height: 260px;

                        h2,
                        p {
                            font-size: $fontSize16px;
                            color: $reseneNightOwlColor;
                            margin-bottom: 0.5rem;
                            font-weight: $fontWeight600;
                        }
                        p {
                            font-weight: $fontWeight500;
                            font-size: $fontSize14px;
                        }
                        h2 {
                            text-transform: capitalize;
                        }
                    }
                    .user-actions {
                        .fa-edit {
                            margin-right: 16px;
                            color: green;
                            font-size: $fontSize20px;
                        }
                        .fa-trash {
                            color: $fireAxeRedColor;
                            font-size: $fontSize20px;
                        }
                    }
                }
            }
        }
    }
}
#contact-us {
    .card {
        border: 0;
        border-radius: 0;
        height: 100%;
        .card-body {
            padding: 30px;
        }
    }
    .top-title {
        font-size: $fontSize24px;
        font-weight: $fontWeight600;
        color: $rusticRedColor;
        margin-bottom: 0;
    }
    p {
        font-size: $fontSize14px;
        font-weight: $fontWeight400;
        color: $rusticRedColor;
        margin-bottom: 0;
    }
    form {
        label {
            letter-spacing: 0px;
            color: $doveGrey;
            opacity: 1;
            font-size: $fontSize12px;
            font-weight: $fontWeight500;
        }
        .form-check-input {
            &:focus {
                outline: none;
                box-shadow: none;
            }
            accent-color: $companyPrimaryColor;
        }
        .form-group {
            margin-bottom: 1rem;
        }
        .shop-btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            color: $companyPrimaryButtonTextColor;
            background-color: $companyPrimaryColor;
            border-radius: 4px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            &:hover {
                color: $companyPrimaryColor;
                background-color: $companyPrimaryButtonTextColor;
                border: 1px solid $companyPrimaryColor;
            }
        }
        .form-control {
            background: $whiteSmokeDarkColor;
            border: 1px solid $earlyCrocusColor;
            border-radius: 4px;
            opacity: 1;
            color: $rusticLightRedColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
            padding: 8px;
            &:focus {
                outline: none;
                box-shadow: none;
            }

            &::placeholder {
                color: $reseneNightOwlColor;
                font-weight: $fontWeight400;
                font-size: $fontSize12px !important;
            }
        }
        .PhoneInput {
            .PhoneInputInput {
                background: $whiteSmokeDarkColor;
                border: 1px solid $earlyCrocusColor;
                border-radius: 4px;
                opacity: 1;
                color: $rusticLightRedColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                padding: 8px;
                &:focus {
                    outline: none;
                }
            }
            .PhoneInputCountryIcon {
                box-shadow: none;
            }
        }
        .button {
            background-color: $lynxWhiteColor;
            color: $rusticRedColor;
        }
    }
    .file-section {
        .image-input {
            background: $whiteSmokeDarkColor;
            border: 1px dashed $earlyCrocusColor;
            border-radius: 4px;
            text-align: center;
            width: 120px;
            height: 100px;
            input {
                display: none;
            }
            label {
                align-items: center;
                color: $buttonBlueColor;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                font-size: $fontSize13px;
                font-weight: $fontWeight400;
                height: 100px;
                justify-content: center;
                i {
                    color: $sugarTreeColor;
                    font-weight: $fontWeight400;
                }
                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    i {
                        font-size: $fontSize22px;
                    }
                }
            }
        }
    }
    .contact-details-col {
        .card {
            height: 100%;
            .card-header {
                background-color: transparent;
                padding: 16px;
                h2 {
                    font-size: $fontSize18px;
                    font-weight: $fontWeight600;
                    color: $rusticRedColor;
                    margin-bottom: 0;
                }
            }
            .card-body {
                img {
                    width: 15px;
                }
                p {
                    font-size: $fontSize14px;
                    font-weight: $fontWeight600;
                    color: $rusticRedColor;
                    margin-bottom: 0;
                }
                .contact-image {
                    background-image: url(../images/account/contactus.svg);
                    height: 210px;
                    background-size: contain;
                    background-position: bottom;
                    background-repeat: no-repeat;
                    border-radius: 8px;
                    width: 80%;
                    margin: 0 auto;
                }
                a {
                    text-decoration: none;
                    color: $rusticRedColor;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight600;
                }
            }
        }
    }
}

#order-history,
#return-product {
    .card {
        border: 1px solid $whisperColor;
        border-radius: 0;
        .card-header {
            background-color: $whiteColor;
        }
    }
    .form-control {
        &:focus {
            border-color: none;
            outline: none;
            box-shadow: none;
        }
    }
    h2,
    h4 {
        font-size: $fontSize14px;
        font-weight: $fontWeight600;
        color: $rusticRedColor;
        margin-bottom: 0;
        a {
            text-decoration: none;
            color: $navyBlueColor;
        }
    }
    p {
        letter-spacing: 0px;
        color: $rusticRedColor;
        opacity: 1;
        font-size: $fontSize14px;
        font-weight: $fontWeight400;
        margin-bottom: 2px;
        span {
            font-weight: $fontWeight600;
            color: grey;
        }
    }
    .payment-card {
        img {
            height: 22px;
        }
        .btn {
            font-size: $fontSize14px;
            background-color: $whiteColor !important;
            border: 1px solid $whisperColor !important;
            border-radius: 2px;
            color: $reseneNightOwlColor !important;
            font-weight: $fontWeight500 !important;
            width: 150px;
            border-radius: 2px;
            &.buy-btn {
                color: $navyBlueColor !important;
            }
            &:hover {
                color: $companyPrimaryColor;
                background-color: $whiteColor;
                border: 1px solid $companyPrimaryColor;
            }
        }
    }
    .user-address {
        h4 {
            font-size: $fontSize15px;
        }
        p {
            span {
                font-weight: $fontWeight500;
            }
        }
    }
    table {
        tbody {
            tr {
                th,
                td {
                    font-size: $fontSize14px;
                    vertical-align: middle;
                }
                th {
                    font-weight: $fontWeight500;
                    color: $rusticRedColor;
                }
                td {
                    color: $rusticRedColor;
                    font-weight: $fontWeight500;
                }
                .total {
                    font-weight: $fontWeight600;
                }
            }
            tr:nth-of-type(2) {
                td {
                    color: $rusticRedColor;
                    font-weight: $fontWeight500;
                }
            }
            tr:nth-of-type(3) {
                td {
                    color: $paradiseGrapeColor;
                }
            }
            tr:nth-of-type(4) {
                td {
                    color: $rusticRedColor;
                    font-weight: $fontWeight600;
                }
            }
        }
        tfoot {
            border-top: 1px solid $superSilverColor;
            tr {
                th:nth-of-type(2) {
                    font-size: $fontSize18px;
                }
            }
        }
    }
    .product-table {
        table {
            tbody {
                tr {
                    td {
                        padding: 10px 20px;
                        border-bottom: 1px solid $rockCrystalColor;
                        img {
                            background-color: $whiteColor;
                            border: 1px solid $whisperColor;
                            width: 160px;
                            height: 160px;
                            object-fit: contain;
                        }
                        h6 {
                            font-size: $fontSize16px;
                            font-weight: $fontWeight600;
                            color: $rusticRedColor;
                            overflow: hidden;
                        }
                        .track-btn,
                        .review-btn,
                        .buy-btn,
                        .return-btn {
                            &.btn {
                                font-size: $fontSize14px;
                                font-weight: $fontWeight400;
                                color: $companyPrimaryButtonTextColor;
                                background-color: $companyPrimaryColor;
                                width: 172px;
                                border-radius: 2px;
                                &:hover {
                                    color: $companyPrimaryColor;
                                    background-color: $companyPrimaryButtonTextColor;
                                    border: 1px solid $companyPrimaryColor;
                                }
                            }
                        }
                        .review-btn {
                            box-shadow: 0px 3px 6px $pinkLavenderColor;
                            border: 1px solid $whisperColor;
                            border-radius: 2px;
                            background-color: $whiteColor !important;
                            color: $reseneNightOwlColor !important;
                            font-weight: $fontWeight500 !important;
                            width: 180px !important;
                        }
                        .return-btn,
                        .buy-btn {
                            background-color: $whiteColor !important;
                            border: 1px solid $whisperColor !important;
                            border-radius: 2px;
                            color: $reseneNightOwlColor !important;
                            font-weight: $fontWeight500 !important;
                        }
                        .buy-btn {
                            color: $navyBlueColor !important;
                        }
                        .return-btn {
                            &:hover {
                                box-shadow: 0px 3px 6px $pinkLavenderColor;
                            }
                        }
                        .product-list {
                            display: flex;
                            align-items: flex-start;
                        }
                        .pdt-detail {
                            p {
                                font-size: $fontSize14px;
                                color: $reseneNightOwlColor;
                                margin-bottom: 0.5rem;
                                white-space: nowrap;
                                span {
                                    &[color="yellow"] {
                                        color: $yellowColor;
                                    }
                                    &[color="green"] {
                                        color: $energyGreenColor;
                                    }
                                }
                                a {
                                    color: $navyBlueColor;
                                    text-decoration: none;
                                }
                            }
                        }
                        .next-collapse {
                            background: none;
                            border: 0;
                            i {
                                color: $reddishBlack;
                            }
                            a {
                                text-decoration: none;
                                color: inherit;
                            }
                        }
                        .show-label {
                            padding: 8px;
                            font-size: $fontSize12px;
                            font-weight: $fontWeight500;
                            text-align: center;
                            text-transform: capitalize;
                            white-space: nowrap;
                            border-radius: 2px;
                            &.success {
                                background-color: $mintCreamColor;
                                color: $energyGreenColor;
                                margin-top: 4px;
                            }
                            &.error {
                                background-color: $tulipColor;
                                color: $companyPrimaryColor;
                            }
                        }
                    }
                    &:last-child {
                        td {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    form {
        width: 60%;
        .edit-profile {
            position: relative;
        }
        label {
            letter-spacing: 0px;
            color: $doveGrey;
            opacity: 1;
            font-size: $fontSize12px;
            font-weight: $fontWeight400;
        }
        .form-check-input {
            &:focus {
                outline: none;
                box-shadow: none;
            }
            accent-color: $companyPrimaryColor;
        }
        .form-group {
            margin-bottom: 1rem;
            &:focus {
                border-color: none;
                outline: none;
                box-shadow: none;
            }
            .shop-btn {
                position: absolute;
                right: 4px;
                width: 85px;
                top: 27px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .form-check {
                label {
                    letter-spacing: 0px;
                    color: $rusticRedColor;
                    opacity: 1;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;
                    margin-bottom: 2px;
                }
                p {
                    font-size: $fontSize12px !important;
                    font-weight: $fontWeight500;
                    a {
                        color: $navyBlueColor;
                        text-decoration: none;
                    }
                }
                .form-check-input {
                    font-size: $fontSize15px;
                }
                &:focus {
                    border-color: none;
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        .form-control {
            background: $whiteSmokeDarkColor;
            border: 1px solid $earlyCrocusColor;
            border-radius: 4px;
            opacity: 1;
            color: $rusticLightRedColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
            padding: 8px;
            &:focus {
                outline: none;
                box-shadow: none;
            }

            &::placeholder {
                color: $reseneNightOwlColor;
                font-weight: $fontWeight400;
                font-size: $fontSize12px !important;
            }
        }
        select {
            appearance: auto;
        }
        .sub-title {
            font-size: $fontSize15px;
            font-weight: $fontWeight600;
            color: $rusticRedColor;
            margin-bottom: 0;
        }
    }

    hr {
        border-color: $whiteSmokeColor;
        opacity: 1;
        border-width: 1.4px;
    }
    .pickup-card {
        label {
            letter-spacing: 0px;
            color: $doveGrey;
            opacity: 1;
            font-size: $fontSize12px;
            font-weight: $fontWeight400;
        }
        .form-control {
            border: 1px solid $ghostwaverColor;
            border-radius: 2px;
            opacity: 1;
            color: $rusticLightRedColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
            padding: 6px;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .bottom-buttons {
        .btn {
            &.confirm-btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
                color: $companyPrimaryButtonTextColor;
                background-color: $companyPrimaryColor;
                width: 170px;
                border-radius: 2px;
                a {
                    text-decoration: none;
                    color: inherit;
                }
                &:hover {
                    color: $yellowColor;
                    background-color: $companyPrimaryButtonTextColor;
                    border: 1px solid $yellowColor;
                }
            }
            &.cancel-btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
                color: $whiteColor;
                background-color: $pinkSwanColor;
                width: 170px !important;
                border-radius: 2px;
                &:hover {
                    background-color: $spanishGrayColor;
                }
            }
        }
    }
    .file-section {
        .image-input {
            background: $whiteSmokeDarkColor;
            border: 1px dashed $earlyCrocusColor;
            border-radius: 4px;
            text-align: center;
            width: 56px;
            height: 56px;
            input {
                display: none;
            }
            label {
                margin-right: 0px !important;
                align-items: center;
                color: $rocketMetallicColor;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                font-size: $fontSize13px;
                font-weight: $fontWeight400;
                height: 56px;
                justify-content: center;
                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    i {
                        font-size: $fontSize22px;
                    }
                }
            }
        }
    }
}

.update-modal {
    .modal-dialog {
        width: 600px;
        min-width: 600px;
        &.modal-sm {
            width: 480px;
            min-width: 480px;
            .modal-body {
                padding-left: 3.5rem;
                padding-right: 2rem;
            }
        }
    }
    .modal-header {
        border-bottom: 0;
        .btn-close {
            visibility: hidden;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
        h4,
        .h4,
        .modal-title {
            font-size: $fontSize20px !important;
            p {
                font-size: $fontSize14px;
                color: $rusticRedColor;
                margin-bottom: 0.5rem;
                font-weight: $fontWeight500;
                margin-bottom: 0px;
            }
        }
        .info-icon {
            border-radius: 8px;
            background-color: $tealishColor;
            padding: 0px 12px;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            i {
                color: $whiteColor;
                font-size: $fontSize15px;
            }
        }
    }
    .modal-content {
        border-radius: 0;
        .modal-body {
            .order-updates {
                .css-1dfutkj-control {
                    border-radius: inherit;
                }
                p {
                    font-size: $fontSize14px;
                    color: $rusticRedColor;
                    margin-bottom: 0.5rem;
                    font-weight: $fontWeight500;
                    span {
                        color: $purplishBrownColor;
                        font-weight: $fontWeight400;
                    }
                }
            }
            .order-status {
                margin: 1rem 0;
                .stepper {
                    padding-left: 0;
                    padding-bottom: 0.5rem;
                    .stepContainer {
                        order: 2;
                    }
                    .middleConnectorWrapper {
                        display: none;
                    }
                    div:has(.verticalStepperWrapper) {
                        display: flex;
                    }
                    .descriptionContainer {
                        align-items: baseline;
                        .description {
                            padding: 3px !important;
                            font-size: $fontSize14px;
                            color: $rusticRedColor;
                            margin-bottom: 0;
                            font-weight: $fontWeight500;
                        }
                    }
                    .verticalStepperWrapper {
                        .verticalContentWrapper {
                            padding-left: 1rem;
                        }
                        .label {
                            p {
                                width: 150px;
                                font-size: $fontSize14px;
                                color: $rusticRedColor;
                                margin-bottom: 0.5rem;
                                font-weight: $fontWeight500;
                                white-space: nowrap;
                                display: flex;
                                flex-direction: column;
                                align-items: baseline;
                                span {
                                    font-size: $fontSize14px;
                                    font-weight: $fontWeight400;
                                }
                            }
                        }
                    }
                    .node {
                        padding: 0;
                        margin-top: -47px;
                        #stepper-node {
                            margin: 0;
                            background: $energyGreenColor;
                            font-size: 0;
                            width: 10px;
                            height: 10px;
                            img {
                                display: none;
                            }
                        }
                    }
                    .stepConnector {
                        width: 6px;
                        background-color: $lineDriedSheetsColor;
                        &.activeConnector {
                            background-color: $homeopathicLimeColor;
                        }
                        &.hiddenConnector {
                            visibility: visible !important;
                        }
                    }
                }
            }
            .bottom-buttons {
                .btn {
                    &.shop-btn {
                        font-size: $fontSize14px;
                        font-weight: $fontWeight400;
                        color: $companyPrimaryButtonTextColor;
                        background-color: $companyPrimaryColor;
                        width: 150px;
                        border-radius: 2px;
                        a {
                            text-decoration: none;
                            color: inherit;
                        }
                        &:hover {
                            color: $companyPrimaryColor;
                            background-color: $companyPrimaryButtonTextColor;
                            border: 1px solid $companyPrimaryColor;
                        }
                    }
                    &.cancel-btn {
                        font-size: $fontSize14px;
                        font-weight: $fontWeight400;
                        color: $whiteColor;
                        background-color: $pinkSwanColor;
                        width: 150px;
                        border-radius: 2px;
                        &:hover {
                            background-color: $spanishGrayColor;
                        }
                    }
                }
            }
            .form-control {
                background: $whiteSmokeDarkColor;
                border: 1px solid $earlyCrocusColor;
                border-radius: 4px;
                opacity: 1;
                color: $rusticLightRedColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                padding: 8px;
                appearance: auto;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                &::placeholder {
                    font-weight: $fontWeight400;
                }
            }
            .sub-title {
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                color: $rusticRedColor;
                margin-bottom: 0.5rem;
            }
            p {
                color: $purplishBrownColor;
                margin-bottom: 0px;
            }
            img {
                height: 50px;
            }
        }
    }
}

.profile-form {
    .nav {
        border: none;
    }
    .nav-link {
        background-color: $lynxWhiteColor !important;
        color: $rusticRedColor !important;
        border: none;
        font-size: $fontSize14px !important;
        font-weight: $fontWeight500 !important;
        width: 94% !important;
        border-radius: none !important;

        &:hover {
            background-color: $coyColor !important;
            color: $companyPrimaryColor !important;
        }
        &.active {
            background-color: $coyColor !important;
            color: $companyPrimaryColor !important;
        }
    }
}

.filter-form {
    .modal-dialog {
        position: fixed;
        margin: -1px;
        width: 400px;
        height: 100%;
        right: 0;
        top: 0;
        bottom: 0;
        .modal-content {
            height: 100vh;
            border-radius: 0;
            .modal-header {
                .modal-title {
                    font-size: $fontSize18px !important;
                    font-weight: $fontWeight600 !important;
                }
                .btn-close {
                    font-size: $fontSize10px;
                    color: $paradiseGrapeColor !important;
                    border: none;
                    background-color: $whiteColor;
                }
            }
            .modal-footer {
                border-top: none;
                justify-content: center;
                .clear-all-btn {
                    background-color: $pinkSwanColor;
                    border: none;
                    border-radius: 4px;
                    width: 142px;
                    color: $whiteColor;
                }
                .apply-btn {
                    background-color: $companyPrimaryColor;
                    border: none;
                    border-radius: 4px;
                    width: 142px;
                    color: $companyPrimaryButtonTextColor;
                }
            }
            .form-label {
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
            }
            .form-control[type="date"] {
                text-transform: uppercase !important;
            }
        }
    }
}

.image-container {
    position: relative;
    display: inline-block;
    margin-right: 15px;

    .image {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .close-button {
        position: absolute;
        top: -9px;
        right: -8px;
        background: rgba(0, 0, 0, 0.5);
        color: $whiteColor;
        border: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: $fontSize16px;
    }

    .close-button:hover {
        background: rgba(0, 0, 0, 0.7);
    }
}

.img-upload {
    display: flex;
    justify-content: center;
    .modal-dialog {
        width: 500px;
        .modal-content {
            .modal-body {
                display: flex;
                justify-content: center;
                .img-file {
                    border: 2px dashed $pinkSwanColor;
                    padding: 68px;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: column;
                    margin: 30px;
                    align-items: center;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                    p {
                        text-align: center;
                        margin-top: 20px;
                        color: $reseneNightOwlColor;
                        font-weight: $fontWeight600;
                        font-size: $fontSize14px;
                    }
                    button {
                        text-align: center;
                        background-color: $navyBlueColor !important;
                        border-radius: 4px;
                        border: none;
                        color: $whiteColor;
                        padding: 5px;
                        width: 150px;
                    }
                }
            }
            .modal-footer {
                border-top: none;
                justify-content: center;
                margin-top: -20px;
                margin-bottom: 24px;
                .cancel-btn {
                    background-color: $pinkSwanColor;
                    border: none;
                    border-radius: 4px;
                    width: 142px;
                }
                .save-btn {
                    background-color: $companyPrimaryColor;
                    color: $companyPrimaryButtonTextColor;
                    border: none;
                    border-radius: 4px;
                    width: 142px;
                }
            }
        }
    }
}
.profile-email {
    .back-btn {
        position: absolute;
        top: 43px;
        left: 38px;
        background: $whisperColor;
        border-radius: 2px;
        opacity: 1;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.profile-mobile {
    a {
        text-decoration: none;
    }
    .back-button {
        background: $whisperColor;
        border-radius: 2px;
        opacity: 1;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .back-btn {
        position: absolute;
        top: 43px;
        left: 38px;
        background: $whisperColor;
        border-radius: 2px;
        opacity: 1;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.change-password {
    .back-btn {
        position: absolute;
        top: 30px;
        left: 38px;
        background: $whisperColor;
        border-radius: 2px;
        opacity: 1;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.star-rating-container {
    position: relative;
    display: inline-block;
    margin-bottom: 5px;

    &:hover .dropdown-content {
        display: block;
    }
}
.star-rating-container {
    i {
        font-size: $fontSize20px;
        margin-right: 10px;
        cursor: pointer;
    }
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: $whiteColor;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        padding: 10px;
        width: 250px;

        /* Dropdown content styling */
        a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;

            &:hover {
                background-color: $superSilverColor;
            }
        }
        i {
            cursor: pointer;
            font-size: $fontSize17px;
            margin-left: 10px;
            margin-top: -9px;
        }
    }
}

.wishlist {
    .carousel__item {
        margin: 0 10px;
        position: relative;
        img {
            width: 100%;
            height: 210px;
            object-fit: contain;
        }
        .out-stock {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
        }
    }
    .image-box {
        background: $coyColor;
        padding: 30px;
        height: 100%;
        margin: 0;
        overflow: hidden;
    }

    .carousel__caption {
        margin: 15px 0;

        .title {
            letter-spacing: 0px;
            color: $rusticRedColor;
            font-size: $fontSize16px;
            font-weight: $fontWeight600;
            margin-bottom: 1rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-word;

            span {
                font-weight: $fontWeight400 !important;
                cursor: pointer;
            }
        }

        p {
            margin-bottom: 4px;

            &.model {
                letter-spacing: 0px;
                text-align: left;
                font-size: $fontSize15px;
                font-weight: $fontWeight500;
                color: $rusticLightRedColor;

                span {
                    text-align: left;
                }
            }

            &.rating {
                letter-spacing: 0px;
                color: $purplishBrownColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;

                i {
                    color: $yellowColor;
                    font-size: $fontSize14px;
                    margin-right: 5px;
                }
            }
        }

        .price {
            letter-spacing: 0px;
            color: $rusticRedColor;
            font-size: $fontSize18px;
            font-weight: $fontWeight600;
        }
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    .cart-btn {
        &.btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            color: $companyPrimaryButtonTextColor;
            background-color: $companyPrimaryColor;
            width: 100%;
            border-radius: 4px;
            border: 1px solid $dullColor;
            &:hover {
                color: $companyPrimaryColor;
                background-color: $companyPrimaryButtonTextColor;
                border: 1px solid $companyPrimaryColor;
            }
        }
    }
    .delete-btn {
        letter-spacing: 0px;
        color: $resonantBlueColor;
        opacity: 1;
        font-size: $fontSize14px;
        font-weight: $fontWeight500;
        text-align: center;
        text-decoration: none;
        margin: 10px 0;
        display: block;
        width: 100%;
        &:hover {
            color: $companyPrimaryColor;
        }
    }
}

.orders {
    h2 {
        font-size: $fontSize22px;
        font-weight: $fontWeight600;
        color: $rusticRedColor;
        margin-bottom: 0;
    }
    .input-group {
        justify-content: end;
        width: auto;
        flex-wrap: unset;
        justify-content: center;
        .input-group-text {
            background: $whiteColor;
            border: 1px solid $rockCrystalColor;
            border-radius: 4px 0px 0px 4px;
            border-right: 0 !important;
            padding-right: 3px;
            height: 34px;
            i {
                color: $reseneNightOwlColor;
                font-size: $fontSize14px;
            }
        }
        input {
            background: $whiteColor;
            border: 1px solid $rockCrystalColor;
            border-left: 0 !important;
            border-radius: 0px 4px 4px 0px;
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            width: 100%;
            padding: 8px 10px;
            color: $reseneNightOwlColor;
            padding-left: 4px;
            height: 34px;
            &::placeholder {
                color: $reseneNightOwlColor;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .filter-dropdown {
        button {
            display: flex;
            align-items: center;
            background: $whiteColor;
            border: 1px solid $rockCrystalColor;
            border-radius: 4px;
            color: $reseneNightOwlColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            width: 120px;
            text-align: center;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding-left: 5px;
            img {
                height: 16px;
            }
        }
        select {
            border: none;
            height: 30px;
            padding: 0;
            padding-left: 6px;
            color: $reseneNightOwlColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.address-section {
    .card {
        border: 0;
        border-radius: 0;
        .card-body {
            padding: 30px;
            .sub-title {
                font-size: $fontSize15px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
        }
    }
    .top-title {
        font-size: $fontSize24px;
        font-weight: $fontWeight600;
        color: $rusticRedColor;
        margin-bottom: 0;
    }
    p {
        font-size: $fontSize14px;
        font-weight: $fontWeight400;
        color: $rusticRedColor;
        margin-bottom: 0;
    }
    form {
        label {
            letter-spacing: 0px;
            color: $doveGrey;
            opacity: 1;
            font-size: $fontSize12px;
            font-weight: $fontWeight500;
        }
        .form-check-input {
            &:focus {
                outline: none;
                box-shadow: none;
            }
            accent-color: $companyPrimaryColor;
        }
        .form-group {
            margin-bottom: 1rem;
            .wrapper {
                background: $whiteSmokeDarkColor;
                border: 1px solid $earlyCrocusColor;
                border-radius: 4px;
                opacity: 1;
                color: $rusticLightRedColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                z-index: unset;
            }
            .css-1jqq78o-placeholder {
                color: $rusticLightRedColor;
                font-size: $fontSize12px;
                font-weight: $fontWeight400;
            }
            .jUgxBz {
                input {
                    font-size: $fontSize12px;
                    &::placeholder {
                        color: $rusticLightRedColor;
                        font-size: $fontSize12px;
                        font-weight: $fontWeight400;
                    }
                }
            }
            .kVlBpq {
                input {
                    font-size: $fontSize12px;
                    &::placeholder {
                        color: $rusticLightRedColor;
                        font-size: $fontSize12px;
                        font-weight: $fontWeight400;
                    }
                }
            }
            .css-hlgwow {
                font-size: $fontSize12px;
                font-weight: $fontWeight400;
            }
        }
        .shop-btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            color: $companyPrimaryButtonTextColor;
            background-color: $companyPrimaryColor;
            width: 32%;
            border-radius: 4px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            &:hover {
                color: $companyPrimaryColor;
                background-color: $companyPrimaryButtonTextColor;
                border: 1px solid $companyPrimaryColor;
            }
        }
        .form-control {
            background: $whiteSmokeDarkColor;
            border: 1px solid $earlyCrocusColor;
            border-radius: 4px;
            opacity: 1;
            color: $rusticLightRedColor;
            font-size: $fontSize12px;
            padding: 8px;
            &:focus {
                outline: none;
                box-shadow: none;
            }

            &::placeholder {
                color: $reseneNightOwlColor;
                font-weight: $fontWeight400;
                font-size: $fontSize12px !important;
            }
        }
        .PhoneInput {
            .PhoneInputInput {
                background: $whiteSmokeDarkColor;
                border: 1px solid $earlyCrocusColor;
                border-radius: 4px;
                opacity: 1;
                color: $rusticLightRedColor;
                font-size: $fontSize12px;
                font-weight: $fontWeight400;
                padding: 8px;
                &:focus {
                    outline: none;
                }

                &::placeholder {
                    font-size: $fontSize12px;
                    font-weight: $fontWeight400;
                }
            }
            .PhoneInputCountryIcon {
                box-shadow: none;
            }
        }
        .button {
            background-color: $lynxWhiteColor;
            color: $rusticRedColor;
        }
    }
    .choose-address {
        font-size: $fontSize14px;
        font-weight: $fontWeight400;
        color: $companyPrimaryButtonTextColor;
        background-color: $companyPrimaryColor;
        width: 145px;
        border-radius: 4px;
        margin-right: 5px;
        margin-top: 2px;
        white-space: nowrap;
        &:hover {
            color: $companyPrimaryColor;
            background-color: $companyPrimaryButtonTextColor;
            border: 1px solid $companyPrimaryColor;
        }
    }
    .file-section {
        .image-input {
            background: $whiteSmokeDarkColor;
            border: 1px dashed $earlyCrocusColor;
            border-radius: 4px;
            text-align: center;
            width: 120px;
            height: 100px;
            input {
                display: none;
            }
            label {
                align-items: center;
                color: $buttonBlueColor;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                font-size: $fontSize13px;
                font-weight: $fontWeight400;
                height: 100px;
                justify-content: center;
                i {
                    color: $sugarTreeColor;
                    font-weight: $fontWeight400;
                }
                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    i {
                        font-size: $fontSize22px;
                    }
                }
            }
        }
    }
    .btn {
        &.shop-btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            border: 1px solid $companyPrimaryColor;
            color: $companyPrimaryButtonTextColor;
            background-color: $companyPrimaryColor;
            width: 145px;
            border-radius: 4px;
            margin-right: 5px;
            margin-top: 2px;
            white-space: nowrap;
            &:hover {
                color: $companyPrimaryColor;
                background-color: $companyPrimaryButtonTextColor;
                border: 1px solid $companyPrimaryColor !important;
                i {
                    color: $companyPrimaryColor !important;
                }
            }
            i {
                margin-right: 0.25rem;
                font-size: $fontSize12px;
            }
        }
        &.cancel-btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            color: $whiteColor;
            background-color: $pinkSwanColor;
            width: 145px;
            border-radius: 4px;
            &:hover {
                background-color: $spanishGrayColor;
            }
        }
    }
}

.login-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-sec {
        img {
            height: 70px;
        }
    }

    h1 {
        text-align: center;
        text-transform: capitalize;
        color: $rusticRedColor;
        font-size: $fontSize24px;
        font-weight: $fontWeight500;
        color: $rusticLightRedColor;
        margin-bottom: 15px;
    }

    .login-form {
        margin: 8px 0 0;

        label {
            letter-spacing: 0px;
            color: $reseneNightOwlColor;
            opacity: 1;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;
        }
        .form-check-input {
            &:focus {
                outline: none;
                box-shadow: none;
            }
            accent-color: $companyPrimaryColor;
            &[type="checkbox"] {
                height: 14px;
                margin-right: 4px;
            }
        }
        .form-group {
            margin-bottom: 1rem;
            &.small {
                gap: 1rem;
                align-items: center;
                justify-content: center;
                .form-control {
                    width: 44px;
                    font-size: $fontSize24px;
                    font-weight: $fontWeight600;
                    line-height: 0;
                }
            }
        }
        .link-text {
            color: $navyBlueColor;
            text-decoration: none;
            &:hover {
                text-decoration: none;
                color: $companyPrimaryColor;
            }
        }
        .form-control {
            background: $whiteSmokeDarkColor;
            border: 1px solid $earlyCrocusColor;
            border-radius: 4px;
            opacity: 1;
            color: $rusticLightRedColor;
            font-size: $fontSize14px;
            font-weight: $fontWeight500;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &::placeholder {
                color: $reseneNightOwlColor;
                font-weight: $fontWeight400;
                font-size: $fontSize12px !important;
            }
        }

        p {
            color: $reseneNightOwlColor;
            font-size: $fontSize15px;
            font-weight: $fontWeight500;
            margin-bottom: 5px;
            text-align: left;

            a {
                color: $navyBlueColor;
                text-decoration: none;
                margin: 0 4px;
                &:hover {
                    text-decoration: none;
                    color: $companyPrimaryColor !important;
                }
            }

            &.register-link {
                text-align: center;
            }
        }
        .PhoneInput {
            .PhoneInputInput {
                background: $whiteSmokeDarkColor;
                border: 1px solid $earlyCrocusColor;
                border-radius: 4px;
                opacity: 1;
                color: $rusticLightRedColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                padding: 0.375rem 0.75rem;
                &:focus {
                    outline: none;
                }
            }
            .PhoneInputCountryIcon {
                box-shadow: none;
            }
            .PhoneInputCountrySelect {
                color: $rusticLightRedColor;
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
            }
        }
        .react-tel-input {
            margin-left: 9%;
            width: auto !important;
            .flag-dropdown {
                background: $whiteSmokeDarkColor;
                border: 1px solid $earlyCrocusColor;
                border-radius: 4px;
                left: -52px;
            }
            .country-list {
                .search-box {
                    margin-left: 0;
                    width: 100%;
                }
                .search {
                    padding: 10px 10px 10px 10px !important;
                }
            }
            .form-control {
                width: 100%;
                padding: 0.375rem 0.75rem;
            }
        }

        .btn {
            &.shop-btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
                color: $companyPrimaryButtonTextColor;
                background-color: $companyPrimaryColor;
                width: 100%;
                border-radius: 4px;
                margin-top: 2px;
                &:hover {
                    color: $companyPrimaryColor !important;
                    background-color: $companyPrimaryButtonTextColor !important;
                    border: 1px solid $companyPrimaryColor !important;
                    i {
                        color: $companyPrimaryColor !important;
                    }
                }
                i {
                    margin-right: 0.25rem;
                    font-size: $fontSize12px;
                }
            }
            &.cancel-btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
                color: $whiteColor;
                background-color: $spanishGrayColor;
                width: 100%;
                border-radius: 4px;
                &:hover {
                    color: $spanishGrayColor !important;
                    background-color: $whiteColor !important;
                    border: 1px solid $spanishGrayColor !important;
                }
            }
        }

        hr {
            border-color: $crystallizeColor;
            border-width: 1.4px;
        }
    }
}

.profile-email-otp {
    .back-btn {
        position: absolute;
        top: 33px;
        left: 38px;
        background: $whisperColor;
        border-radius: 2px;
        opacity: 1;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.delete-account {
    h4 {
        font-size: $fontSize18px;
        font-weight: $fontWeight500;
        color: $rusticRedColor;
    }
    h5 {
        font-size: $fontSize18px;
        font-weight: $fontWeight500;
        color: $rusticRedColor;
    }
    p {
        margin-bottom: 15px !important;
        font-size: $fontSize14px !important;
    }
    input {
        margin-right: 5px;
        width: 23px;
    }
    .delete-acc-icon {
        img {
            height: 115px;
            width: 149px;
        }
    }

    .delete-buttons {
        .shop-btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            color: $companyPrimaryButtonTextColor;
            background-color: $companyPrimaryColor;
            width: 145px !important;
            border-radius: 4px;
            margin-right: 5px;
            margin-top: 2px;
        }
        .cancel-btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            color: $whiteColor;
            background-color: $pinkSwanColor;
            width: 145px !important;
            border-radius: 4px;
        }
    }
}

.cancel-return {
    .modal-dialog {
        width: 600px;
        min-width: 600px;
        &.modal-sm {
            width: 480px;
            min-width: 480px;
            .modal-body {
                padding-left: 3.5rem;
                padding-right: 2rem;
            }
        }
    }
    .modal-header {
        border-bottom: 0;
        .btn-close {
            visibility: hidden;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .modal-body {
        .delete-buttons {
            .shop-btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
                color: $whiteColor;
                background-color: $pinkSwanColor;
                width: 145px !important;
                border-radius: 4px;
                margin-right: 5px;
                margin-top: 2px;
            }
            .cancel-btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight400;
                color: $companyPrimaryButtonTextColor;
                background-color: $companyPrimaryColor;
                width: 145px !important;
                border-radius: 4px;
            }
        }
        h4 {
            color: $rusticRedColor;
            font-size: $fontSize18px;
            font-weight: $fontWeight600;
            margin-top: 13px;
        }
        p {
            color: $purplishBrownColor;
            font-size: $fontSize15px;
        }
    }
}
