@import "variables";

#cartPageSec {
    // product-details-column start
    .product-details-column {
        .card {
            border: 0;
            border-radius: 0;
            .card-header {
                display: flex;
                justify-content: space-between;
                background-color: $whiteColor;
                align-items: center;

                h1 {
                    font-size: $fontSize18px;
                    font-weight: $fontWeight600;
                    color: $rusticRedColor;
                    margin-bottom: 0;
                }
                p {
                    margin-bottom: 0px;
                    span {
                        font-weight: 600;
                        color: grey;
                    }
                }
                .delete-cart {
                    background-color: transparent;
                    border: none;
                    color: $tomatoBabyColor;
                    font-weight: $fontWeight500;
                    font-size: $fontSize12px;
                    img {
                        margin-right: 5px;
                        margin-bottom: 4px;
                        height: 12px;
                    }
                }
                .btn {
                    font-size: $fontSize12px;
                    color: $energyGreenColor;
                    padding: 0;
                    font-weight: $fontWeight500;
                    &:focus {
                        border: unset;
                        outline: none;
                        box-shadow: none;
                    }
                    &:active {
                        border: unset;
                    }
                    img {
                        margin-right: 4px;
                        margin-bottom: 4px;
                        height: 12px;
                    }
                    &.save-cart {
                        background-color: $navyBlueColor;
                        border-radius: 4px;
                        color: $whiteColor;
                        padding: 6px;
                        width: 120px;
                        &:hover {
                            background-color: $navyBlueColor;
                        }
                    }
                    &.save-cart-btn {
                        font-size: $fontSize12px;
                        color: $energyGreenColor;
                        padding: 0;
                        font-weight: $fontWeight500;
                        img {
                            margin-right: 4px;
                            margin-bottom: 4px;
                            height: 12px;
                        }
                    }
                }
            }
            .card-body {
                padding-top: 25px;
                padding-bottom: 25px;
                table {
                    tbody {
                        tr {
                            td:nth-of-type(1) {
                                img {
                                    background-color: $whiteColor;
                                    border: 1px solid $whisperColor;
                                    width: 138px;
                                    height: 157px;
                                    object-fit: contain;
                                }
                                .out-stock {
                                    position: absolute;
                                    top: 96px;
                                    left: 41px;
                                    white-space: nowrap;
                                }
                            }
                            td:nth-of-type(2) {
                                font-size: $fontSize14px;
                                font-weight: $fontWeight600;
                                color: $rusticRedColor;
                                margin-bottom: 0;
                                width: 100%;
                                h6 {
                                    font-size: $fontSize18px;
                                    font-weight: $fontWeight600;
                                    color: $rusticRedColor;
                                }
                                .btn {
                                    font-size: $fontSize12px;
                                    font-weight: $fontWeight500;
                                    color: $navyBlueColor;
                                    background-color: transparent;
                                    border: 0;
                                    &:focus {
                                        border: unset;
                                    }
                                    &:active {
                                        border: unset;
                                    }
                                }

                                .price-details {
                                    white-space: nowrap;

                                    h2 {
                                        font-size: $fontSize20px;
                                        font-weight: $fontWeight600;
                                        color: $companyPrimaryColor;
                                        margin-bottom: 0px;
                                    }
                                    h3 {
                                        font-size: $fontSize18px;
                                        font-weight: $fontWeight600;
                                        color: $rusticRedColor;
                                        text-decoration: line-through;
                                        margin-bottom: 0px;
                                    }
                                    h4 {
                                        font-size: $fontSize16px;
                                        font-weight: $fontWeight600;
                                        color: $energyGreenColor;
                                        margin-bottom: 0px;
                                    }
                                }
                                .pdt-model {
                                    span {
                                        font-weight: $fontWeight600;
                                        color: grey;
                                    }
                                }
                                .qty-price {
                                    color: black;
                                    font-size: $fontSize15px;
                                    font-weight: $fontWeight400;
                                    margin-bottom: 2px;
                                    span {
                                        font-weight: $fontWeight600;
                                        color: grey;
                                    }
                                }
                                .options-btns {
                                    .btn {
                                        font-size: $fontSize14px;
                                        color: $resonantBlueColor;
                                        background-color: $whiteColor;
                                        font-weight: $fontWeight500;
                                    }
                                    .delete-btn {
                                        border-left: 1px solid $pinkSwanColor;
                                        border-right: 1px solid $pinkSwanColor;
                                        border-radius: unset;
                                    }
                                }
                                h5 {
                                    font-size: $fontSize14px;
                                    font-weight: $fontWeight600;
                                    color: $rusticRedColor;
                                }
                                .quantity-btns {
                                    .btn {
                                        font-size: $fontSize16px;
                                        color: $rusticRedColor;
                                        background-color: $superSilverColor;
                                        height: 32px;
                                        width: 35px;
                                        padding: 2px;
                                        &:focus {
                                            border: unset;
                                        }
                                        &:active {
                                            border: unset;
                                        }
                                        i {
                                            font-size: $fontSize14px;
                                        }
                                    }
                                    .count-num {
                                        background-color: $glimpseOfPinkColor !important;
                                        width: 75px;
                                        border: 0px;
                                    }
                                }
                                .dropdown-toggle::after {
                                    display: none;
                                }
                                .dropdown-menu {
                                    width: 320px;
                                    h2 {
                                        padding: 0px;
                                        font-weight: $fontWeight600;
                                        color: $rusticRedColor;
                                        font-size: $fontSize14px;
                                    }
                                    button {
                                        font-size: $fontSize10px;
                                    }
                                    h3 {
                                        color: $rusticRedColor;
                                        font-size: $fontSize14px;
                                        font-weight: $fontWeight600;
                                        margin-bottom: 0px;
                                    }
                                    p {
                                        color: $rusticRedColor;
                                        font-size: $fontSize14px;
                                        font-weight: $fontWeight400;
                                        white-space: unset;
                                    }
                                    .btn {
                                        font-size: $fontSize14px;
                                        font-weight: $fontWeight600;
                                        color: $companyPrimaryButtonTextColor;
                                        background-color: $companyPrimaryColor;
                                        width: 100%;
                                        border-radius: 4px;
                                    }
                                    i {
                                        color: $resonantBlueColor;
                                    }
                                }
                                img {
                                    width: 13px;
                                }
                                p {
                                    font-size: $fontSize14px;
                                    color: $resonantBlueColor;
                                    margin-bottom: 0px;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // product-details-column end
    // coupon-card start
    .coupon-card {
        border: 0;
        border-radius: 0;
        .card-header {
            background-color: $whiteColor;
            border: 0;
            padding-top: 1rem;
            h2 {
                font-size: $fontSize16px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
        }
        .coupons {
            border: 2px dashed $resonantBlueColor;
            background-color: $snowColor;
            padding: 10px;
            position: relative;
            display: inline-block;
            width: 100%;
            .close-button {
                position: absolute;
                top: -12px;
                right: -12px;
                background: rgba(0, 0, 0, 0.5);
                color: $whiteColor;
                border: none;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: $fontSize12px;
                background-color: black;
            }
        }
        .card-body {
            .input-group {
                input {
                    font-size: $fontSize14px;
                    border: 1px solid $ghostwaverColor;
                    border-radius: 2px;
                    border-right: 0px !important;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                .input-group-text {
                    background-color: $whiteColor;
                    .fa-search {
                        margin-left: 5px;
                        color: $sugarTreeColor;
                    }
                }
                .apply-btn {
                    font-size: $fontSize14px;
                    background-color: $watermillWoodColor;
                    color: $rusticLightRedColor;
                    border-radius: 2px;
                    line-height: 24px;
                    font-weight: $fontWeight600;
                    &:focus {
                        border: unset;
                    }
                    &:active {
                        border: unset;
                    }
                }
            }
            h3 {
                font-size: $fontSize14px;
                color: $rusticRedColor;
            }
            .form-check {
                label {
                    font-size: $fontSize14px;
                    font-weight: $fontWeight600;
                }
            }
            p {
                font-size: $fontSize12px;
                font-weight: $fontWeight500;
                color: $rusticRedColor;
                margin-bottom: 0;
                span {
                    font-weight: $fontWeight600;
                }
            }
            .view-btn {
                font-size: $fontSize12px;
                font-weight: $fontWeight500;
                color: $resonantBlueColor;
                background-color: transparent;
                padding: 0;
                border: unset;
                &:focus {
                    border: unset;
                }
                &:active {
                    border: unset;
                }
                a {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
        .card-footer {
            text-align: center;
            background-color: $whiteColor;
            .btn {
                font-size: $fontSize14px;
                font-weight: $fontWeight500;
                color: $rusticRedColor;
                background-color: transparent;
                padding: 0;
                &:focus {
                    border: unset;
                }
                &:active {
                    border: unset;
                }
            }
        }
    }
    // coupon-card end
    // order-summary-card start

    .order-summary-card {
        border: 0;
        border-radius: 0;
        height: 100%;
        .card-header {
            background-color: $whiteColor;
            border-bottom: unset;
            h2 {
                font-size: $fontSize18px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
                margin-bottom: 0;
            }
        }
        .card-body {
            padding-top: 0;
            table {
                tbody {
                    tr {
                        th,
                        td {
                            font-size: $fontSize14px;
                            vertical-align: middle;
                            padding: 0.2rem 0.5rem;
                        }
                        th {
                            font-weight: $fontWeight400;
                            color: $rusticRedColor;
                        }
                        td {
                            color: $rusticRedColor;
                            font-weight: $fontWeight600;
                        }
                    }
                    tr:nth-of-type(2) {
                        td {
                            font-weight: $fontWeight600;
                        }
                    }
                    // tr:nth-of-type(3) {
                    //     td {
                    //         color: $paradiseGrapeColor;
                    //     }
                    // }
                    tr:nth-of-type(4) {
                        td {
                            color: $rusticRedColor;
                            font-weight: $fontWeight600;
                        }
                    }
                }
                tfoot {
                    border-top: 1px solid $superSilverColor;
                    tr {
                        th:nth-of-type(2) {
                            font-size: $fontSize18px;
                            text-align: right;
                        }
                        .btn {
                            font-size: $fontSize14px;
                            font-weight: $fontWeight600;
                            color: $companyPrimaryButtonTextColor;
                            background-color: $companyPrimaryColor;
                            width: 100%;
                            border-radius: 4px;
                            a {
                                color: inherit;
                                text-decoration: none;
                            }
                        }
                        p {
                            font-size: $fontSize14px;
                            color: $rusticRedColor;
                            margin-bottom: 0;
                            span {
                                font-weight: $fontWeight600;
                            }
                        }
                    }
                }
            }
        }
    }
    // order-summary-card end
    // pay-with-card start
    .pay-with-card {
        border: 0;
        border-radius: 0;
        .card-header {
            background-color: $whiteColor;
            h2 {
                font-size: $fontSize18px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
            }
            img {
                background-color: $whiteColor;
                border: 1px solid $ghostwaverColor;
                border-radius: 2px;
                width: 70px;
                height: 35px;
                object-fit: contain;
                padding: 2px;
            }
        }
        .card-body {
            h3 {
                font-size: $fontSize16px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
            }
            p {
                font-size: $fontSize14px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
            }
            button {
                font-size: $fontSize14px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
                background-color: transparent;
                padding: 0;
            }
        }
    }
    // pay-with-card end

    // wishlist products
    .products-sec {
        background: $whiteColor;
        padding: 25px 20px;
        margin: 6px 0;

        .overlap-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            h2 {
                text-align: left !important;
                margin-bottom: 0;
                text-transform: capitalize;
                color: $rusticRedColor;
                font-size: $fontSize22px;
                font-weight: $fontWeight500;
            }
            a {
                color: $navyBlueColor;
            }

            .next-button {
                background: $navyBlueColor;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;

                i {
                    color: $whiteColor;
                    font-size: $fontSize12px;
                }
            }
            .viewall {
                a {
                    text-decoration: none !important;
                }
            }
        }
        .react-multi-carousel-list {
            padding: 20px 0;

            .react-multi-carousel-item {
                &:first-child {
                    .carousel__item {
                        margin-left: 0 !important;
                        position: relative;
                    }
                }

                &:last-child {
                    .carousel__item {
                        margin-right: 0 !important;
                    }
                }

                .carousel__item {
                    margin: 0 10px;
                    cursor: pointer;
                    .out-stock {
                        position: absolute;
                        top: 30%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        white-space: nowrap;
                    }
                    img {
                        width: 100%;
                        height: 210px;
                        object-fit: contain;
                    }
                }

                .image-box {
                    background: $coyColor;
                    padding: 30px;
                    height: 314px;
                    margin: 0;
                    overflow: hidden;
                }

                .carousel__caption {
                    margin: 15px 0;

                    .title {
                        letter-spacing: 0px;
                        color: $rusticRedColor;
                        text-transform: uppercase;
                        font-size: $fontSize16px;
                        font-weight: $fontWeight600;
                        overflow: hidden;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        span {
                            font-weight: $fontWeight400 !important;
                            text-transform: capitalize;
                        }
                    }

                    p {
                        margin-bottom: 4px;

                        &.model {
                            letter-spacing: 0px;
                            text-align: left;
                            font-size: $fontSize15px;
                            font-weight: $fontWeight500;
                            color: $rusticLightRedColor;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            span {
                                text-align: left;
                            }
                        }

                        &.rating {
                            letter-spacing: 0px;
                            color: $purplishBrownColor;
                            font-size: $fontSize14px;
                            font-weight: $fontWeight500;

                            i {
                                color: $yellowColor;
                                font-size: $fontSize14px;
                                margin-right: 5px;
                            }
                        }
                    }

                    .price {
                        letter-spacing: 0px;
                        color: $rusticRedColor;
                        font-size: $fontSize18px;
                        font-weight: $fontWeight600;
                    }
                }
                .cart-btn {
                    &.btn {
                        font-size: $fontSize14px;
                        font-weight: $fontWeight400;
                        color: $companyPrimaryButtonTextColor;
                        background-color: $companyPrimaryColor;
                        width: 100%;
                        border-radius: 4px;
                        border: 1px solid $dullColor;
                        &:hover {
                            color: $companyPrimaryColor;
                            background-color: $companyPrimaryButtonTextColor;
                            border: 1px solid $companyPrimaryColor;
                        }
                    }
                }
                .delete-btn {
                    letter-spacing: 0px;
                    color: $resonantBlueColor;
                    opacity: 1;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;
                    text-align: center;
                    text-decoration: none;
                    margin: 10px 0;
                    display: block;
                    width: 100%;
                    &:hover {
                        color: $companyPrimaryColor;
                    }
                }
            }

            .react-multi-carousel-dot-list {
                top: -16px !important;
                bottom: unset;

                li {
                    width: 100%;
                }

                .custom-dot {
                    background: $rockCrystalColor;
                    width: 100%;
                    height: 2px;
                    border-width: 0;

                    &.custom-dot--active {
                        background: $companyPrimaryColor;
                        width: 260px;
                    }
                }
            }
        }
    }
}

.social-media {
    .modal-dialog {
        position: fixed;
        margin: -1px;
        width: 400px;
        height: 100%;
        right: 0;
        top: 0;
        bottom: 0;
        .modal-content {
            height: 100vh;
            border-radius: 0;
            .modal-header {
                .modal-title {
                    font-size: $fontSize18px !important;
                    font-weight: $fontWeight600 !important;
                }
                .btn-close {
                    font-size: $fontSize10px;
                    color: $paradiseGrapeColor !important;
                    border: none;
                    background-color: $whiteColor;
                }
            }
            .modal-body {
                h4 {
                    font-size: $fontSize12px;
                    color: $sugarTreeColor;
                }
                p {
                    margin-bottom: 0px;
                    margin-left: 13px;
                    font-weight: $fontWeight600;
                }
                .copy-url {
                    width: 248px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
