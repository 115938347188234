@import "variables";

.footer-sec {
    .footer-wrapper {
        .toplink {
            z-index: inherit;
            width: 100%;
            .footer-title {
                border-bottom: 1px solid rgba(328, 255, 255, 0.5);
                p {
                    margin-bottom: 0px;
                }
            }

            a {
                color: $whiteColor;
            }
            .footer-container {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }
            .footerlink {
                display: inline-block;
                margin: 4px 0px;

                &.title {
                    letter-spacing: 0px;
                    color: $footerHeadingTextWhiteColor;
                    text-transform: capitalize;
                    opacity: 1;
                    font-weight: $fontWeight400;
                    font-size: $fontSize20px;
                    white-space: nowrap;
                }

                a {
                    letter-spacing: 0px;
                    color: $huskyColor;
                    text-transform: inherit;
                    opacity: 1;
                    font-size: $fontSize15px;
                    font-weight: normal;
                    line-height: 22px;
                    text-decoration: none;

                    &:hover {
                        color: $hyacinthTintColor;
                    }
                }
            }
        }

        .bottom-footer {
            padding: 15px 0px;
            background: $darkCeruleanColor;

            .footernav {
                height: auto;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                margin-top: 5px;
                margin-bottom: 5px;
                justify-content: center;
                padding: 0px;
                &.download {
                    justify-content: center;
                    img {
                        height: 36px;
                        margin-bottom: 8px;
                        margin-top: 4px;
                        width: 117px;
                    }
                }
                .social-media-icons {
                    i {
                        font-size: $fontSize22px;
                        color: $huskyColor;
                        margin-right: 14px;
                        cursor: pointer;
                        transition: transform 0.2s;
                        &:hover{
                            transform: scale(1.10);
                        }
 
                    }
                }
            }
        }

        .copright-sec {
            .region {
                margin: 10px 0;
                align-items: center;
                display: flex;
                justify-content: space-between;

                .regionnav {
                    font-size: $fontSize13px;
                    justify-content: space-between;
                    flex-direction: row;
                    margin-bottom: 0;
                    padding-left: 0;

                    span {
                        color: $whiteColor;
                        padding-right: 5px;
                    }

                    .regionlink {
                        margin-left: 0px;
                        margin-right: 2px;
                        letter-spacing: 0px;
                        color: $whiteColor;
                        opacity: 0.89;

                        a {
                            cursor: pointer;
                            letter-spacing: 0px;
                            color: $whiteColor;
                            opacity: 0.89;
                            font-size: $fontSize12px;
                            text-decoration: none;
                            font-weight: $fontWeight400;

                            &:hover {
                                color: $hyacinthTintColor;
                            }
                        }

                        &.copyright {
                            span {
                                margin: auto;
                                letter-spacing: 0px;
                                color: $rusticRedColor;
                                opacity: 1;
                                font-size: $fontSize14px;
                                font-weight: $fontWeight500;
                            }
                        }
                    }
                }
                .cards {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 0;
                    padding: 0;
                    li {
                        cursor: pointer;
                        &:last-child {
                            img {
                                margin-right: 0;
                            }
                        }
                    }
                    img {
                        height: 30px;
                        margin: 2px 4px;
                    }
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .footer-sec {
        .footer-wrapper {
            .toplink {
                .footerlink {
                    &.title {
                        width: auto !important;
                    }
                }
            }
        }
    }
}
