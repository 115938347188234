@import "variables";
#deleteAccountSection {
    .card {
        border: 0;
        border-radius: 0;
        max-width: 650px;
        margin: 1rem auto;
        h1 {
            text-align: center;
            text-transform: capitalize;
            color: $rusticRedColor;
            font-size: $fontSize24px;
            font-weight: $fontWeight500;
            color: $rusticLightRedColor;
            margin-bottom: 15px;
        }
        img {
            height: 115px;
            width: 149px;
        }
        h4 {
            font-size: $fontSize18px;
            font-weight: $fontWeight500;
            color: $rusticRedColor;
        }
        p {
            color: $reseneNightOwlColor;
            font-weight: $fontWeight500;
            margin-bottom: 15px;
            font-size: $fontSize14px;
        }
        input {
            margin-right: 5px;
            width: 23px;
        }
        .shop-btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            color: $whiteColor;
            background-color: $pinkSwanColor !important;
            width: 145px;
            border-radius: 4px;
            margin-top: 2px;
        }
        .cancel-btn {
            font-size: $fontSize14px;
            font-weight: $fontWeight400;
            color: $companyPrimaryButtonTextColor;
            background-color: $companyPrimaryColor !important;
            width: 145px;
            border-radius: 4px;
        }
        .back-btn {
            position: absolute;
            left: 17px;
            top: 25px;
            background: $whisperColor;
            border-radius: 2px;
            opacity: 1;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
