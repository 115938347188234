@import "variables";

#policy-sec {
    .card {
        border: 0;
        border-radius: 0;
        background-color: transparent;
        img {
            height: 25px;
            margin-right: 0.5rem;
        }
        .card-header {
            background-color: $whiteColor;
            padding: 1rem;
            border-color: $whiteSmokeColor;
            border-width: 1.5px;
            border-radius: 0;
            text-align: center;
            h2 {
                font-size: $fontSize20px;
                font-weight: $fontWeight600;
                color: $rusticRedColor;
                margin-bottom: 0;
                flex-grow: 9;
                justify-content: center;
            }
            .input-group {
                flex-wrap: unset;
                justify-content: end;
                input {
                    border: 1px solid $ghostwaverColor;
                    border-radius: 2px;
                    background-color: $whiteColor;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }

                    &::placeholder {
                        color: $reseneNightOwlColor;
                        font-size: $fontSize12px;
                        font-weight: $fontWeight400;
                    }
                }
                .input-group-text {
                    border: 1px solid $ghostwaverColor;
                    border-radius: 2px;
                    color: $lovelyEuphoricDelightColor;
                    background-color: $whiteColor;
                    border-left: 0px;
                    i {
                        color: $sugarTreeColor;
                    }
                }
            }
        }
        .card-body {
            padding: 0;
            .policy-right {
                background-color: $whiteColor;
                margin-top: 0.125rem;
                padding: 1rem;
                overflow: auto;
            }
            .policy-left {
                margin-top: 0.125rem;
            }
            .policy-description {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-size: $othersContentTitleSize24px;
                    font-weight: $othersContentTitleWeight600;
                    color: $rusticRedColor;
                    margin-bottom: 1rem;
                }
                p {
                    font-size: $othersContentDescriptionSize16px;
                    font-weight: $othersContentDescriptionWeight400;
                    color: $rusticRedColor;
                    white-space: pre-line;
                    text-align: justify;
                }
                ul,
                ol {
                    padding-left: 1rem;
                    li {
                        list-style: auto !important;
                        font-size: $othersContentDescriptionSize16px;
                        font-weight: $othersContentDescriptionWeight400;
                        color: $rusticRedColor;
                        white-space: pre-line;
                        text-align: justify;
                        h2 {
                            font-size: $othersContentTitleSize24px;
                            font-weight: $othersContentTitleWeight600;
                            color: $rusticRedColor;
                            margin-bottom: 1rem;
                        }
                        p {
                            font-size: $othersContentDescriptionSize16px;
                            font-weight: $othersContentDescriptionWeight400;
                            color: $rusticRedColor;
                            white-space: pre-line;
                            text-align: justify;
                        }
                        &::marker {
                            font-weight: $fontWeight600;
                        }
                    }
                }
            }
        }
        .nav-item {
            background-color: $whiteColor;
            padding: 0.25rem 1rem;
            margin-bottom: 0.25rem;
            a {
                color: $paradiseGrapeColor;
                font-size: $fontSize15px;
                font-weight: $fontWeight600;
                text-decoration: none;
                padding: 8px 0px;
                display: block;
                &.active {
                    color: $rusticLightRedColor;
                }

                &:hover {
                    color: $rusticLightRedColor;
                }
                a {
                    padding: 0;
                }
                span {
                    padding: 0px 8px;
                    border: 1px solid $rusticRedColor;
                    border-radius: 50%;
                    margin-right: 5px;
                    i {
                        font-size: $fontSize12px;
                    }
                }
            }
        }
        .accordion {
            margin-top: 0.25rem;
            .accordion-item {
                border: none;
                border-radius: 0;
                background-color: $whiteColor;
                margin-bottom: 0.1rem;
                .accordion-button {
                    border: 0;
                    border-radius: 0;
                    background-color: transparent;
                    box-shadow: none;
                    color: $rusticRedColor;
                    font-size: $faqTitleHeaderSize15px;
                    font-weight: $faqTitleHeaderWeight600;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    border-bottom: 1px solid $ghostwaverColor;
                    &::after {
                        content: "\f068";
                        font-family: FontAwesome;
                        right: 0;
                        position: absolute;
                        background: $whiteColor;
                        border-radius: 50%;
                        border: 2px solid $waitingColor;
                        font-size: $fontSize10px;
                        font-weight: normal;
                        color: $waitingColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 20px;
                        padding: 2px 5px;
                        transition: none;
                    }
                    &.collapsed {
                        &::after {
                            content: "\f067";
                        }
                    }
                    &[aria-expanded="true"] {
                        color: $companyPrimaryColor;
                        &::after {
                            color: $companyPrimaryColor;
                            border-color: $companyPrimaryColor;
                        }
                    }
                }
                .accordion-body {
                    p {
                        color: $rusticRedColor;
                        font-size: $faqTitleDetailsSize14px;
                        font-weight: $faqTitleDetailsWeight400;
                        text-align: left;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
