@import "variables";

.login-page {
    max-width: 650px;
    margin: 1rem auto;
    &.signup {
        .card {
            .login-sec {
                .login-form {
                    p {
                        text-align: left;
                    }
                }
            }
        }
    }
    .card {
        padding: 30px 35px;
        border-radius: 0;
        border: 0;

        .login-sec {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .logo-sec {
                img {
                    height: 70px;
                }
            }

            h1 {
                text-align: center;
                text-transform: capitalize;
                color: $rusticRedColor;
                font-size: $fontSize24px;
                font-weight: $fontWeight500;
                color: $rusticLightRedColor;
                margin-bottom: 15px;
            }

            .login-form {
                margin: 8px 0 0;

                label {
                    letter-spacing: 0px;
                    color: $reseneNightOwlColor;
                    opacity: 1;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;
                }
                .form-check-input {
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    accent-color: $companyPrimaryColor;
                    &[type="checkbox"] {
                        height: 14px;
                        margin-right: 4px;
                    }
                }
                .form-group {
                    margin-bottom: 1rem;
                    &.small {
                        gap: 1rem;
                        align-items: center;
                        justify-content: center;
                        .form-control {
                            width: 44px;
                            font-size: $fontSize24px;
                            font-weight: $fontWeight600;
                            line-height: 0;
                        }
                    }
                    .success-msg {
                        font-size: $fontSize12px;
                        color: $energyGreenColor;
                        font-weight: $fontWeight400;
                    }
                }
                .link-text {
                    color: $navyBlueColor;
                    text-decoration: none;
                    &:hover {
                        text-decoration: none;
                        color: $companyPrimaryColor;
                    }
                }
                .form-control {
                    background: $whiteSmokeDarkColor;
                    border: 1px solid $earlyCrocusColor;
                    border-radius: 4px;
                    opacity: 1;
                    color: $rusticLightRedColor;
                    font-size: $fontSize14px;
                    font-weight: $fontWeight500;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }

                    &::placeholder {
                        color: $reseneNightOwlColor;
                        font-weight: $fontWeight400;
                        font-size: $fontSize12px !important;
                    }
                }

                p {
                    color: $reseneNightOwlColor;
                    font-size: $fontSize15px;
                    font-weight: $fontWeight500;
                    margin-bottom: 5px;
                    text-align: left;

                    a {
                        color: $navyBlueColor;
                        text-decoration: none;
                        margin: 0 4px;
                        &:hover {
                            text-decoration: none;
                            color: $companyPrimaryColor !important;
                        }
                    }

                    &.register-link {
                        text-align: center;
                    }
                }
                .PhoneInput {
                    .PhoneInputInput {
                        background: $whiteSmokeDarkColor;
                        border: 1px solid $earlyCrocusColor;
                        border-radius: 4px;
                        opacity: 1;
                        color: $rusticLightRedColor;
                        font-size: $fontSize14px;
                        font-weight: $fontWeight500;
                        padding: 0.375rem 0.75rem;
                        &:focus {
                            outline: none;
                        }
                    }
                    .PhoneInputCountryIcon {
                        box-shadow: none;
                    }
                    .PhoneInputCountrySelect {
                        color: $rusticLightRedColor;
                        font-size: $fontSize14px;
                        font-weight: $fontWeight500;
                    }
                }
                .react-tel-input {
                    margin-left: 9%;
                    width: auto !important;
                    .flag-dropdown {
                        background: $whiteSmokeDarkColor;
                        border: 1px solid $earlyCrocusColor;
                        border-radius: 4px;
                        left: -52px;
                    }
                    .country-list {
                        .search-box {
                            margin-left: 0;
                            width: 100%;
                        }
                        .search {
                            padding: 10px 10px 10px 10px !important;
                        }
                    }
                    .form-control {
                        width: 100%;
                        padding: 0.375rem 0.75rem;
                    }
                }

                button {
                    &.submit-btn {
                        border-radius: 4px;
                        opacity: 1;
                        color: $companyPrimaryButtonTextColor;
                        background-color: $companyPrimaryColor;
                        font-size: $fontSize14px;
                        font-weight: $fontWeight500;
                        width: 100%;
                        margin: 15px 0;
                        padding: 10px;

                        a {
                            color: inherit;
                            text-decoration: none;
                        }
                        &:hover {
                            color: $companyPrimaryColor;
                            background-color: $companyPrimaryButtonTextColor;
                            border: 1px solid $companyPrimaryColor;
                        }
                    }
                }

                hr {
                    border-color: $crystallizeColor;
                    border-width: 1.4px;
                }
            }
        }
    }

    .otp-container {
        display: flex;
        gap: 10px;
        justify-content: center;
        input {
            width: 34px !important;
            margin-right: 21px;
            border: 2px solid $earlyCrocusColor;
            color: $blackColor;
        }

        .otp-input {
            width: 40px;
            height: 40px;
            text-align: center;
            font-size: $fontSize18px;
            border: 1px solid $mintCreamColor;
            border-radius: 4px;
            outline: none;
            background-color: $whiteSmokeDarkColor;

            &:focus {
                border-color: $navyBlueColor;
                box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
            }
        }
    }

    .back-btn {
        position: absolute;
        left: 35px;
        background: $whisperColor;
        border-radius: 2px;
        opacity: 1;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            color: $purplishBrownColor;
            font-size: $fontSize16px;
        }
    }
}
.social-signin {
    pointer-events: none;
    opacity: 0.6;
    p {
        margin-bottom: 0px !important;
    }
    img {
        width: 19px;
    }
    .social-google {
        border: 1px solid $earlyCrocusColor;
        padding: 9px;
        cursor: pointer;
    }
    .social-facebook {
        border: 1px solid $earlyCrocusColor;
        padding: 9px;
        cursor: pointer;
    }
}
